import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { isMessageFromAdmin } from '../../helpers/useMessages';
import { useUser } from '../../contexts/userContext';

import PropTypes from 'prop-types';

const MessageFrom = ({ message, options = {} }) => {
  const { sender, senderAlias, senderName, id } = message;
  const { useAdminView: adminViewParam = false, defaultSender = 'Project 1591' } = options;
  const { isEmployee, isAdmin, user } = useUser();
  const useAdminView = isAdmin && adminViewParam;

  if (!message) {
    return;
  }

  if (useAdminView && isMessageFromAdmin(message)) {
    const senderTitle = `Alias: ${senderAlias}`;
    return <span title={senderTitle}>{senderName || defaultSender}</span>;
  } else if (useAdminView) {
    const senderTitle = `Real name: ${senderName}`;
    return (
      <span title={senderTitle}>
        {isEmployee && useAdminView && <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />}{' '}
        {senderAlias || defaultSender}
      </span>
    );
  } else {
    return <span>{senderAlias || defaultSender}</span>;
  }
};

MessageFrom.propTypes = {
  message: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default MessageFrom;
