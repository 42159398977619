import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { listMessageThreads as listMessageThreadsQuery } from '../../generated/graphql/queries';
import { Link } from 'react-router-dom';
import {
  Button,
  DropDown,
  TextField,
  UserSelector,
  DateDisplay,
  MessageForm,
  PopUpConfirmation,
} from '../../components';
import MessageFrom from '../MessageFrom/MessageFrom';
import { parseMarkdown } from '../../helpers/markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { getInboxUid } from '../../helpers/useMessages';
import { useHistory } from 'react-router-dom';

/**
 * @typedef {Object} Thread
 * @property {string} id - The ID of the message
 * @property {string} message - Content of the message
 * @property {string} recipient - UID of the recipient
 */

/**
 * A placeholder component for a message thread view that loops over an array of threads.
 * Displays the message title attribute. Clicking on the title toggles the display of that messages body content.
 * @param {number} rootMessageId - The ID of the root message to display threads for.
 * @returns {JSX.Element} - Rendered component.
 */
const MessageThread = ({
  rootMessageId,
  displaySeen = false,
  currentMessageId = null,
  showReply = false,
  showRootIfSingle = false,
  showLoadingSkeleton = true,
  user = {},
}) => {
  const [activeThread, setActiveThread] = useState(null);
  const { threads, loading } = useMessageThreads(rootMessageId, { showRootIfSingle });
  const myIds = user && user.id ? [user.id, getInboxUid(user.userStatus)] : [];

  /**
   * Handles the click event on a thread title.
   * If the clicked thread is already active, sets activeThread to null.
   * If the clicked thread is not active, sets activeThread to the clicked thread.
   * @param {Object} thread - The thread object that was clicked.
   */
  const handleThreadClick = thread => {
    if (activeThread === thread) {
      setActiveThread(null);
    } else {
      setActiveThread(thread);
    }
  };
  // return <p>{JSON.stringify({ threads, rootMessageId })}</p>;
  if (!loading && threads.length <= 0) {
    return <div></div>;
    // return <div className="text-pursuit-gray">Message is not part of a thread.</div>;
  }

  return (
    <div>
      {!loading && <h2 className="mb-1 text-xl text-left font-bold">Thread</h2>}
      {loading && showLoadingSkeleton ? (
        <>
          <MessageThreadSkeleton />
        </>
      ) : (
        <ul>
          {(threads || []).map(thread => {
            const level = `${thread.replyPath}`.match(/\//g).length - 1 || 0;
            let marginClass = `ml-${level * 0} py-1 border-b cursor-pointer`;
            let title = null;
            if (thread.id === currentMessageId) {
              marginClass += ' bg-yellow-100';
              title = 'Current message';
            }
            const active = activeThread === thread;
            const clickHandler = !active ? () => handleThreadClick(thread) : null;
            return (
              <li title={title} key={thread.id} className={marginClass} onClick={clickHandler}>
                <ThreadHeader
                  displaySeen={displaySeen}
                  thread={thread}
                  active={activeThread === thread}
                  showReply={showReply && myIds.length > 0 && myIds.includes(thread.recipient)}
                />
                {/* {activeThread === thread && <ThreadBody thread={thread} />} */}
                {/* {JSON.stringify({ thread, activeThread, myIds }, null, 2)} */}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

MessageThread.propTypes = {
  rootMessageId: PropTypes.string.isRequired,
  displaySeen: PropTypes.bool,
  currentMessageId: PropTypes.string,
  showReply: PropTypes.bool,
  showRootIfSingle: PropTypes.bool,
  showLoadingSkeleton: PropTypes.bool,
  user: PropTypes.object,
};

/**
 * Returns an object with an array of threads for a given message ID and a loading boolean attribute.
 * @param {number} rootMessageId - The ID of the message to get threads for.
 * @returns {Thread[]} - An object with a threads array and a loading boolean attribute.
 */
const useMessageThreads = (rootMessageId, { autoLoad = true, showRootIfSingle = false } = {}) => {
  const threadsDefault = [];
  const [loading, setLoading] = useState(autoLoad);
  /**
   * @type {[Thread[], function]}
   */
  const [threads, setThreads] = useState(threadsDefault);
  const fetchData = async () => {
    const params = { id: rootMessageId };
    setLoading(true);
    try {
      const {
        data: { listMessageThreads },
      } = await API.graphql(graphqlOperation(listMessageThreadsQuery, params));
      // console.log('params', params);
      setLoading(false);
      return listMessageThreads.items || threadsDefault;
    } catch (e) {
      const {
        data: { listMessageThreads },
      } = e;
      console.error('Error loading threads', e);
      setLoading(false);
      return listMessageThreads.items || threadsDefault;
    }
  };

  const loadThreads = async () => {
    const data = await fetchData();
    if (!showRootIfSingle) {
      // console.log({ showRootIfSingle, data, rootMessageId });
      // Remove the root message from the list of threads if there is only one thread
      if (data.length === 1) {
        const [thread] = data;
        if (thread.id === rootMessageId) {
          // data.splice(0, 1);
          setThreads([]);
          return;
        }
      }
    }
    // console.log('data', data);
    setThreads(data);
  };

  useEffect(() => {
    if (autoLoad) {
      loadThreads();
    }
  }, [autoLoad]);

  return { threads, loading };
};

const MessageThreadSkeleton = () => {
  // Tailwind skeleton that looks like a table with two rows, three columns
  return (
    <div className="border-gray-300 p-4 w-full">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 grid grid-cols-8 gap-4">
          <div className="h-2 bg-gray-300 rounded col-span-1"></div>
          <div className="h-2 bg-gray-300 rounded col-span-2"></div>
          <div className="h-2 bg-gray-300 rounded col-span-5"></div>
          <div className="h-2 bg-gray-300 rounded col-span-1 col-start-1"></div>
          <div className="h-2 bg-gray-300 rounded col-span-2"></div>
          <div className="h-2 bg-gray-300 rounded col-span-3"></div>
        </div>
      </div>
    </div>
  );
};

const ThreadHeader = ({ thread, active = false, displaySeen = false, showReply = false }) => {
  const seen = thread.seenAt ? (
    <>
      <span
        title={'Seen: ' + DateTime.fromISO(thread.seenAt).toLocaleString(DateTime.DATETIME_FULL)}
        className="text-opacity-50 text-pursuit-gray">
        <FontAwesomeIcon icon={icon({ name: 'eye', style: 'regular' })} />
      </span>
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={icon({ name: 'eye-slash', style: 'regular' })} />
    </>
  );
  const date = thread.createdAt ? <DateDisplay date={thread.createdAt} /> : '-';
  return (
    <div className="flex">
      <div className="flex-none m-2 mr-0">{displaySeen && seen}</div>
      <div className="flex-initial m-2 ml-1">{date}</div>
      <div className={`${!active ? 'flex-none w-3/12' : 'flex-grow'} min-w-md m-2`}>
        {active && <>From: </>}
        <MessageFrom message={thread} options={{ useAdminView: true }} />
        {active && (
          <>
            <div className="my-2">Subject: {thread.subject}</div>
            <ThreadBody thread={thread} showReply={showReply} />
          </>
        )}
      </div>
      {!active && (
        <div className={`flex-grow${!active ? ' flex truncate' : ''} m-2`}>
          <span className="flex-initial">{thread.subject}</span>
          {!active && <span className="text-gray-400 ml-2 truncate max-w-full">{thread.message}</span>}
          {/* {active && <ThreadBody thread={thread} />} */}
        </div>
      )}
    </div>
  );
};
ThreadHeader.propTypes = {
  thread: PropTypes.object.isRequired,
  active: PropTypes.bool,
  displaySeen: PropTypes.bool,
  showReply: PropTypes.bool,
};

const ThreadBody = ({ thread, showReply = false }) => {
  const replyNotification = {
    // fromReply: true,
    subject: formatReplySubject(thread.subject),
    replyTo: thread.id,
    title: thread.title,
    recipients: [{ recipient: thread.sender }],
  };

  const history = useHistory();
  const [showSentConfirmation, setShowSentConfirmation] = useState(false);

  return (
    <div className="my-2 border-t pt-2 border-gray-200">
      {showSentConfirmation && (
        <PopUpConfirmation
          title="Message Sent"
          content={`Your message has been sent.`}
          noCancel
          onConfirm={() => {
            // Navigate to the messaging page
            history.push('/messaging');
          }}
          confirmText="OK"
          confirmLoadingText="OK"
          className="w-11/12 lg:w-auto"
          destructive
        />
      )}
      <span
        dangerouslySetInnerHTML={{
          __html: parseMarkdown(thread.message),
        }}></span>
      {/* {thread.message} */}
      {/* {JSON.stringify(thread)} */}
      {/* {JSON.stringify({ replyNotification, showReply })} */}
      <div>
        {showReply && (
          <div className="bg-white border border-gray-200 rounded-sm p-4">
            <MessageForm
              notification={replyNotification}
              isReply={true}
              createLabel="Reply"
              cancelLink={null}
              createCallback={() => setShowSentConfirmation(true)}
              noUserSelector={true}
              subjectDisabled={true}
              noTitle={true}
              noSubject={true}
            />
          </div>
        )}
      </div>
      {/* <div className="flex justify-end mt-2">{threadReplyButton}</div> */}
    </div>
  );
};

ThreadBody.propTypes = {
  thread: PropTypes.object.isRequired,
  showReply: PropTypes.bool,
};
// Copied from some other place
const formatReplySubject = subject => {
  const prefix = 'Re: ';
  if (subject.startsWith(prefix)) {
    return subject;
  }
  return `${prefix}${subject}`;
};

export default MessageThread;
