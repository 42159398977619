import React, { useContext } from 'react';
import Alert from '../Alert';
import { NotificationContext } from '../../helpers/AlertContext/AlertContext.js';

const AlertHandler = () => {
  const [state] = useContext(NotificationContext);

  switch (state.notification.occurs) {
    case true:
      return (
        <Alert textColor={state.notification.textColor} borderColor={state.notification.borderColor}>
          {state.notification.message}
        </Alert>
      );

    default:
      return <></>;
  }
};

export default AlertHandler;
