import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import PropTypes from 'prop-types';

const MessageToDetail = ({ message, options }) => {
  if (!message) {
    return null;
  }
  const {
    recipientAlias,
    recipientName,
    id,
    recipientGroups,
    recipientMeta = {},
    recipientCount,
    createRequestId,
  } = message;

  const userIcon =
    recipientCount > 1 ? (
      <FontAwesomeIcon icon={icon({ name: 'users', style: 'solid' })} />
    ) : (
      <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />
    );

  const { useAdminView = false } = options;
  let to = (
    <span title={useAdminView ? `Real name: ${message.recipientName}` : null}>
      {useAdminView && userIcon} {message.recipientAlias || message.recipient}
    </span>
  );
  if (useAdminView && (message.recipientGroups || []).includes('Admin')) {
    to = <span title={`Alias: ${message.recipientAlias}`}>{message.recipientName || message.recipient}</span>;
  }

  if (
    !useAdminView ||
    !recipientMeta ||
    !Array.isArray(recipientMeta.users) ||
    (Array.isArray(recipientMeta.users) && recipientMeta.users.length <= 1)
  ) {
    return to;
  }
  // if (recipientMeta) {
  //   const users = recipientMeta && Array.isArray(recipientMeta.users) ? recipientMeta.users : [];
  //   recipientMeta.users = [...users, ...users, ...users, ...users, ...users, ...users, ...users, ...users, ...users];
  // }

  // Build a basic stats summary for the message
  // const recipientCount = message.recipientCount || 1;

  // If message is sent to more than one person, show a summary of the recipients
  // Summary should include:
  // Name of each recipient, whether they've seen it or not and a count of how many have seen it
  const tipId = `${id}-data`;
  const liClass = 'px-4 py-2';
  return (
    <span className="relative">
      {(recipientMeta && (
        <span
          aria-describedby={tipId}
          className="underline"
          style={{ textDecorationStyle: 'dashed', textUnderlinePosition: 'under' }}>
          {to}
        </span>
      )) ||
        to}
      <ul
        role="tooltip"
        id={tipId}
        className="rounded-md bg-white text-sm text-gray-400 shadow-2xl border-gray-200 overflow-y-scroll"
        style={{ minWidth: '24em', maxHeight: '24em' }}>
        {recipientMeta && (
          <li className="grid grid-cols-3">
            <span className={liClass + ' font-bold col-span-2'}>Recipients {recipientMeta.recipientCount}</span>
            <span className={liClass + ' font-bold'}>Seen by {recipientMeta.seenCount}</span>
          </li>
        )}
        {recipientMeta &&
          Array.isArray(recipientMeta.users) &&
          recipientMeta.users.map((x, i) => (
            <li key={x.id} className="grid grid-cols-3">
              <span className={'col-span-2 ' + (i % 2 == 0 ? `bg-gray-100 ${liClass}` : liClass)} key={x.id + '-name'}>
                <Link to={`/user/${x.id}`} title={useAdminView && x.name}>
                  {x.alias}
                </Link>
              </span>
              <span className={i % 2 == 0 ? `bg-gray-100 ${liClass}` : liClass} key={x.id + '-seen'}>
                {x.seenAt ? (
                  <span
                    className="text-opacity-100 text-black"
                    title={DateTime.fromJSDate(new Date(x.seenAt)).toLocaleString(DateTime.DATETIME_FULL)}>
                    <FontAwesomeIcon icon={icon({ name: 'eye', style: 'regular' })} />
                  </span>
                ) : (
                  <span className="text-opacity-50 text-pursuit-gray">
                    <FontAwesomeIcon icon={icon({ name: 'eye-slash', style: 'regular' })} />
                  </span>
                )}
              </span>
            </li>
          ))}
        {/* {JSON.stringify(message, null, 2)} */}
      </ul>
    </span>
  );
};

MessageToDetail.propTypes = {
  message: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default MessageToDetail;
