import React, { useEffect, useState, useContext, useCallback } from 'react';
import { DateTime } from 'luxon';
import { API, graphqlOperation } from 'aws-amplify';
import { snakeCase } from 'change-case';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import {
  Container,
  HeaderText,
  DataGrid,
  Button,
  StatusPill,
  DropDown,
  PopUpConfirmation,
  ToolTip,
} from '../../../components';
import { listAssignedCases, listCasesForAdmin, listEmployees } from '../../../generated/graphql/queries';
import { assignCase, nullifyCase, unassignCase } from '../../../generated/graphql/mutations';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';
import { CaseStatus, UserGroups } from '../../../constants';
import { useUser } from '../../../contexts/userContext';
import Trash from '../../../assets/Images/trash.svg';

const CaseStatusOptions = [
  { value: CaseStatus.Active, label: 'Active' },
  { value: CaseStatus.Approved, label: 'Approved' },
  { value: CaseStatus.Nullified, label: 'Deleted' },
  { value: CaseStatus.Resubmitted, label: 'Resubmitted' },
  { value: CaseStatus.Returned, label: 'Returned' },
  { value: CaseStatus.Submitted, label: 'Submitted' },
];

const EmployeeCases = () => {
  const { userGroups, isAdmin } = useUser();

  const [, setAlert] = useContext(NotificationContext);

  const [nextCaseToken, setNextCaseToken] = useState();

  const [cases, setCases] = useState([]);
  const [casesLoading, setCasesLoading] = useState(true);

  const [employeeOptions, setEmployeeOptions] = useState([]);

  const [status, setStatus] = useState(CaseStatus.Submitted);
  const [assignedTo, setAssignedTo] = useState();

  const [filters, setFilters] = useState({
    status: CaseStatus.Submitted,
    assignedTo: null,
    searchTerm: '',
  });

  const [sort, setSort] = useState({
    fieldName: 'submittedAt',
    direction: 'DESC',
  });

  const [filtersOnLastSearch, setFiltersOnLastSearch] = useState(null);
  const [sortOnLastSearch, setSortOnLastSearch] = useState({
    fieldName: 'submittedAt',
    direction: 'DESC',
  });

  const [search, setSearch] = useState('');

  const [caseToAssign, setCaseToAssign] = useState(null);
  const [assignmentSaving, setAssignmentSaving] = useState(null);

  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    fetchCases();
  }, [sort]);

  useEffect(() => {
    fetchCases();
  }, [filters]);

  const fetchCases = async () => {

    try {
      let nextTokenToUse = nextCaseToken;

      let oldCases = nextTokenToUse ? [...cases] : [];

      if (
        !filtersOnLastSearch ||
        filtersOnLastSearch.status !== filters.status ||
        filtersOnLastSearch.assignedTo !== filters.assignedTo ||
        filtersOnLastSearch.searchTerm !== filters.searchTerm ||
        sortOnLastSearch.fieldName !== sort.fieldName ||
        sortOnLastSearch.direction !== sort.direction
      ) {
        setNextCaseToken(null);

        nextTokenToUse = null;
        oldCases = [];
      }
      if (oldCases.length == 0) {
        setCasesLoading(true);
      }

      setSortOnLastSearch(sort);
      setFiltersOnLastSearch(filters);

      const params = {
        status: filters.status,
        assignedTo: filters.assignedTo,
        searchTerm: filters.searchTerm,
        nextToken: nextTokenToUse,
        sortField: sort.fieldName ? snakeCase(sort.fieldName).toUpperCase() : '',
        sortOrder: sort.direction,
        limit: 100,
      };

      const query = isAdmin ? listCasesForAdmin : listAssignedCases.replace('assignedToName\n', '');

      const response = await API.graphql(graphqlOperation(query, params));

      const result = isAdmin ? response.data.listCasesForAdmin : response.data.listAssignedCases;


      setCases([...oldCases, ...result.items]);
      setNextCaseToken(result.nextToken);
    } catch (error) {
      console.error('Error Loading cases: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading active cases',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setCasesLoading(false);
  };

  const fetchEmployees = async () => {
    try {
      let nextEmployeeToken = null;
      let employeeList = [];

      do {
        const params = {
          nextToken: nextEmployeeToken,
          limit: 100,
          sortOrder: 'ASC',
        };

        const result = await API.graphql(graphqlOperation(listEmployees, params));

        nextEmployeeToken = result.data.listEmployees.nextToken;

        employeeList = [...employeeList, ...result.data.listEmployees.items];
      } while (nextEmployeeToken);

      const options = employeeList.map(x => ({
        value: x.id,
        label: `${x.firstName} ${x.lastName}`,
      }));

      setEmployeeOptions(options);

      return options;
    } catch (error) {
      console.error('Error Loading employees: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading employees',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
  };

  const checkAssignCase = ({ userId, item }) => {
    const name = employeeOptions.find(x => x.value === userId)?.label;

    if (item.assignedTo && item.assignedTo !== userId) {
      setCaseToAssign({ name, userId, caseId: item.id });
    } else {
      onAssignCase({ name, userId, caseId: item.id });
    }
  };

  const onAssignCase = async ({ name = null, userId = null, caseId }) => {
    try {
      setAssignmentSaving(caseId);

      if (userId) {
        const params = {
          input: {
            id: caseId,
            assignedToName: name,
            assignedTo: userId,
          },
        };

        await API.graphql(graphqlOperation(assignCase, params));
      } else {
        await API.graphql(graphqlOperation(unassignCase, { input: { id: caseId } }));
      }

      const oldCases = [...cases];

      const caseToUpdateIdx = oldCases.findIndex(x => x.id === caseId);

      if (caseToUpdateIdx !== -1) {
        const updatedCase = oldCases[caseToUpdateIdx];

        updatedCase.assignedTo = userId;
        updatedCase.assignedToName = name;

        oldCases[caseToUpdateIdx] = updatedCase;

        setCases([...oldCases]);
      }

      setCaseToAssign(null);
    } catch (error) {
      console.error('Error assigning case: ', error);
    }

    setAssignmentSaving(null);
  };

  const renderStatus = ({ status: recordStatus }) => <StatusPill status={recordStatus} />;

  const renderAssignedTo = item => {
    if (item.status === CaseStatus.Submitted) {
      return (
        <select
          value={item.assignedTo || ''}
          className={`custom-select pl-2 h-10 w-48 lg:mr-4 border border-gray-200 bg-white rounded ${
            !item.assignedTo ? 'text-gray-200' : 'text-pursuit-gray'
          }`}
          onChange={event => checkAssignCase({ item, userId: event.target.value })}>
          {/* <option key="default-state" value="" disabled="disabled"> */}
          <option key="default-state" value="">
            Unassigned
          </option>

          {employeeOptions.map((x, i) => (
            <option value={x.value} key={i}>
              {x.label}
            </option>
          ))}
        </select>
      );
    }

    return item.assignedToName || '-';
  };

  const getSubmittedDate = item => (item.submittedAt ? DateTime.fromISO(item.submittedAt).toLocaleString() : '-');

  const renderHeaderText = () => (userGroups.includes(UserGroups.Admin) ? 'Cases' : 'My Assigned Cases');

  const renderPoints = ({ totalPoints }) => (
    <div
      className={`${getAwardColor(
        totalPoints
      )} h-6 w-8 rounded-full flex font-bold justify-center items-center p-0 ml-2`}>
      {totalPoints?.pointsAwarded || ''}
    </div>
  );

  const renderDelete = record => {
    if (!(record.status === CaseStatus.Submitted || record.status === CaseStatus.Approved)) {
      return null;
    }

    return (
      <div className="flex flex-row items-center justify-between w-full focus:outline-none lg:w-auto ">
        <span className="font-semibold lg:hidden">Delete Case:</span>

        <button type="button" onClick={() => setRecordToDelete(record)} className="focus:outline-none">
          <img src={Trash} alt="Trash" className="object-contain w-5" />
        </button>
      </div>
    );
  };

  const nullifyRecord = async id => {
    try {
      setDeleting(true);

      const updatedCases = [...cases];

      const indexOfRecord = updatedCases.findIndex(x => x.id === id);

      await API.graphql(graphqlOperation(nullifyCase, { id }));

      updatedCases.splice(indexOfRecord, 1);

      setCases(updatedCases);
      toast.success('Case removed', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });
    } catch (error) {
      console.error('Error deleting record: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'There was an error removing the case',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setRecordToDelete(null);
    setDeleting(false);
  };

  const getAwardColor = totalPoints => {
    // if points aren't awared yet don't show
    if (!totalPoints) {
      return 'bg-white text-white';
    }

    if (!totalPoints.isFullPoints) {
      return 'bg-pursuit-amber text-white';
    }

    return 'bg-green-500 text-white';
  };

  let columns = [
    { title: 'Case Number', fieldName: 'caseNumber', sortable: true },
    {
      title: 'Submitted',
      fieldName: 'submittedAt',
      value: getSubmittedDate,
      sortable: true,
    },
    { title: 'First Name', fieldName: 'firstName', sortable: true },
    { title: 'Last Name', fieldName: 'lastName', sortable: true },
    { title: 'State', fieldName: 'state', sortable: true },
    { title: 'Volunteer', fieldName: 'ownerAlias', sortable: true },
  ];

  if (isAdmin) {
    columns.unshift({
      title: '',
      renderFunction: renderDelete,
      width: '2rem',
      noLink: true,
    });
    columns.push({
      title: 'Assigned To',
      value: renderAssignedTo,
      width: '14rem',
      noLink: true,
    });
  }

  columns.push({
    title: 'Case Status',
    fieldName: 'status',
    renderFunction: renderStatus,
  });
  columns.push({
    title: 'Points',
    fieldName: 'totalPoints',
    renderFunction: renderPoints,
  });
  if (status === CaseStatus.Nullified) {
    columns = [
      { title: 'Case Number', fieldName: 'caseNumber', sortable: true },
      {
        title: 'Submitted',
        fieldName: 'submittedAt',
        value: getSubmittedDate,
        sortable: true,
      },
      { title: 'Volunteer', fieldName: 'ownerAlias', sortable: true },
      {
        title: 'Deleted',
        fieldName: 'deletedAt',
        sortable: false,
        renderFunction: item => (item.deletedAt ? DateTime.fromISO(item.deletedAt).toLocaleString() : '-'),
      },
    ];
  }

  return (
    <>
      {caseToAssign ? (
        <PopUpConfirmation
          title="Confirm Case Reassignment"
          content="This case is already assigned, are you sure you want to reassign this case?"
          onCancel={() => setCaseToAssign(null)}
          onConfirm={() => onAssignCase(caseToAssign)}
          confirmLoading={assignmentSaving}
          confirmLoadingText="REASSINGING"
          confirmText="REASSIGN"
          className="w-11/12 lg:w-auto"
        />
      ) : null}
      <Container height="lg:h-152" padding="p-4" margin="m-3 lg:m-auto lg:mx-8" className="relative">
        <div className="flex flex-col pt-4 lg:px-4">
          <HeaderText className="mb-4 text-2xl text-left lg:text-4xl">{renderHeaderText()}</HeaderText>

          <div className="items-end justify-end mb-10 lg:flex">
            <div className="lg:mr-4">
              <DropDown
                value={CaseStatusOptions.find(x => x.value === status)}
                onChange={option => {
                  setFilters({
                    status: option ? option.value : null,
                    assignedTo,
                    searchTerm: search,
                  });
                  setStatus(option ? option.value : null);
                }}
                containerClassName="mb-4 lg:mb-0"
                width="w-full lg:w-48 xl:w-64"
                label="Case Status"
                options={CaseStatusOptions}
                placeholder="Status"
                isClearable={!isAdmin}
              />
            </div>

            {isAdmin && (
              <div className="lg:mr-4">
                <DropDown
                  value={employeeOptions.find(x => x.value === assignedTo)}
                  width="w-full lg:w-48 xl:w-64"
                  className="bg-gray-100"
                  onChange={option => {
                    const assigned = option ? option.value : null;

                    setAssignedTo(assigned);

                    setFilters({
                      status,
                      assigned,
                      searchTerm: search,
                    });
                  }}
                  label="Assigned To"
                  options={employeeOptions}
                  placeholder="Assigned To"
                  loadOptions={fetchEmployees}
                  containerClassName="mb-4 lg:mb-0"
                  isClearable
                />
              </div>
            )}

            <div className="flex flex-col">
              <div className="flex flex-row">
                <p className="mb-1 font-light light-primary-blue-text">Search Term</p>

                <ToolTip
                  tooltipText="Find records with the search term included in the Case Number, First Name, Last Name, State, or Volunteer fields."
                  title="Search Term"
                  className="ml-2 text-pursuit-gray"
                />
              </div>

              <input
                type="text"
                value={search}
                onChange={event => setSearch(event.target.value)}
                placeholder="Search Term"
                className="w-full h-10 px-4 mt-2 mb-4 bg-gray-100 lg:mb-0 lg:mt-0 lg:mr-4 lg:w-48 xl:w-64"
                onKeyDown={event => {
                  const pressedKey = event.key;

                  if (pressedKey === 'Enter') {
                    setFilters({
                      status,
                      assignedTo,
                      searchTerm: search,
                    });
                  }
                }}
              />
            </div>

            <Button
              solidBlue
              className="w-full px-4 lg:w-24"
              onClick={() => {
                setFilters({
                  status,
                  assignedTo,
                  searchTerm: search,
                });
              }}>
              FILTER
            </Button>
          </div>

          {recordToDelete ? (
            <PopUpConfirmation
              title="Delete Record"
              content={`Are you sure you want to delete ${recordToDelete?.caseNumber || 'this record'}?`}
              onConfirm={() => {
                nullifyRecord(recordToDelete.id);
              }}
              onCancel={() => {
                setRecordToDelete(null);
              }}
              confirmLoading={deleting}
              confirmText="DELETE"
              confirmLoadingText="DELETING"
              destructive
              className="w-11/12 lg:w-auto"
            />
          ) : null}

          <DataGrid
            columns={columns}
            sort={sort}
            setSort={setSort}
            loading={casesLoading}
            data={cases}
            noRecordsText="No cases found"
            rowLink={item => `/case-review/${item.id}`}
            loadNextPage={() => fetchCases(filters)}
            hasMore={!!nextCaseToken}
            containerHeight="h-88"
            gridKey="employee-cases"
          />
        </div>
      </Container>
    </>
  );
};

export default EmployeeCases;
