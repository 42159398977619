import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Button,
  Spinner,
  DateDisplay,
  PopUpConfirmation,
  MessageToDetail,
  MessageThread,
  MessageForm,
} from '../../../components';
import * as Icons from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useMessages, isMessageFromAdmin, getInboxUid } from '../../../helpers/useMessages';
import { useUser } from '../../../contexts/userContext';
import { useParams } from 'react-router-dom';
import { parseMarkdown } from '../../../helpers/markdown';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Storage } from 'aws-amplify';
const USE_VOLUNTEER_VIEW = 'USE_VOLUNTEER_VIEW';
Storage.configure({
  customPrefix: {
    public: '',
  },
  // level: 'email_attachments',
});

const MessageRead = ({ defaultSender = 'Project 1591' }) => {
  const pageTitle = 'Message Detail';
  const { messageId } = useParams();
  const { isAdmin, user } = useUser();
  const history = useHistory();
  // const history = useHistory();
  const [message, setMessage] = useState();
  const [autoRead, setAutoRead] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [messageRead, setMessageRead] = useState(false);
  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] = useState(false);
  const [adminFlagged, setAdminFlagged] = useState(false);
  const [showSentConfirmation, setShowSentConfirmation] = useState(false);
  const myIds = user && user.id ? [user.id, getInboxUid(user.userStatus)] : [];
  const {
    loadMessageDetail,
    loading,
    readMessage,
    unreadMessage,
    flagMessage,
    unflagMessage,
    deleteMessage,
  } = useMessages();

  const checkForVolunteerView = () => {
    const useVolunteerSavedPref = localStorage.getItem(USE_VOLUNTEER_VIEW);

    if (useVolunteerSavedPref && useVolunteerSavedPref.toLowerCase() === 'true') {
      return true;
    }

    return false;
  };

  const [useVolunteerView, setUseVolunteerView] = useState(checkForVolunteerView());

  const useAdminView = isAdmin && !useVolunteerView;

  // Toggle the delete confirmation modal
  const toggleDeleteConfirmation = useCallback(() => {
    setDisplayDeleteConfirmation(!displayDeleteConfirmation);
  }, [displayDeleteConfirmation]);

  useEffect(() => {
    const load = async () => {
      try {
        const data = await loadMessageDetail(messageId);
        setMessage(data);
        setMessageRead(!!data.readAt);
        setAdminFlagged(!!data.adminFlaggedAt);
      } catch (e) {
        console.error(e);
      }
    };
    load();
  }, [messageId, loadMessageDetail]);

  const messageReadToggle = useCallback(() => {
    console.log('Toggle read', { messageRead, messageId });
    const task = async () => {
      const apiRequest = messageRead ? unreadMessage(messageId) : readMessage(messageId);
      const updatedMessage = await apiRequest;
      console.log({ updatedMessage });
      setMessageRead(!!updatedMessage.readAt);
      setMessage(updatedMessage);
    };
    task();
  }, [messageId, readMessage, unreadMessage, messageRead]);

  const messageFlagToggle = useCallback(() => {
    const task = async () => {
      const apiRequest = adminFlagged ? unflagMessage(messageId) : flagMessage(messageId);
      const updatedMessage = await apiRequest;
      console.log({ updatedMessage });
      setAdminFlagged(!!updatedMessage.adminFlaggedAt);
      setMessage(updatedMessage);
    };
    task();
  }, [messageId, flagMessage, unflagMessage, adminFlagged]);

  useEffect(() => {
    // console.log('Auto read', { message, user, autoRead, useAdminView });
    if (message && (message.readAt || message.seenAt)) {
      // console.log('Auto read disabled', { message, user });
      setAutoRead(false);
      return;
    }
    if (!message || message.readAt !== null || message.seenAt != null || !autoRead) {
      return;
    }
    // Check if user is recipient or message recipientGroups contains the users userStatus and useAdminView is true
    const lowerCaseGroups = (message.recipientGroups || []).map(group => group.toLowerCase());
    const messageGroupIncludesUser = lowerCaseGroups.includes(user.userStatus.toLowerCase());
    const groupMessageAndAdminView = messageGroupIncludesUser && useAdminView;
    // console.log({ lowerCaseGroups, messageGroupIncludesUser, groupMessageAndAdminView });
    if (!user || (user.id != message.recipient && !groupMessageAndAdminView)) {
      console.info('Message not for user');
      return;
    }
    const markRead = async () => {
      // console.log('Auto marking', { autoRead });
      setAutoRead(false);
      const updatedMessage = await readMessage(message.id);
      if (updatedMessage) {
        setMessage(updatedMessage);
        setMessageRead(!!updatedMessage.readAt);
      }
    };
    markRead();
  }, [message, autoRead, user]);

  const downloadAttachment = async attachment => {
    console.log({ attachment });
    // S3 path and file name
    const { path, name } = attachment;
    const url = await Storage.get(path, { level: 'public' });
    // const url = await Storage.get(path, { download: true, level: '' });
    console.log({ url });
    // Trigger download
    window.open(url, '_blank');
  };

  const backButton = (
    <Link
      to="/messaging"
      onClick={e => {
        e.preventDefault();
        return history.goBack();
      }}
      className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue">
      <Icons.FaChevronLeft size={18} className="mr-4" />
      BACK
    </Link>
  );

  const readActionButton = message &&
    user &&
    (user.id == message.recipient || message.recipient == getInboxUid(user.userStatus)) && (
      <Button
        height="h-auto"
        className="flex items-center mb-0 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue mx-2"
        onClick={messageReadToggle}
        // As long as this is the only thing that changes state on this page it can piggy back on message loading
        loading={loading}
        disabled={loading}>
        Mark {messageRead ? 'unread' : 'read'}
      </Button>
    );

  const flagActionButton = useAdminView && (
    <Button
      height="h-auto"
      className="flex items-center mb-0 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue mx-2"
      onClick={messageFlagToggle}
      // As long as this is the only thing that changes state on this page it can piggy back on message loading
      loading={loading}
      disabled={loading}>
      {adminFlagged ? 'Unflag' : 'Flag'}
    </Button>
  );
  const deleteActionButton = useAdminView && message && (
    <Button
      height="h-auto"
      className="flex items-center mb-0 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue mx-2"
      onClick={toggleDeleteConfirmation}
      // As long as this is the only thing that changes state on this page it can piggy back on message loading
      // loading={loading}
      disabled={loading}>
      Delete
    </Button>
  );
  // console.log({user})
  if ((loading && !message) || !message) {
    return (
      <Container
        width="flex flex-col w-96pr h-88 lg:w-3/4 justify-center items-center"
        margin="m-3 lg:m-auto"
        className="relative px-6 py-8">
        <div className="flex flex-col items-center justify-center w-120">
          <div className="mb-6 text-xl font-bold text-pursuit-gray">
            {loading ? 'Loading message' : 'Unable to load message'}
          </div>
          {loading && <Spinner />}
        </div>
      </Container>
    );
  }

  const headerSection = (
    <div className="flex justify-between items-end">
      <div>
        <h1 className="mb-1 text-2xl text-left font-bold lg:text-4xl">{pageTitle}</h1>
        <span className="mb-4 text-sm text-left text-pursuit-gray">
          Date: <DateDisplay date={message.createdAt} format="DATETIME_SHORT" />
        </span>
      </div>
      <div className="flex">
        {readActionButton}
        {flagActionButton}
        {deleteActionButton}
      </div>
      {/* {primaryAction} */}
    </div>
  );
  const formatReplySubject = subject => {
    const prefix = 'Re: ';
    if (subject.startsWith(prefix)) {
      return subject;
    }
    return `${prefix}${subject}`;
  };
  const replyButton = (
    <Link
      to={{
        pathname: '/messaging/create',
        search: `?recipients=${message.sender}`,
        state: {
          fromReply: true,
          subject: formatReplySubject(message.subject),
          replyTo: messageId,
          title: message.title,
        },
      }}
      // className="flex items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue"
      // onClick={() => history.push('/messaging')}
      //  >
    >
      <Button solidBlue className="w-full mt-4 text-base lg:mt-0 lg:w-64">
        REPLY
      </Button>
    </Link>
  );
  // <span title={isAdmin && !useVolunteerView && `Real name: ${message.senderName}`}>
  //   {isAdmin && !useVolunteerView && <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />}{' '}
  //   {`${useAdminView ? message.senderAlias || message.sender : defaultSender}`}
  // </span>
  let from = (
    <span>
      {/* {isEmployee && !useVolunteerView && (
            <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />
          )}{' '} */}
      {defaultSender}
    </span>
  );

  // let to = (
  //   <span title={useAdminView ? `Real name: ${message.recipientName}` : null}>
  //     {isAdmin && !useVolunteerView && <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />}{' '}
  //     {message.recipientAlias || message.recipient}
  //   </span>
  // );
  // if (useAdminView && (message.recipientGroups || []).includes('Admin')) {
  //   to = <span title={`Alias: ${message.recipientAlias}`}>{message.recipientName || message.recipient}</span>;
  // }

  if (useAdminView && isMessageFromAdmin(message)) {
    const senderTitle = `Alias: ${message.senderAlias}`;
    from = <span title={senderTitle}>{message.senderName || defaultSender}</span>;
  } else if (useAdminView) {
    const senderTitle = `Real name: ${message.senderName}`;
    from = (
      <span title={senderTitle}>
        {!useVolunteerView && <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />}{' '}
        {message.senderAlias || defaultSender}
      </span>
    );
  } else {
  }
  const content = (
    <div className="w-full px-2 py-4 my-2 bg-gray-100 lg:px-8 lg:py-8">
      <ul className="message-reader">
        <li className="my-1">
          {/* <p> */}
          <b className="text-pursuit-gray">To: </b>
          {/* {to} */}
          <MessageToDetail message={message} options={{ useAdminView }} />
          {/* <span title={isAdmin && !useVolunteerView && `Alias: ${message.recipientAlias}`}>
              {`${isAdmin && !useVolunteerView ? message.recipientName : message.recipientAlias || message.recipient}`}
            </span> */}
          {/* </p> */}
        </li>
        <li className="my-1">
          <p>
            <b className="text-pursuit-gray">From: </b>
            {from}
          </p>
        </li>
        <li className="my-1">
          <p>
            <b className="text-pursuit-gray">Title: </b>
            {`${(message.attributes && message.attributes.customTitle) || message.title}`}
          </p>
        </li>
        <li className="my-1">
          <p>
            <b className="text-pursuit-gray">Subject: </b>
            {`${message.subject}`}
          </p>
        </li>
        {/**
         * Show message attribute attachments and download ling from S3
         */}
        {message.attributes && message.attributes.fileAttachments && message.attributes.fileAttachments.length > 0 && (
          <>
            <li className="my-1">
              <p>
                <b className="text-pursuit-gray">Attachments: </b>
              </p>
            </li>
            <li className="my-1">
              <ul>
                {message.attributes.fileAttachments.map(
                  (attachment, index) =>
                    attachment && (
                      <li key={index} className="my-1">
                        <a onClick={() => downloadAttachment(attachment)} target="_blank" rel="noopener noreferrer">
                          {attachment.name}
                        </a>
                      </li>
                    )
                )}
              </ul>
            </li>
          </>
        )}

        {/* <p>{JSON.stringify({ message })}</p> */}
        <li className="my-1">
          {/* <b className="text-pursuit-gray">Message: </b> */}
          <hr className="mt-2" />
          {/* <p className="p-2"> */}
          <span
            className="p-2"
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(message.message),
            }}></span>
          {/* </p> */}
          <hr className="mb-2" />
        </li>
      </ul>
      <div className="flex flex-col-reverse justify-between mt-8 lg:flex-row">
        <div className="w-full mt-4 lg:mt-0 lg:w-auto">{/* {backButton} */}</div>
        {/* {replyButton} */}
        {/* {JSON.stringify({ myIds })} */}
        {/* {myIds.length > 0 && !myIds.includes(message.sender) && ( */}
        {myIds.length > 0 && myIds.includes(message.recipient) && (
          <div className="bg-white border border-gray-200 rounded-sm p-4 w-full mb-4">
            {showSentConfirmation && (
              <PopUpConfirmation
                title="Message Sent"
                content={`Your message has been sent.`}
                noCancel
                onConfirm={() => {
                  // Navigate to the messaging page
                  history.push('/messaging');
                }}
                confirmText="OK"
                confirmLoadingText="OK"
                className="w-11/12 lg:w-auto"
                destructive
              />
            )}
            <MessageForm
              // notification={message}
              notification={{
                subject: formatReplySubject(message.subject),
                replyTo: message.id,
                title: message.title,
                recipients: [{ recipient: message.sender }],
              }}
              isReply={true}
              createLabel="Reply"
              cancelLink={'/messaging'}
              cancelCallback={e => {
                // e.preventDefault();
                history.goBack();
              }}
              createCallback={() => setShowSentConfirmation(true)}
              noUserSelector={true}
              subjectDisabled={true}
              noTitle={true}
              noSubject={true}
              confirmSend
            />
          </div>
        )}
      </div>
      {/* <p>{JSON.stringify({ message })}</p> */}
      <MessageThread
        rootMessageId={message.rootMessageId || message.id}
        displaySeen={useAdminView}
        currentMessageId={message.id}
        user={user}
        showReply
        showLoadingSkeleton={false}
        // showRootIfSingle
      />
      {/* <p>{JSON.stringify({ message })}</p> */}
    </div>
  );

  return (
    <Container width="lg:w-3/4" margin="m-3 lg:m-auto" className="relative px-3 py-4 lg:px-6 lg:py-8">
      {displayDeleteConfirmation && (
        <PopUpConfirmation
          title="Delete Message"
          content={`Are you sure you want to delete this message?`}
          onCancel={toggleDeleteConfirmation}
          onConfirm={() => {
            setDeleting(true);
            deleteMessage(message.id, false);
            setTimeout(() => {
              setDeleting(false);
              history.push('/messaging');
            }, 2000);
          }}
          confirmText="DELETE"
          confirmLoading={deleting}
          confirmLoadingText="DELETING"
          className="w-11/12 lg:w-auto"
          destructive
        />
      )}
      {headerSection}
      {content}
    </Container>
  );
};

export default MessageRead;
