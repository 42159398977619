import React from 'react';

const Page = ({ children }) => (
  <div className="mt-10 lg:mt-0 flex flex-1 flex-col pb-6 w-full bg-gray-100 lg:bg-transparent">
    <div
      style={{ zIndex: -10, height: '45vh' }}
      className="fixed bg-no-repeat bg-cover bg-scroll w-screen bg-none lg:mt-24 lg:flex lg:justify-center lg:bg-coin"
    />

    <div className="flex flex-col flex-1 lg:justify-center w-full mt-3 lg:mt-24">{children}</div>
  </div>
);

export default Page;
