import { titleValidator, descriptionValidator, uploadedVideoValidator, categoryValidator } from './fieldValidation.js';

export const validateTutorial = (setAlert, title, description, category, s3Key) => {
  const titleValidation = titleValidator(setAlert, title);
  const descriptionValidation = descriptionValidator(setAlert, description);
  const videoValidation = uploadedVideoValidator(setAlert, s3Key);
  const categoryValidation = categoryValidator(setAlert, category);

  return titleValidation && descriptionValidation && videoValidation && categoryValidation;
};
