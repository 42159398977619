import React, { useCallback, useEffect, useState } from 'react';
import * as Icons from 'react-icons/fa';

import { generateName } from '../../helpers/createAccount/nameGenerator';
import { API, graphqlOperation } from 'aws-amplify';
import { generateAlias } from '../../generated/graphql/mutations';

import ToolTip from '../ToolTip';
import DropDown from '../DropDown';
import TextField from '../TextField';
import Button from '../Button';

const AliasGenerator = ({ alias, setAlias = () => {}, excludeHeader, clearedAt, setMessage = () => {}, message }) => {
  const [gender, setGender] = useState();
  const [startingLetter, setStartingLetter] = useState();
  const [aliasOptions, setAliasOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState('You must select an alias to continue.');
  // const [selectedAlias, setSelectedAlias] = useState();

  // const updateUsersAlias = useCallback(() => {
  //   setAlias(selectedAlias);
  // }, [selectedAlias, setAlias]);

  const generateNewAlias = useCallback(async () => {
    // return generateName(
    //   gender ? gender.value : null,
    //   !startingLetter || startingLetter.value === 'Any Letter' ? null : startingLetter.value
    // );
    try {
      setLoading(true);

      const { data } = await API.graphql(
        graphqlOperation(generateAlias, {
          input: { gender: gender ? gender.value : null, firstInitial: startingLetter ? startingLetter.value : null },
        })
      );
      // console.log({ data });
      setLoading(false);
      return data.generateAlias;
    } catch (error) {
      console.error('Error getting alias options:', error);
      // toast.error('Error getting alias options');
      setLoading(false);
    }
  }, [gender, startingLetter]);

  const createAliasOptions = useCallback(() => {
    const options = [];

    for (let i = 0; i < 5; i++) {
      // options.push(generateName());
      options.push(generateNewAlias());
    }

    setAliasOptions(options);
  });

  // When first loading the component, generate the alias options and set the selected alias to the first option
  useEffect(() => {
    // createAliasOptions();
    addNewAliasOption();
  }, []);

  useEffect(() => {
    if (aliasOptions && !!alias) {
      // setSelectedAlias(aliasOptions[0]);
      setAlias(aliasOptions[0]);
    }
  }, [aliasOptions, alias]);

  const addNewAliasOption = useCallback(async () => {
    const { aliases } = await generateNewAlias();
    // console.log({ aliases });
    if (aliases.length === 0) {
      setMessage('No could be generated for the selected criteria.');
      return;
    }
    setMessage(undefined);
    // Add alias to the options list and remove the last option
    const updatedOptions = [...aliases, ...aliasOptions].slice(0, 5);
    // setSelectedAlias(newAlias);
    // setAlias(updatedOptions[0]);
    setAlias(aliases[0]);
    setAliasOptions(updatedOptions);
    // console.log({ aliases, updatedOptions });

    // setAliasOptions([...aliasOptions, newAlias]);
  }, [aliasOptions, generateNewAlias]);

  // Regen name when options change
  useEffect(() => {
    addNewAliasOption();
  }, [gender, startingLetter]);

  // const selectAltAlias = useCallback(setAlias, [setAlias]);

  // useEffect(() => {
  //   if (!alias) {
  //     const startingAlias = generateName();

  //     setAlias(startingAlias);
  //   }
  // }, [alias, setAlias]);

  useEffect(() => {
    if (clearedAt) {
      setGender(null);
      setStartingLetter(null);
    }
  }, [clearedAt]);

  const updateAlias = useCallback(
    alias => {
      console.log('Setting alias to', alias);
      setAlias(alias);
    },
    [setAlias]
  );

  // useEffect(() => {
  //   setAlias(
  //     generateName(
  //       gender ? gender.value : null,
  //       !startingLetter || startingLetter.value === 'Any Letter' ? null : startingLetter.value
  //     )
  //   );
  // }, [gender, startingLetter, setAlias]);

  const altAliases = aliasOptions.slice(1).map((option, index) => (
    <Button
      // solidBlue
      key={index}
      className="ml-4 flex items-center justify-center"
      onClick={() => updateAlias(option)}>
      {option}
    </Button>
  ));
  return (
    <>
      {!excludeHeader && (
        <div className="flex items-center mb-4">
          <label className="font-bold text-lg text-pursuit-gray mr-2">Generate an Alias</label>
          <ToolTip
            tooltipText="We use an automated username/alias generator to protect the identities of our users from being disclosed on other platform applications. Gender is only asked to generate a random alias."
            title="Why an Alias?"
            className="text-pursuit-gray"
          />
        </div>
      )}

      <div className="flex flex-row">
        <DropDown
          value={gender}
          onChange={value => setGender(value)}
          label="Gender of Name"
          containerClassName="mr-2 mb-4"
          options={[
            { value: null, label: 'Unspecified' },
            { value: 'male', label: 'Male' },
            { value: 'female', label: 'Female' },
          ]}
          key={`${gender}-gender-selection`}
        />

        <DropDown
          value={startingLetter}
          label="First Name Starts With"
          onChange={value => setStartingLetter(value)}
          options={[
            { value: 'Any Letter', label: 'Any Letter' },
            { value: 'A', label: 'A' },
            { value: 'B', label: 'B' },
            { value: 'C', label: 'C' },
            { value: 'D', label: 'D' },
            { value: 'E', label: 'E' },
            { value: 'F', label: 'F' },
            { value: 'G', label: 'G' },
            { value: 'H', label: 'H' },
            { value: 'I', label: 'I' },
            { value: 'J', label: 'J' },
            { value: 'K', label: 'K' },
            { value: 'L', label: 'L' },
            { value: 'M', label: 'M' },
            { value: 'N', label: 'N' },
            { value: 'O', label: 'O' },
            { value: 'P', label: 'P' },
            { value: 'Q', label: 'Q' },
            { value: 'R', label: 'R' },
            { value: 'S', label: 'S' },
            { value: 'T', label: 'T' },
            { value: 'U', label: 'U' },
            { value: 'V', label: 'V' },
            { value: 'W', label: 'W' },
            { value: 'X', label: 'X' },
            { value: 'Y', label: 'Y' },
            { value: 'Z', label: 'Z' },
          ]}
          containerClassName="ml-2 mb-4"
          key={`${startingLetter}-starts-with-selection`}
        />
      </div>

      <div className="flex flex-row items-end mb-4">
        <TextField value={alias} placeHolder="" readonly label={excludeHeader ? 'Alias' : null} required />
        {/* <br />
        {alias} */}

        <Button
          solidBlue
          loading={loading}
          className="w-12 ml-4 flex items-center justify-center"
          onClick={() => {
            // updateUsersAlias();
            addNewAliasOption();
            // setAlias(
            //   generateName(
            //     gender ? gender.value : null,
            //     !startingLetter || startingLetter.value === 'Any Letter' ? null : startingLetter.value
            //   )
            // );
          }}>
          {!loading && <Icons.FaSyncAlt />}
        </Button>
      </div>
      {/* <div className="flex flex-row items-end mb-4">
        {altAliases}
  
      </div> */}
    </>
  );
};

export default AliasGenerator;
