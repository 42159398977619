import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import * as Icons from 'react-icons/fa';
import VerticalSorter from '../VerticalSorter';
import { ReactComponent as EditIcon } from '../../assets/Images/edit.svg';
import { ReactComponent as TrashIcon } from '../../assets/Images/trash.svg';
import Button from '../Button';
import { useUser } from '../../contexts/userContext';
import { parseMarkdown, parseMarkdownPage } from '../../helpers/markdown';
import { ReactComponent as PursuitIcon } from '../../assets/Images/pursuit_icon.svg';
import PopUpConfirmation from '../PopupConfirmation';

const noteSeparator = '\n!---\n';

const noteFilter = (visibility = []) => note => {
  if (
    undefined === note.metadata.visibility ||
    note.metadata.visibility === null ||
    visibility.includes(note.metadata.visibility)
  ) {
    return true;
  }

  return false;
};
/**
 * Split a string of notes into an array of objects with a note and author
 * @param {string} notes
 * @returns {Array<{note: string, metadata: {
 *  author: string,
 *  visibility: string,
 * }}>}
 */
const splitNotes = notes => {
  if (!notes || notes.length === 0) {
    return [];
  }
  const split = notes.split(noteSeparator);

  return split.map(note => {
    const { content, metadata } = parseMarkdownPage(note);

    return { note: content, metadata };
    // if (parse.metadata.author) {
    //   return { note: parse.content, author: parse.metadata.author };
    // }
    // return { note, author: undefined };
  });
};

/**
 * Join an array of note and metadata into a string
 * @param {Array<{note: string, metadata: object}>} notes
 * @returns {string}
 */
const joinNotes = notes =>
  notes
    .map(
      note =>
        `${
          Object.entries(note.metadata).length > 0
            ? `---\n${Object.entries(note.metadata)
                .filter(([key, value]) => value !== null && value !== undefined)
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n')}\n---\n`
            : ''
        }${note.note.trim()}`
    )
    .join(noteSeparator);

const Note = ({
  note: initialNote,
  metadata = {},
  editing: initialEditingState = false,
  editable = false,
  deletable = true,
  editDisabled = false,
  sortable = false,
  collapsable = true,
  collapsed: initialCollapseState = true,
  sortActions = {
    up: undefined,
    down: undefined,
  },
  label = null,
  onEdit = _ => {},
  updateNote = _ => {},
  removeNote = () => {},
}) => {
  const [editing, setEditing] = useState(initialEditingState);
  const [note, setNote] = useState(initialNote);
  const [collapsed, setCollapsed] = useState(initialCollapseState);
  const [overflowActive, setOverflowActive] = useState(false);
  const overflowingText = useRef(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { author, visibility } = metadata;

  const checkOverflow = textContainer => {
    if (textContainer) {
      return (
        textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth
      );
    }

    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      // console.log('overflowing');

      return;
    }
    // console.log('not overflowing');

    setOverflowActive(false);
  }, [overflowActive]);

  const toggleEditing = useCallback(() => {
    onEdit(!editing);
    setEditing(!editing);
  }, [editing, onEdit]);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const collapseButton =
    collapsable && overflowActive ? (
      <Button
        onClick={toggleCollapsed}
        className="w-10 focus:outline-none"
        debounceDelay={0}
        disabled={editing}
        title={collapsed ? 'Expand' : 'Collapse'}
      >
        {collapsed ? (
          <Icons.FaChevronRight
            // size={16}
            className={`${editDisabled ? 'text-gray-200' : ''}`}
            // className={index !== 0 && !isLast ? 'text-guardian-darkblue' : 'text-gray-200'}
          />
        ) : (
          <Icons.FaChevronDown
            // size={16}
            className={`${editDisabled ? 'text-gray-200' : ''}`}
            // className={index !== 0 && !isLast ? 'text-guardian-darkblue' : 'text-gray-200'}
          />
        )}
      </Button>
    ) : null;

  const editButton = editable ? (
    <Button
      onClick={toggleEditing}
      className={`w-10 focus:outline-none ${editDisabled ? 'text-gray' : null}`}
      debounceDelay={0}
      disabled={editing || editDisabled}
    >
      <EditIcon className={`w-5 ${editDisabled ? 'text-gray-200' : null}`} fill="currentColor" />
      {/* <img src={EditIcon} alt="Edit" className="w-5" /> */}
    </Button>
  ) : null;

  const cancelButton = (
    <Button
      className="flex focus:outline-none items-center mb-12 font-semibold lg:text-lg focus:outline-none text-guardian-darkblue my-2 mr-8 px-4 py-1"
      onClick={() => {
        toggleEditing();
        setNote(initialNote);
        if (initialNote === '') {
          removeNote();
        }
      }}
      debounceDelay={0}
    >
      Cancel
    </Button>
  );

  const deleteButton = deletable && (
    <Button
      className="w-10 focus:outline-none"
      onClick={() => {
        // toggleEditing();
        // removeNote();
        setShowDeleteDialog(true);
      }}
      debounceDelay={0}
      disabled={editing || editDisabled}
    >
      {/* Delete */}
      <TrashIcon className={`w-5 ${editDisabled ? 'text-gray-200 opacity-10' : null}`} fill="currentColor" />
    </Button>
  );

  const saveButton = (
    <Button
      solidBlue
      className="my-2 mr-8 px-4 py-1 focus:outline-none"
      onClick={() => {
        onEdit(false);
        updateNote(note);
        toggleEditing();
      }}
      debounceDelay={0}
    >
      Save
    </Button>
  );

  const sortButton = sortable ? (
    <VerticalSorter up={!!sortActions.up} down={!!sortActions.down} onUp={sortActions.up} onDown={sortActions.down} />
  ) : null;

  const noteContent = editing ? (
    <div className="grow-wrap -mr-2" data-replicated-value={note}>
      <textarea
        className="w-full pl-1 mt-0 mb-2 shadow"
        // onInput="this.parentNode.dataset.replicatedValue = this.value"
        value={note}
        onChange={e => setNote(e.target.value)}
      />
    </div>
  ) : (
    <div
      className={`markdown-content ${collapsed ? 'clamp' : ''}`}
      ref={overflowingText}
      dangerouslySetInnerHTML={{
        __html: parseMarkdown(note),
      }}
    />
  );

  const visibilityIcon = useMemo(() => {
    if (visibility === 'employee') {
      return (
        <>
          <div className={`w-8 h-10 flex justify-center items-center ${editDisabled ? 'text-gray' : null}`}>
            <div className="rounded-full bg-white justify-center p-1">
              <PursuitIcon className={`w-5  ${editDisabled ? 'text-gray-200' : null}`} fill="currentColor" />
            </div>
          </div>
        </>
      );
    }

    return null;
  }, [visibility]);

  const dialog = showDeleteDialog ? (
    <PopUpConfirmation
      title="Confirm delete"
      content={`Are sure you want to delete note ${label}`}
      onCancel={() => setShowDeleteDialog(false)}
      onConfirm={() => {
        setShowDeleteDialog(false);
        removeNote();
      }}
      confirmText="Delete"
      // confirmLoading={approving}
      confirmLoadingText="Deleting..."
      className="w-11/12 lg:w-auto"
    />
  ) : null;

  return (
    <>
      {dialog}
      <div className="flex gap-4">
        <div className="flex-none pt-1">{sortButton}</div>
        <div className="flex-none pt-2">{label}</div>
        {/* <div className="flex-none">{collapseButton}</div> */}
        <div className="flex-grow pt-2">
          {noteContent}
          {editing ? (
            <div className="flex justify-end">
              {/* <div>{deleteButton}</div> */}
              <div>{cancelButton}</div>
              <div>{saveButton}</div>
            </div>
          ) : null}
        </div>
        {/* {!editing ? ( */}
        <div className="flex-none">
          <div className="flex">
            {collapseButton}
            {visibilityIcon}
            {editButton}
            {deleteButton}
          </div>
        </div>
        {/* ) : null} */}
        {/* <code>{JSON.stringify({ editing, editable, initialEditingState })}</code> */}
      </div>
    </>
  );
};
const InvestigatorNotes = ({
  notes,
  setNotes = _ => {},
  canEdit = true,
  canDelete = true,
  canSort = true,
  visibility = [],
  newVisibility,
}) => {
  const [parsedNotes, setParsedNotes] = useState(splitNotes(notes));
  const [editingNoteIndex, setEditingNoteIndex] = useState(undefined);
  const { user, userGroups, isEmployee } = useUser();
  // const canEdit = true;
  // const canSort = true;

  // console.log({ user });

  const save = useCallback(
    note => {
      setNotes(Array.isArray(note) ? joinNotes(note) : note);
    },
    [setNotes]
  );

  const addNewNote = useCallback(() => {
    // console.log({ user, userGroups, isEmployee });
    const metadata = {
      author: user.id,
      // visibility: 'employee',
      // visibility: isEmployee ? 'employee' : null,
      visibility: newVisibility,
    };
    const newNotes = [...parsedNotes, { note: '', metadata }];
    // Index needs to account for notes that are filtered out by from parsedNotes
    // const filteredOffset = parsedNotes.filter(n => !noteFilter(visibility)(n)).length || 0;

    // setEditingNoteIndex(newNotes.length - 1 - filteredOffset);
    setEditingNoteIndex(newNotes.length - 1);
    setParsedNotes(newNotes);
    // save(newNotes);
  }, [parsedNotes, user.id]);

  const moveNote = useCallback(
    (from, to) => {
      const newNotes = [...parsedNotes];
      const [removed] = newNotes.splice(from, 1);

      newNotes.splice(to, 0, removed);
      console.log('Saving new notes', newNotes);
      save(newNotes);
      setParsedNotes(newNotes);
    },
    [parsedNotes, save]
  );

  const removeNote = useCallback(
    index => () => {
      const newNotes = [...parsedNotes];

      newNotes.splice(index, 1);
      setParsedNotes(newNotes);
      save(newNotes);
    },
    [parsedNotes, save]
  );

  const onEdit = useCallback(
    index => (editing = true) => {
      setEditingNoteIndex(editing ? index : undefined);
    },
    [setEditingNoteIndex]
  );

  const updateNote = useCallback(
    index => note => {
      const newNotes = [...parsedNotes];

      newNotes[index].note = note;
      console.log({ newNotes });
      setParsedNotes(newNotes);
      save(newNotes);
    },
    [parsedNotes, save]
  );

  const addNoteButton = canEdit ? (
    <Button
      id="case-add-note"
      className={`btton primary-solid-blue h-10 my-2 px-4 py-1 ${editingNoteIndex !== undefined ? 'opacity-20' : null}`}
      onClick={addNewNote}
      debounceDelay={0}
      disabled={editingNoteIndex !== undefined}
    >
      Add Note
    </Button>
  ) : null;

  const renderedNotes = parsedNotes
    // .filter(note => {
    //   if (
    //     undefined === note.metadata.visibility ||
    //     note.metadata.visibility === null ||
    //     visibility.includes(note.metadata.visibility)
    //   ) {
    //     return true;
    //   }

    //   return false;
    // })
    // .filter(Boolean)
    .map((note, i) => ({
      note,
      index: i,
    }))

    .filter(({ note }) => noteFilter(visibility)(note))
    // Move indexes all around to account for notes that are filtered out
    .map(({ note, index: i }, index, accessibleNotes) => {
      // if (noteFilter(visibility)(note)) {
      //   return;
      // }
      const isEditing = i === editingNoteIndex;
      const sortActions =
        editingNoteIndex !== undefined
          ? undefined
          : {
              // up: i > 0 ? () => moveNote(i, i - 1) : undefined,
              // down: i < parsedNotes.length - 1 ? () => moveNote(i, i + 1) : undefined,
              up: index > 0 ? () => moveNote(i, i - 1) : undefined,
              down: index < accessibleNotes.length - 1 ? () => moveNote(i, i + 1) : undefined,
            };
      const label = `${index + 1}`;

      // console.log({ isEditing, i, editingNoteIndex });

      return (
        <li key={note.note + i} className={`my-2 pt-2${i > -1 ? ' border-t border-gray-200' : null}`}>
          <Note
            label={label}
            note={note.note}
            metadata={note.metadata}
            editing={isEditing}
            deletable={canDelete}
            editDisabled={editingNoteIndex !== undefined}
            editable={canEdit}
            sortable={canSort}
            sortActions={sortActions}
            onEdit={onEdit(i)}
            removeNote={removeNote(i)}
            updateNote={updateNote(i)}
          />
        </li>
      );
    }) || <span>No Notes</span>;

  return (
    <div className="flex flex-col mt-8">
      <div className="flex">
        <div className="flex-grow self-center">
          <label className="mb-2 text-lg font-bold text-pursuit-gray">Investigator Notes</label>
        </div>
        <div className="flex-none">{addNoteButton}</div>
      </div>
      {renderedNotes.length > 0 ? (
        <ol className="">{renderedNotes}</ol>
      ) : (
        <span className="text-gray-300">No notes</span>
      )}
      {/* <ol className="">{renderedNotes}</ol> */}
      {/* {editingNoteIndex} */}
      {/* {JSON.stringify({renderedNotes})} */}
    </div>
  );
};

export default InvestigatorNotes;
