import {
  nameValidator,
  firstNameValidator,
  lastNameValidator,
  emailValidator,
  passwordValidator,
  dateOfBirthValidator,
  locationValidator,
  confirmPasswordValidator,
  countryValidator,
  heardAboutValidator,
  zipValidator,
} from './fieldValidation.js';

export const validatePageOne = (setAlert, firstName, lastName, email, password, confirmPassword) => {
  const firstNameValidation = nameValidator(setAlert, firstName);
  const lastNameValidation = nameValidator(setAlert, lastName);
  const emailValidation = emailValidator(setAlert, email);
  const passwordValidation = passwordValidator(setAlert, password);
  const confirmPasswordValidation = confirmPasswordValidator(setAlert, confirmPassword, password);

  return (
    firstNameValidation && lastNameValidation && emailValidation && passwordValidation && confirmPasswordValidation
  );
};

export const validatePageTwo = (setAlert, dateOfBirth, location, agreeToTerms, country, heardAbout, zip) => {
  if (!agreeToTerms) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please read and accept the terms and conditions',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  const countryValidation = countryValidator(setAlert, country);

  let dateOfBirthValidation = false;
  let locationValidation = false;
  let zipValidation = false;
  let heardAboutValidation = false;

  if (countryValidation) {
    locationValidation = locationValidator(setAlert, location, country);
  }

  if (locationValidation) {
    zipValidation = zipValidator(setAlert, zip, country);
  }

  if (zipValidation) {
    dateOfBirthValidation = dateOfBirthValidator(setAlert, dateOfBirth);
  }

  if (dateOfBirthValidation) {
    heardAboutValidation = heardAboutValidator(setAlert, heardAbout);
  }

  return dateOfBirthValidation && locationValidation && countryValidation && heardAboutValidation && zipValidation;
};

export const validateAdminCreateUser = (setAlert, { email, firstName, lastName, dateOfBirth, country, state, zip }) => {
  const emailValidation = emailValidator(setAlert, email);

  let firstNameValidation = false;
  let lastNameValidation = false;
  let dateOfBirthValidation = false;
  let countryValidation = false;
  let stateValidation = false;
  let zipValidation = false;

  if (emailValidation) {
    firstNameValidation = firstNameValidator(setAlert, firstName);
  }

  if (firstNameValidation) {
    lastNameValidation = lastNameValidator(setAlert, lastName);
  }

  if (lastNameValidation) {
    dateOfBirthValidation = dateOfBirthValidator(setAlert, dateOfBirth, true);
  }

  if (dateOfBirthValidation) {
    countryValidation = countryValidator(setAlert, country);
  }

  if (countryValidation) {
    stateValidation = locationValidator(setAlert, state, country);
  }

  if (stateValidation) {
    zipValidation = zipValidator(setAlert, zip, country);
  }

  return (
    firstNameValidation &&
    lastNameValidation &&
    dateOfBirthValidation &&
    stateValidation &&
    countryValidation &&
    zipValidation
  );
};

export const validateAdminCreateLEOUser = (setAlert, { email, firstName, lastName, state }) => {
  const emailValidation = emailValidator(setAlert, email);

  return emailValidation;
  // let firstNameValidation = false;
  // let lastNameValidation = false;
  // let dateOfBirthValidation = false;
  // let countryValidation = false;
  // let stateValidation = false;
  // let zipValidation = false;

  // if (emailValidation) {
  //   firstNameValidation = firstNameValidator(setAlert, firstName);
  // }

  // if (firstNameValidation) {
  //   lastNameValidation = lastNameValidator(setAlert, lastName);
  // }

  // if (lastNameValidation) {
  //   dateOfBirthValidation = dateOfBirthValidator(setAlert, dateOfBirth, true);
  // }

  // if (countryValidation) {
  //   stateValidation = locationValidator(setAlert, state, country);
  // }


  // return (
  //   firstNameValidation &&
  //   lastNameValidation &&
  //   dateOfBirthValidation &&
  //   stateValidation &&
  //   countryValidation &&
  //   zipValidation
  // );
};
