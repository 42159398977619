import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { HeaderText, Button, Container } from '../../../components';
import PrivacyPolicy from '../../public/Privacy/privacyPolicy';

const PrivacyModal = ({ pageChange }) => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/create-account_privacy' });
  }, []);

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto"
    >
      <center>
        <HeaderText noBold className="text-3xl lg:text-4xl">
          Privacy Policy
        </HeaderText>
      </center>
      <br />
      <div className="overflow-y-auto">
        <PrivacyPolicy />
      </div>
      <br />
      <br />
      <center>
        <Button
          solidBlue
          className="mb-2 btton-signup"
          onClick={() => {
            pageChange('page-two');
          }}
        >
          Go Back
        </Button>
      </center>
    </Container>
  );
};

export default PrivacyModal;
