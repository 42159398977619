import React, { useState, useRef, useEffect } from 'react';
import {
  fieldValue,
  readableFormat,
  sortWebSourceFields,
  webSourceFieldLabel,
} from '../../helpers/caseForms/utilHelpers';
import Dropdown from '../DropDown';
import { AwardClassificationOptions, AwardTypes, CaseStatus } from '../../constants';
import TextField from '../TextField';
import PursuitIcon from '../../assets/Images/pursuit_icon.svg';
import { parseMarkdown } from '../../helpers/markdown';
import { getWebSourceSiteLabel, webSourceFields } from '../../helpers/caseForms/webSourceFields';

const awardTypeOptions = [
  { label: 'Account Identified', value: AwardTypes.AccountIdentified },
  { label: 'Verification Source', value: AwardTypes.VerficationSource },
  { label: 'No Points', value: AwardTypes.NoPoints },
];

const ReviewStep = ({
  data,
  index,
  setFeedback,
  setAwardOption,
  awardClassificationOption,
  status,
  showAwardOptions = true,
  showCheckboxes = false,
  toggleCheckbox,
  isChecked,
  hideReview,
  customSorter,
  actions,
}) => {
  const [initialAward] = useState(data.award);
  const [staffSource] = useState(!!data.author);

  // console.log({ data });

  /**
   * This is a hack to get the cursor to stay in the same position when
   * the user is typing in the feedback field. This is because the
   * feedback field is a controlled component and the cursor position
   * is reset when the value is updated.
   */
  const [cursor, setCursor] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    const input = ref.current;

    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, data.feedback]);

  const handleChange = e => {
    setCursor(e.target.selectionStart);

    if (setFeedback) {
      setFeedback({ id: data.id, value: e.target.value });
    }
  };

  function phoneData(arr) {
    return arr.map((obj, i) => (
      <div key={`Field${i}`}>
        <p className="pt-1 font-normal ">
          <b>Phone {i + 1}</b>
        </p>
        <div className="pb-1 pl-2 font-normal ">
          <p>
            <b>Number: </b>
            {obj.number}
          </p>
          <p>
            <b>Type: </b>
            {obj.type}
          </p>
        </div>
      </div>
    ));
  }

  function uniqueData(obj) {
    // const
    const { website } = data.body;
    const webSourceKey = website.toLowerCase().replace(/\s/g, '');
    const orderedFields = Object.keys(webSourceFields[webSourceKey]);
    const sortFunction = sortWebSourceFields(orderedFields);
    const sortedFields = Object.entries(obj).sort(sortFunction);

    // console.log({ webSourceKey, obj, sortedFields, orderedFields: {...orderedFields} });
    // const sortFunction = (a, b) => {
    //   return 0;
    // };
    return Object.entries(obj)
      .sort(sortFunction)
      .map(([field, value], i) => {
        if (!value) {
          return <></>;
        }

        // const readableField = readableFormat(field);
        const readableField = webSourceFieldLabel(field, undefined, true);

        return (
          <p key={`Unique${i}`} className="py-1 font-normal ">
            <b>{`${readableField}: `}</b>
            {/* {value}  */}
            {fieldValue(field, value)}
          </p>
        );
      });
  }

  function defaultData(field, value, i) {
    const formatFieldName = x => {
      if (x.toLowerCase() === 'url') {
        return 'URL';
      }

      return x ? x.charAt(0).toUpperCase() + x.slice(1) : '';
    };

    if (field === 'description') {
      const { website = "" } = data.body;
      const webSourceKey = website.toLowerCase().replace(/\s/g, '');
      return (
        <p key={`Field${i}`} className="py-1 font-normal " style={{ overflowWrap: 'anywhere' }}>
          {/* <b className="text-pursuit-gray">{`${formatFieldName(field)}: `}</b> */}
          <b className="">{`${webSourceFieldLabel(field, webSourceKey, true)}: `}</b>
          <span
            dangerouslySetInnerHTML={{
              __html: parseMarkdown(value),
            }}
          />
          {/* {value} */}
        </p>
      );
    }

    return (
      <p key={`Field${i}`} className="py-1 font-normal " style={{ overflowWrap: 'anywhere' }}>
        <b className="">{`${formatFieldName(field)}: `}</b>
        {value}
      </p>
    );
  }

  const renderStepContent = () => {
    if (!data?.body) return null;
    const stepFields = data.body;
    // const typeOfStep = data.body.website

    // const sortedFields = sortWebSourceFields(Object.entries(stepFields), orderedFields);

    // const fields = [...Object.entries(stepFields)];
    const tmp = [...Object.entries(stepFields)];
    const fields = JSON.parse(JSON.stringify(tmp));
    // console.log({ stepFields, orderedFields, fields });
    // console.log({ stepFields, fields, webSourceKey, sortedFields });

    const idxOfDescription = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'description');
    // If URL is in unique fields keep it in place else move it to the bottom of the list
    const idxOfUrl = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'url');
    if (idxOfUrl !== -1) {
      const url = fields[idxOfUrl];
      // console.log({ website });
      // if (orderedFields.includes('url')) {
        const { website = "" } = stepFields;
      // This is such a hack
      if ('Escort Ad' === website) {
        // Remove spaces and lowercase the key
        const webSourceKey = website.toLowerCase().replace(/\s/g, '');
        const orderedFields = Object.keys(webSourceFields[webSourceKey]);
        const indexOfUniqueFields = fields.findIndex(x => Array.isArray(x) && x.length && x[0] === 'uniqueFields');
        const indexOfNewUrl = orderedFields.indexOf('url');
        // const indexOfUrl = Array.findIndex(fields, x => Array.isArray(x) && x.length && x[0] === 'url');
        // fields[indexOfUniqueFields][1].splice(indexOfNewUrl, 0, url);
        // Add URL to the unique fields
        fields[indexOfUniqueFields][1][url[0]] = url[1];
        // console.log({ fields: { ...fields }, idxOfUrl });
        // Remove URL from the fields
        fields.splice(idxOfUrl, 1);
        // console.log({ fields2: { ...fields } });

        // console.log({ orderedFields, indexOfUniqueFields, idxOfUrl, indexOfNewUrl, url, fields });
      } else {
        const removed = fields.splice(idxOfUrl, 1);
        // console.log({fields, idxOfUrl, removed});
        // fields.push(url);
        fields.splice(fields.length - 1, 0, url);
      }
    }

    // ensure description is the last shown field
    if (idxOfDescription !== -1) {
      // const description = fields[idxOfDescription];
      // fields.splice(idxOfDescription, 1);
      // fields.push(description);
    }

    return (
      <div className="flex flex-col " key={data.id}>
        {fields.map(([field, value], i) => {
          if (field === 'website' || field === 'id') return null;

          // if (field === 'url') return null;

          // Hide staff fields
          if (field.slice(0, 5).toLowerCase() === 'staff') {
            return null;
          }

          if (field === 'phones') {
            return phoneData(value);
          }

          if (field === 'uniqueFields') {
            return uniqueData(value);
            // return null;
          }

          // if (field === 'description') {
          //   return defaultData(field, parseMarkdown(value), i);
          // }

          return defaultData(field, value, i);
        })}

        {!hideReview ? (
          <>
            <label className="mt-4 text-pursuit-gray">Review Feedback</label>
            <textarea
              ref={ref}
              placeholder="Share feedback about this source with the volunteer who submitted the case"
              className="h-24 pl-1 mt-1 mb-2 shadow"
              value={data?.feedback || ''}
              onChange={handleChange}
              // onChange={(event) =>
              //   setFeedback({ id: data.id, value: event.target.value })
              // }
            />
          </>
        ) : null}
      </div>
    );
  };

  const stepContent = (
    <div key={`Data${index}`} className="pt-4 lg:px-12">
      {renderStepContent()}
    </div>
  );

  const getAwardedPoints = () => {
    if (data.award === AwardTypes.VerficationSource) {
      if (awardClassificationOption === AwardClassificationOptions.Underage) {
        return 3;
      }

      return 1;
    }

    if (data.award === AwardTypes.AccountIdentified) {
      if (awardClassificationOption === AwardClassificationOptions.Underage) {
        return 5;
      }

      return 1;
    }

    return 0;
  };

  const getAwardColor = () => {
    if (staffSource) {
      return 'text-white pursuit-staff bg-pursuit-red';
    }
    if (!data.award) {
      return 'bg-white text-pursuit-gray';
    }
    if (data.award === 'NO_POINTS') {
      return 'bg-pursuit-red text-white';
    }

    return 'bg-green-500 text-white';
  };

  return (
    <>
      <div key={`Step${index}`} className="p-2 pb-8 mb-4 font-bold transition-transform duration-500 transform shadow">
        <p className="m-2 mt-0 text-pursuit-darkred">Step {index + 1}</p>
        <div className="flex">
          <div className="flex-grow">
            <div className="flex flex-row mt-4">
              <div className={`${customSorter ? '' : 'hidden '}w-12 lg:block`}>{customSorter}</div>
              <div className="flex flex-col items-center justify-between w-full p-2 lg:mr-4 lg:flex-row bg-guardian-darkblue ">
                <p className="ml-2 text-white">{getWebSourceSiteLabel(data.title)}</p>
                <div className="flex flex-row items-center w-full lg:w-64">
                  {awardClassificationOption && (
                    <div
                      className={`${getAwardColor()} h-6 w-8 rounded-full flex justify-center items-center p-0 mr-4`}>
                      {getAwardedPoints()}
                    </div>
                  )}
                  {staffSource && (
                    <div
                      // className={`${getAwardColor()} h-6 w-8 rounded-full flex justify-center items-center p-0 mr-4`}
                      className="h-6 w-8 rounded-full flex justify-center items-center p-0 mr-4">
                      {/* /   {getAwardedPoints()} */}
                      {/* <FaIcons.FaUsers size={20} /> */}
                      {/* TODO: Link to user */}
                      <div className="bg-white rounded-full p-1 justify-center items-center">
                        <img
                          src={PursuitIcon}
                          alt="Project 1591 pursuit icon"
                          title="Staff contribution"
                          className="w-6"
                        />
                      </div>
                    </div>
                  )}
                  {showAwardOptions && (
                    <Dropdown
                      // value={awardTypeOptions.find(x => x.value === data.award) || null}
                      value={
                        !staffSource
                          ? awardTypeOptions.find(x => x.value === data.award) || null
                          : // : awardTypeOptions.find(x => x.value === AwardTypes.NoPoints) || null
                            { label: 'Staff Contribution', value: null } || null
                      }
                      // value={AwardTypes.NoPoints}
                      onChange={option => setAwardOption({ id: data.id, value: option.value })}
                      width="w-full"
                      options={awardTypeOptions}
                      // disabled
                      disabled={
                        staffSource ||
                        ((status === CaseStatus.Approved || status === CaseStatus.Resubmitted) &&
                          initialAward !== AwardTypes.NoPoints &&
                          initialAward !== null)
                      }
                    />
                  )}
                </div>
              </div>
              {actions}
            </div>
            {stepContent}
          </div>
          {showCheckboxes && (
            <TextField
              noRounded
              noFullFieldWidth
              fieldWidth="w-5"
              fieldHeight="h-5"
              width="w-5"
              type="checkbox"
              onChange={e => {
                toggleCheckbox({ isChecked: e.target.checked, source: data });
              }}
              className="mt-6 ml-2 mr-4 lg:mr-1 md:ml-0"
              checked={isChecked}
            />
          )}
        </div>
      </div>
      {/* <code>{JSON.stringify(data)}</code> */}
    </>
  );
};

export default ReviewStep;
