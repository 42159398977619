import React from 'react';
import * as Icons from 'react-icons/fa';

const VerticalSorter = ({
  up = false,
  down = false,
  onUp = () => {},
  onDown = () => {},
  enabledClassName = 'text-guardian-darkblue',
  disabledClassName = 'text-gray-200',
  buttonClassName = 'm-auto',
  wrapClassName = 'flex flex-col',
  iconSize = 16,
}) => {
  const upArrow = (
    <button className={buttonClassName} onClick={onUp} disabled={!up} type="button">
      <Icons.FaChevronUp size={iconSize} className={up ? enabledClassName : disabledClassName} />
    </button>
  );

  const downArrow = (
    <button className={buttonClassName} onClick={onDown} disabled={!down} type="button">
      <Icons.FaChevronDown size={iconSize} className={down ? enabledClassName : disabledClassName} />
    </button>
  );

  return (
    <div className={wrapClassName}>
      {upArrow}
      {downArrow}
    </div>
  );
};

export default VerticalSorter;
