import React, { useState, useCallback, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createMessage as createAdminMessageMutation,
  createVolunteerMessage as createVolunteerMessageMutation,
  unreadMessage as unreadMessageMutation,
  readMessage as readMessageMutation,
  flagMessage as flagMessageMutation,
  unflagMessage as unflagMessageMutation,
  deleteMessage as deleteMessageMutation,
  assignMessage as assignMessageMutation,
} from '../generated/graphql/mutations';
import {
  listMessages as listMessagesQuery,
  getMessage as getMessageQuery,
  viewMessage as viewMessageQuery,
  listAllMessages as listAllMessagesQuery,
  sentMessages as sentMessagesQuery,
  sentGroupMessages as sentGroupMessagesQuery,
  listGroupMessages as listGroupMessagesQuery,
} from '../generated/graphql/queries';
import { NotificationContext } from '../helpers/AlertContext/AlertContext';
import { toast } from 'react-toastify';

const validateMessage = thing => {
  const { title, message, type, recipients } = thing;
  if (!title || !message || !type || !recipients || !Array.isArray(recipients)) {
    // throw new Error('Missing required notification data');
    console.error('Not a valid message', thing);
    return false;
  }
  return true;
};

const createMessageRequest = async (data, asAdmin = false) => {
  const input = {
    ...data,
  };
  // console.log('input: ', { input, asAdmin });
  // return { id: '123' };
  if (validateMessage(input)) {
    const mutation = asAdmin ? createAdminMessageMutation : createVolunteerMessageMutation;
    const { data: result } = await API.graphql(
      graphqlOperation(mutation, {
        input,
      })
    );
    // console.log('result: ', JSON.stringify(result));
    return result.createMessage || result.createVolunteerMessage;
  } else {
    throw new Error('Missing required message data');
  }
};

const sortableMessageFields = ['createdAt', 'senderAlias', 'title', 'subject', 'recipientAlias'];

export const useMessages = ({ limit, asAdmin = false } = {}) => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [resultLimit, setResultLimit] = useState(limit || 10);
  const [nextToken, setNextToken] = useState(null);
  const [sortField, setSortField] = useState(sortableMessageFields[0]);
  const [sortDirection, setSortDirection] = useState('DESC');
  // const [sortDirection, setSortDirection] = useState('ASC');
  const [, setAlert] = useContext(NotificationContext);

  const setSort = useCallback(
    ({ fieldName, direction, ...rest }) => {
      console.info('Setting field', { fieldName, direction, rest });
      if (sortableMessageFields.includes(fieldName)) {
        setSortField(fieldName);
        setSortDirection(direction);
      } else {
        console.error('Invalid sort field', { fieldName, direction, rest });
      }
    },
    // [setSortField, setSortDirection]
    []
  );

  const addMessage = useCallback(async message => {
    setLoading(true);
    // const { recipients: recipientParams, ...params } = message;
    // const { recipients, ...params } = message;
    // const recipients = recipientParams.split(',');

    const resultIds = [];
    try {
      // console.log({ message });

      const id = await createMessageRequest(message, asAdmin);
      resultIds.push(id);
    } catch (e) {
      setLoading(false);
      throw e;
    }

    setLoading(false);
    return resultIds;
  }, []);

  const loadMessageDetail = useCallback(id => {
    const fetchData = async () => {
      const params = { id };
      // const {
      //   data: { listMessages: messages },
      // } = await API.graphql(graphqlOperation(getMessage, params));
      setLoading(true);
      // let result = null;
      try {
        const {
          data: { getMessage: message },
        } = await API.graphql(graphqlOperation(getMessageQuery, params));
        setLoading(false);
        return message;
      } catch (e) {
        const {
          data: { getMessage: message },
        } = e;
        console.error('Error loading message', e);
        setLoading(false);
        return message;
        // console.log('FAIL5', e);
      }
    };
    // try {
    return fetchData();
    // } catch (e) {
    //   setLoading(false);
    //   console.log('FAIL');
    //   console.error(e);
    // }
  }, []);

  const viewMessageDetail = useCallback(id => {
    const fetchData = async () => {
      const params = { id };
      setLoading(true);
      try {
        const {
          data: { viewMessage: message },
        } = await API.graphql(graphqlOperation(viewMessageQuery, params));
        setLoading(false);
        return message;
      } catch (e) {
        const {
          data: { getMessage: message },
        } = e;
        console.error('Error loading message', e);
        setLoading(false);
        return message;
      }
    };
    return fetchData();
  }, []);

  const listMessages = useCallback(() => {
    const fetchData = async () => {
      const params = {
        limit: resultLimit,
        nextToken,
        sortOrder: sortDirection,
        sortField,
      };
      // const {
      //   data: { listMessages: messages },
      // } = await API.graphql(graphqlOperation(getMessage, params));
      setLoading(true);
      try {
        const {
          data: {
            listMessages: { items, nextToken },
          },
        } = await API.graphql(graphqlOperation(listMessagesQuery, params));
        setLoading(false);
        // console.log({ items });
        const noneNullNextToken = nextToken
          ? Object.keys(nextToken).reduce((acc, key) => {
              if (nextToken[key]) {
                acc[key] = nextToken[key];
              }
              return acc;
            }, {})
          : nextToken;
        setNextToken(noneNullNextToken);
        // setNextToken(nextToken);
        return items;
      } catch (e) {
        console.error(e);
        setLoading(false);
        const { data } = e;
        if (data) {
          const {
            listMessages: { items, nextToken },
          } = data;
          setNextToken(nextToken);
          return items;
        }
        // const {
        //   data: {
        //     listMessages: { items },
        //   },
        // } = e;
      }
    };
    return fetchData();
  }, [sortField, sortDirection, nextToken]);

  const listSentMessages = useCallback(
    ({ group } = {}) => {
      const fetchData = async () => {
        const params = {
          limit: resultLimit,
          group,
          nextToken,
          sortOrder: sortDirection,
          sortField,
        };
        let query = sentMessagesQuery;
        let resultKey = 'sentMessages';
        if (asAdmin) {
          query = sentGroupMessagesQuery;
          resultKey = 'sentGroupMessages';
        }
        // const {
        //   data: { listMessages: messages },
        // } = await API.graphql(graphqlOperation(getMessage, params));
        setLoading(true);
        try {
          const {
            data: {
              [resultKey]: { items, nextToken },
            },
          } = await API.graphql(graphqlOperation(query, params));
          setLoading(false);
          // Create a new next token with all the none null values
          const noneNullNextToken = nextToken
            ? Object.keys(nextToken).reduce((acc, key) => {
                if (nextToken[key]) {
                  acc[key] = nextToken[key];
                }
                return acc;
              }, {})
            : nextToken;
          // setNextToken(nextToken);
          console.log({ noneNullNextToken });
          setNextToken(noneNullNextToken);
          // console.log({ items });
          return items;
        } catch (e) {
          console.error(e);
          setLoading(false);
          const { data } = e;
          if (data) {
            const {
              // listMessages: { items },
              [resultKey]: { items, nextToken },
            } = data;
            const noneNullNextToken = nextToken
              ? Object.keys(nextToken).reduce((acc, key) => {
                  if (nextToken[key]) {
                    acc[key] = nextToken[key];
                  }
                  return acc;
                }, {})
              : nextToken;
            // setNextToken(nextToken);
            console.log({ noneNullNextToken });
            setNextToken(noneNullNextToken);
            return items;
          }
          // const {
          //   data: {
          //     listMessages: { items },
          //   },
          // } = e;
        }
      };
      return fetchData();
    },
    [sortField, sortDirection, nextToken]
  );

  const assignMessage = useCallback(
    async ({ id, userId, name }, updateLoading = true) => {
      const fetchData = async () => {
        const params = {
          input: {
            id,
            // : messageId,
            assignedToName: name,
            assignedTo: userId,
          },
        };
        updateLoading && setLoading(true);
        try {
          const {
            data: { assignMessage: message },
          } = await API.graphql(graphqlOperation(assignMessageMutation, params));
          updateLoading && setLoading(false);
          // const oldMessages = [...messages];
          // const messageToUpdateIdx = oldMessages.findIndex(x => x.id === id);
          // if (messageToUpdateIdx !== -1) {
          //   const updatedMessage = oldMessages[messageToUpdateIdx];
          //   updatedMessage.assignedTo = userId;
          //   updatedMessage.assignedToName = name;
          //   oldMessages[messageToUpdateIdx] = updatedMessage;
          //   setMessages([...oldMessages]);
          // }
          // console.log({ message, oldMessages, messageToUpdateIdx, messages });
          toast.success('Message assigned', {
            progress: false,
            className: 'bg-green-500 text-white',
            autoClose: 1500,
            closeButton: false,
            // icon: () => <Icons.FaCheck size={18} className="text-white" />,
          });
          return message;
        } catch (e) {
          updateLoading && setLoading(false);
          // const {
          //   // data: { assignMessage: message = undefined },
          //   errors: [{ message: errorMessage }],
          // } = e;
          console.error('Error assigning message: ', e);
          setAlert({
            type: 'SET_NOTIFICATION',
            payload: {
              occurs: true,
              message: e,
              textColor: 'redText',
              borderColor: 'redBorder',
            },
          });
          return null;
        }
      };
      return fetchData();
    },
    [messages, setMessages]
  );
  const resetNextToken = useCallback(() => {
    setNextToken(null);
  }, []);

  const listAllMessages = useCallback(() => {
    const fetchData = async () => {
      const params = {
        limit: resultLimit,
        nextToken,
        sortOrder: sortDirection,
        sortField,
      };
      setLoading(true);
      try {
        const {
          data: {
            listAllMessages: { items },
          },
        } = await API.graphql(graphqlOperation(listAllMessagesQuery, params));
        setLoading(false);
        return items;
      } catch (e) {
        console.error(e);
        setLoading(false);
        const { data } = e;
        if (data) {
          const {
            listMessages: { items },
          } = data;
          return items;
        }
      }
    };
    return fetchData();
  }, [sortField, sortDirection, nextToken]);

  const listSharedMessages = useCallback(
    ({ group, condense = true, condenseOn = 'rootMessageId' }) => {
      const fetchData = async () => {
        const params = {
          limit: resultLimit,
          group,
          nextToken,
          sortOrder: sortDirection,
          sortField,
        };
        // console.log({ params });
        setLoading(true);
        try {
          const {
            data: {
              listGroupMessages: { items, nextToken },
            },
          } = await API.graphql(graphqlOperation(listGroupMessagesQuery, params));
          setLoading(false);
          const noneNullNextToken = nextToken
            ? Object.keys(nextToken).reduce((acc, key) => {
                if (nextToken[key]) {
                  acc[key] = nextToken[key];
                }
                return acc;
              }, {})
            : nextToken;
          setNextToken(noneNullNextToken);
          // setNextToken(nextToken);
          return condense
            ? items.reduce((acc, cur) => {
                const existingObject = cur && cur[condenseOn] && acc.find(x => x[condenseOn] === cur[condenseOn]);
                if (!existingObject) {
                  cur.threadCount = 1;
                  acc.push(cur);
                } else {
                  existingObject.threadCount += 1;
                }
                return acc;
              }, [])
            : items;
        } catch (e) {
          console.error(e);
          setLoading(false);
          const { data } = e;
          if (data) {
            const {
              listGroupMessages: { items },
            } = data;
            return items;
          }
        }
      };
      return fetchData();
    },
    [sortField, sortDirection, nextToken]
  );

  const readMessage = useCallback((id, updateLoading = true) => {
    const fetchData = async () => {
      const params = { id };
      updateLoading && setLoading(true);
      try {
        const {
          data: { readMessage: message },
        } = await API.graphql(graphqlOperation(readMessageMutation, params));
        updateLoading && setLoading(false);
        return message;
      } catch (e) {
        updateLoading && setLoading(false);
        const {
          data: { readMessage: message },
        } = e;
        console.error(e);
        return message;
      }
    };
    return fetchData();
  }, []);
  const flagMessage = useCallback((id, updateLoading = true) => {
    const fetchData = async () => {
      const params = { id };
      updateLoading && setLoading(true);
      try {
        const {
          data: { flagMessage: message },
        } = await API.graphql(graphqlOperation(flagMessageMutation, params));
        updateLoading && setLoading(false);
        return message;
      } catch (e) {
        updateLoading && setLoading(false);
        const {
          data: { readMessage: message },
        } = e;
        console.error(e);
        return message;
      }
    };
    return fetchData();
  }, []);

  const deleteMessage = useCallback((id, updateLoading = true) => {
    const fetchData = async () => {
      const params = { id };
      updateLoading && setLoading(true);
      try {
        const {
          data: { deleteMessage: message },
        } = await API.graphql(graphqlOperation(deleteMessageMutation, params));
        updateLoading && setLoading(false);
        return message;
      } catch (e) {
        updateLoading && setLoading(false);
        const {
          data: { deleteMessage: message },
        } = e;
        console.error(e);
        return message;
      }
    };
    return fetchData();
  }, []);

  const unreadMessage = useCallback((id, updateLoading = true) => {
    const fetchData = async () => {
      const params = { id };
      updateLoading && setLoading(true);
      try {
        const {
          data: { unreadMessage: message },
        } = await API.graphql(graphqlOperation(unreadMessageMutation, params));
        updateLoading && setLoading(false);
        return message;
      } catch (e) {
        updateLoading && setLoading(false);
        const {
          data: { unreadMessage: message },
        } = e;
        console.error(e);
        return message;
      }
    };
    return fetchData();
  }, []);

  const unflagMessage = useCallback((id, updateLoading = true) => {
    const fetchData = async () => {
      const params = { id };
      updateLoading && setLoading(true);
      try {
        const {
          data: { unflagMessage: message },
        } = await API.graphql(graphqlOperation(unflagMessageMutation, params));
        updateLoading && setLoading(false);
        return message;
      } catch (e) {
        updateLoading && setLoading(false);
        const {
          data: { unreadMessage: message },
        } = e;
        console.error(e);
        return message;
      }
    };
    return fetchData();
  }, []);

  return {
    loading,
    messages,
    messageCount,
    setMessages,
    addMessage,
    loadMessageDetail,
    listMessages,
    listSentMessages,
    listAllMessages,
    listSharedMessages,
    readMessage,
    viewMessageDetail,
    unreadMessage,
    setSort,
    setResultLimit,
    flagMessage,
    unflagMessage,
    deleteMessage,
    assignMessage,
    resetNextToken,
    // sort: { field: sortField, direction: sortDirection },
    sortField,
    sortDirection,
    sortableMessageFields,
    nextToken,
  };
};

export const isMessageFromAdmin = message => {
  const adminGroup = 'Admin';
  return (message.senderGroups || []).includes(adminGroup);
};

export const getInboxUid = group => `#inbox-group#${group.toLowerCase()}`;
