import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import HeaderText from '../../../components/HeaderText';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import Container from '../../../components/Container';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import { useUser } from '../../../contexts/userContext';
import { toast } from 'react-toastify';

const ConfirmAccount = ({ authData }) => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const { user, setUser, loadUser, setIsLoading } = useUser();
  const history = useHistory();

  const [, setAlert] = useContext(NotificationContext);

  const signIn = async () => {
    try {
      setIsLoading(true);
      const cognitoUser = await Auth.signIn({
        username: authData.email,
        password: authData.password,
      });

      setUser({ ...user, cognitoUser });

      await loadUser();

      history.push('/');
    } catch (error) {
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: error.message,
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
  };

  const confirmSignUp = async () => {
    try {
      setLoading(true);

      // console.error('Skipping confirmation code for now.')
      await Auth.confirmSignUp(authData.email, code);

      setCode('');
      console.log({ authData });
      if (authData.password !== '') {
        signIn();
      } else {
        // history.push('/login#verify-account', { origin: 'verifyAccount', email: authData.email });
        toast.success(`Account verified`, {
          progress: false,
          className: 'bg-green-500 text-white',
          autoClose: 1500,
          closeButton: false,
        });
        history.push('/forgot-password', { origin: 'verifyAccount', email: authData.email });
        // window.location.reload()
      }
    } catch (error) {
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: error.message,
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });

      setLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      setResending(true);

      // This won't work of account is confirmed but email is not verified
      await Auth.resendSignUp(authData.email);

      // Auth.verifyCurrentUserAttribute('email')

      setResending(false);
    } catch (error) {
      setResending(false);
      console.error('Error resending code: ', error);
      if (error.message === 'User is already confirmed.') {
        const response = await Auth.verifyCurrentUserAttribute('email');
        console.log({ response });
      } else {
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: error.message,
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    }
  };

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto">
      <center>
        <HeaderText noBold className="text-3xl lg:text-4xl">
          Confirm Sign Up
        </HeaderText>
      </center>
      <TextField
        type="code"
        value={code}
        onChange={e => setCode(e.target.value)}
        placeHolder="Code"
        label="Confirmation Code"
      />
      <Button solidBlue onClick={confirmSignUp} className="mt-4" loading={loading}>
        {loading ? 'SUBMITTING' : 'SUBMIT'}
      </Button>
      <div className="flex justify-end mt-4">
        <Button anchorTag onClick={resendCode} loading={resending}>
          {resending ? 'Resending Code' : 'Resend Code'}
        </Button>
      </div>
    </Container>
  );
};

export default ConfirmAccount;
