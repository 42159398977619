import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import HeaderLogo from '../../assets/Images/guardian_group_logo.svg';

import Button from '../Button';

const AuthHeader = () => {
  const history = useHistory();
  const location = useLocation();

  const showButtons = location.pathname === '/';

  return (
    <nav className="fixed z-30 flex flex-row items-center justify-between w-screen h-12 px-3 bg-white shadow-lg lg:h-24 lg:border-b lg:border-gray-300 lg:shadow-none">
      {!showButtons && <div className="lg:hidden" />}

      <Link to="/">
        <img className="h-10 py-1 lg:h-24 lg:my-2 lg:py-2 lg:ml-4" src={HeaderLogo} alt="Guardian Group Logo" />
      </Link>

      {showButtons ? (
        <div className="flex flex-no-wrap lg:pr-12 whitespace-no-wrap">
          <Button solidRed onClick={() => history.push('login')} className="px-3 text-base lg:w-40 mx-1 md:mx-2" inline>
            <span className="text-xs lg:text-base">SIGN IN</span>
          </Button>

          <Button
            solidBlue
            onClick={() => history.push('create-account')}
            // className="px-3 ml-3 text-base lg:w-56 lg:mx-12"
            className="px-3 text-base lg:w-40 mx-1 md:mx-2"
            inline>
            <span className="text-xs lg:text-base">REGISTER</span>
          </Button>

          <Button
            solidLightGray
            onClick={() =>
              (window.location.href =
                'https://guardiangroup.org/team1591/?utm_source=p1591&utm_medium=header-button&utm_campaign=donate')
            }
            className="px-3 text-base lg:w-40 mx-1 md:mx-2"
            // style={{ backgroundColor: '#666166' }}
            inline>
            <span className="text-xs lg:text-base">DONATE</span>
          </Button>
        </div>
      ) : (
        <div className="lg:hidden" />
      )}
    </nav>
  );
};

export default AuthHeader;
