export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '';

  // filter out non-numerical input
  const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

  const { length } = formattedPhoneNumber;

  if (length < 4) {
    return formattedPhoneNumber;
  }
  if (length < 7) {
    return `${formattedPhoneNumber.slice(0, 3)}-${formattedPhoneNumber.slice(3)}`;
  }

  return `${formattedPhoneNumber.slice(0, 3)}-${formattedPhoneNumber.slice(3, 6)}-${formattedPhoneNumber.slice(6, 10)}`;
}
