import React from 'react';

const HeaderText = ({ fontSize, noBold, className, children, mb }) => (
  <div
    className={`${mb || 'mb-2'} primary-blue-text font-display ${noBold ? '' : ' font-bold'} ${fontSize ||
      'text-xl'} ${className}`}
  >
    {children}
  </div>
);

export default HeaderText;
