import React from 'react';
import { CaseStatus, AwardTypes } from '../../constants';

const Feedback = ({ award, comments, status, stepId }) => {
  // if (status !== CaseStatus.Returned && status !== CaseStatus.Approved && status !== CaseStatus.Resubmitted) {
  //   return <></>;
  // }

  // if (award === AwardTypes.AccountIdentified || award === AwardTypes.VerficationSource) {
  //   return <></>;
  // }

  if (!Array.isArray(comments) || !comments.length) {
    return <></>;
  }

  const comment = comments.find(x => x.stepId === stepId);

  if (!comment || !comment.feedback) {
    return <></>;
  }

  return (
    <div className="p-4 my-8 bg-white rounded">
      <label className="text-lg font-bold text-pursuit-darkred">Feedback from Guardian Group on this step:</label>
      <p className="text-pursuit-darkred">{comment.feedback}</p>
    </div>
  );
};

export default Feedback;
