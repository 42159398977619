import React, { useState, useEffect, useCallback } from 'react';
import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';
import debounce from 'lodash.debounce';
import { ToastContainer } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
import ReactGA from 'react-ga4';
import AppRouting from './routing';
import { UserProvider } from './contexts/userContext';
import { useFocus } from './contexts/focusContext';
import AlertStore from './helpers/AlertContext/AlertContext.js';
import { addActiveTimeS } from './generated/graphql/mutations';

import config from './generated/aws-exports';

Amplify.configure(config);

API.configure({
  graphql_headers: async () => {
    try {
      const currentSession = await Auth.currentSession();

      const authorization = currentSession.getIdToken().getJwtToken();

      return {
        Authorization: authorization,
      };
    } catch (error) {
      //
    }
  },
});

const App = () => {
  useEffect(() => {
    if (typeof process.env.REACT_APP_GOOGLE_MEASUREMENT_ID === 'string') {
      const ids = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID.split(',');
      const gaConfig = ids.map(id => ({
        trackingId: id,

        // gaOptions: {
        //   debug_mode: true
        // }
      }));

      // Enable debugging for development
      if (process.env.NODE_ENV === 'development') {
        gaConfig[0].gaOptions = {
          debug_mode: true,
        };
      }

      // gaConfig[0].debug_mode = true;
      // console.log('gaConfig', gaConfig);

      ReactGA.initialize(gaConfig);
    }
  }, []);

  const [user, setUser] = useState(null);
  const { isFocused } = useFocus();
  const autoLogoffTime = 1000 * 60 * 60 * 2;

  const DEBOUNCE_SAVE_DELAY_MS = 250;

  useEffect(() => {
    const lastSavedTime = parseInt(localStorage.getItem('lastSavedTime'));
    const currTime = parseInt(new Date().getTime() / 1000);

    if (currTime - autoLogoffTime / 1000 > lastSavedTime) {
      signOutUser();
    }
  }, []);

  useEffect(() => {
    if (isFocused) {
      const lastSavedTime = parseInt(localStorage.getItem('lastSavedTime'));
      const currTime = parseInt(new Date().getTime() / 1000);

      if (lastSavedTime !== null && currTime - autoLogoffTime / 1000 > lastSavedTime) {
        signOutUser();
      }
    }
  }, [isFocused]);

  const handleOnIdle = async () => {
    // if user goes idle, do not increment activeTimeMS. The previous time was not active.
    signOutUser();
  };

  const handleOnActionWithDebounce = useCallback(
    debounce(async () => {
      await handleOnAction();
    }, DEBOUNCE_SAVE_DELAY_MS),
    [user]
  );

  const handleOnAction = async () => {
    const lastSavedTime = parseInt(localStorage.getItem('lastSavedTime'));
    const currTime = parseInt(new Date().getTime() / 1000);

    const min15 = 60 * 15; // save after 2 min

    if (user !== null) {
      if (currTime - lastSavedTime > min15) {
        await addActiveTime(lastSavedTime, currTime);
      }
    }
  };

  const { reset } = useIdleTimer({
    crossTab: {
      type: 'localStorage',
      emitOnAllTabs: true,
    },
    timeout: autoLogoffTime,
    onIdle: handleOnIdle,
    onAction: handleOnActionWithDebounce,
    debounce: 500,
  });

  const addActiveTime = async (lastSavedTime, currTime) => {
    const input = {
      lastSavedTime,
      currTime,
    };

    try {
      const newLastSavedTime = await API.graphql(graphqlOperation(addActiveTimeS, { input }));

      localStorage.setItem('lastSavedTime', `${newLastSavedTime.data.addActiveTimeS}`);
    } catch (error) {
      console.error('error on adding activeTime', error);
    }
  };

  const signOutUser = () => {
    Auth.signOut();

    setUser(null);
    reset(); // reset idleTimer

    try {
      localStorage.clear();
    } catch (error) {
      console.error(error);
    }
    console.log('logged off');
  };

  if (!localStorage.getItem('lastSavedTime') || !localStorage.getItem('timeSessionStarted')) {
    const currTime = parseInt(new Date().getTime() / 1000);

    localStorage.setItem('timeSessionStarted', currTime);
    localStorage.setItem('lastSavedTime', currTime);
    reset();
  }

  return (
    <UserProvider user={user} setUser={setUser}>
      <AlertStore>
        <AppRouting key={`${user?.id}-routing`} />

        <ToastContainer position="top-right" closeOnClick pauseOnFocusLoss pauseOnHover className="mt-24" />
      </AlertStore>
    </UserProvider>
  );
};

export default App;
