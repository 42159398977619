import React, { useContext, useState, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import * as Icons from 'react-icons/fa';
import Button from '../Button';

import { NotificationContext } from '../../helpers/AlertContext/AlertContext.js';

const FileUploader = ({
  onSuccess,
  onCancel,
  noCancel = false,
  saveText = 'SAVE AND SUBMIT',
  prefix = '',
  level = 'protected',
  errorMessage = 'You have unsuccessfully uploaded your Training Certificate',
  noPrefix = false,
  isUpdate = false,
  autoSave = false,
  acceptedFileTypes = '',
  uploadObject = 'File',
}) => {
  const [fileURL, setFileURL] = useState('');
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [fileType, setFileType] = useState();
  const [, setAlert] = useContext(NotificationContext);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [uploadBoxText, setUploadBoxText] = useState(
    isUpdate ? `Replace Current ${uploadObject}` : `Upload ${uploadObject}`
  );

  const cancelFile = async () => {
    if (saved) {
      setSaved(false);
    }
    const cancelRes = await onCancel();

    if (cancelRes) {
      setUploadBoxText('Successfully Deleted. Upload File.');
      setFilename('');
      setFileURL('');
    }
  };

  const saveFile = useCallback(
    // async ({ filename = filename, file = file, prefix = prefix, level = level }) => {
    async () => {
      try {
        setSaving(true);

        const fileParams = { level };

        if (fileType) {
          fileParams.contentType = fileType;
        }
        if (noPrefix) {
          fileParams.customPrefix = { public: '' };
        }

        const timestamp = new Date().getTime().toString();

        // console.log({ prefix, timestamp, filename, fileParams });
        // const uploadResult = {
        //   key: `${prefix ? `${prefix}/${timestamp}_${filename}` : `${timestamp}_${filename}`}`,
        // };
        const uploadResult = await Storage.put(
          `${prefix ? `${prefix}/${timestamp}_${filename}` : `${timestamp}_${filename}`}`,
          file,
          fileParams
        );

        if (onSuccess) {
          // get duration in seconds START HERE!!, was working
          const meta = {
            filename,
            timestamp,
          };
          onSuccess(uploadResult.key, URL.createObjectURL(file), meta);
          setSaved(true);
          setUploadBoxText('SAVED');
          console.log({ uploadResult });
        }

        setFileURL('');
        setFile('');
      } catch (error) {
        console.error('Error uploading file: ', error);

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: errorMessage,
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }

      setSaving(false);
    },
    [file, filename, fileType, fileURL]
  );

  // const autoSaveFile = useCallback(() => {
  //   saveFile();
  // },  [file, filename, fileType, fileURL]);

  const upload = useCallback(
    f => {
      const fileForUpload = f.target.files[0];

      if (fileForUpload && fileForUpload.type.split('/')[0] === 'image') {
        const fileUrl = URL.createObjectURL(fileForUpload);

        setFileURL(fileUrl);
      } else {
        setFileURL('');
      }

      setFile(fileForUpload);
      setFileType(fileForUpload.type || null);

      setFilename(`${fileForUpload.name}`);

      // if (autoSave) {
      //   autoSaveFile();
      //   // setTimeout(async () => {
      //   // saveFile({
      //   //   filename: fileForUpload.name,
      //   //   file: fileForUpload,
      //   //   prefix,
      //   //   level,
      //   // });
      //   // }, 2000);
      // }
    },
    [saveFile]
  );

  const dragOver = e => {
    e.preventDefault();
  };

  const dragEnter = e => {
    e.preventDefault();
  };

  const dragLeave = e => {
    e.preventDefault();
  };

  const fileDrop = e => {
    e.preventDefault();

    const { files } = e.dataTransfer;

    const fileForUpload = files[0];

    if (fileForUpload && fileForUpload.type.split('/')[0] === 'image') {
      const fileUrl = URL.createObjectURL(fileForUpload);

      setFileURL(fileUrl);
    } else {
      setFileURL('');
    }
    setFile(fileForUpload);
    setFileType(fileForUpload.type || null);

    setFilename(`${fileForUpload.name}`);
  };

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex justify-center m-1 bg-gray-100 shadow-lg"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}>
        <label className="flex flex-col items-center px-4 py-6 tracking-wide uppercase w-68 h-34">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="60"
            viewBox="0 0 46 70"
            className="text-gray-200 fill-current">
            <g transform="translate(-27 -967.362)">
              <path
                className="a"
                d="M50,967.362a3.363,3.363,0,0,0-2.062.813l-17,16c-.974,1.084-1.376,3.163-.25,4.375a3.125,3.125,0,0,0,4.375-.031L47,977.268v47.094a3,3,0,0,0,6,0V977.268l11.938,11.25a3.305,3.305,0,0,0,4.375.031,3.392,3.392,0,0,0-.25-4.375l-17-16A2.885,2.885,0,0,0,50,967.362Zm-20,64a3,3,0,0,0,0,6H70a3,3,0,0,0,0-6Z"
              />
            </g>
          </svg>

          <span className="pt-8 mb-5 ml-0 text-sm leading-normal mt-7">{uploadBoxText}</span>

          <input type="file" className="hidden" onChange={upload} accept={acceptedFileTypes} />

          {fileURL ? (
            <img className="w-12 h-12" src={fileURL} alt="file-preview" />
          ) : (
            filename && (
              <span className="flex flex-row items-center">
                <Icons.FaFile className="mr-2" />
                {filename}
              </span>
            )
          )}
        </label>
      </div>

      <div className="flex flex-col lg:flex-row-reverse">
        <Button solidBlue className="w-full mt-4 lg:ml-2" onClick={saveFile} loading={saving} disabled={saved}>
          {saving ? 'SAVING' : saved ? 'SAVED' : saveText}
        </Button>

        {!noCancel ? (
          <Button linedBlue className="w-full mt-4 lg:mr-2" onClick={cancelFile}>
            CANCEL
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default FileUploader;
