import React, { useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { FileUploader, Container, HeaderText } from '../../../components';
import { submitCertification } from '../../../generated/graphql/mutations';
import { useUser } from '../../../contexts/userContext';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';

const CertificateUpload = () => {
  const { user, setUser } = useUser();
  const [, setAlert] = useContext(NotificationContext);

  const history = useHistory();

  const successfullUpload = async s3Key => {
    try {
      const params = {
        certificatePath: s3Key,
      };

      const {
        data: { submitCertification: updatedUser },
      } = await API.graphql(graphqlOperation(submitCertification, params));

      setUser({ ...user, updatedUser });

      history.push('/thank-you');
    } catch (error) {
      console.error('Error saving user on upload: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An unexpected error occurred',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
  };

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col relative"
      margin="mx-3 md:mx-12 lg:mx-auto"
    >
      <center>
        <HeaderText fontSize="text-3xl lg:text-4xl" noBold="font-light">
          Completed Training
          <br />
          Certificate Upload
        </HeaderText>
      </center>

      <div className="w-400 p-9 pt-4 pb-20">
        <FileUploader onSuccess={successfullUpload} onCancel={() => history.push('/required-training')} />
      </div>
    </Container>
  );
};

export default CertificateUpload;
