import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Dashboard, Investigation, ActiveCases, Success } from '../pages/private';
import { MessageRead, MessageIndex, MessageCreate } from '../pages/employee/Messaging';
// import { MessageIndex } from '../pages/employee/Messaging';

const VolunteerRouting = () => (
  <Switch>
    <Route exact path={['/', '/dashboard']}>
      <Dashboard />
    </Route>

    <Route path="/messaging/read/:messageId">
      <MessageRead />
    </Route>
    <Route path="/messaging/create">
      <MessageCreate />
    </Route>
    <Route path="/messaging">
      <MessageIndex />
    </Route>

    <Route path="/investigation/:idParam">
      <Investigation />
    </Route>

    <Route path="/active-cases">
      <ActiveCases />
    </Route>

    <Route path="/success">
      <Success />
    </Route>

    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default VolunteerRouting;
