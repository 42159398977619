/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Select from 'react-select';
import SelectAsync from 'react-select/async';

const DropDown = props => {
  const {
    label,
    width,
    height,
    containerClassName,
    className,
    noFullFieldWidth,
    noRounded,
    fieldHeight,
    fieldWidth,
    fieldPadding,
    fieldClassName,
    onChange,
    value,
    options,
    getOptionLabel,
    getOptionValue,
    multiSelect,
    placeholder,
    loading,
    loadOptions,
    isClearable,
    styles,
    required,
    disabled,
    tabIndex,
  } = props;

  const [widthStyle] = useState(width || 'w-full');
  const [heightStyle] = useState(height ? ` ${height}` : '');
  const [classNameStyle] = useState(containerClassName ? ` ${containerClassName}` : '');
  const [noFullFieldWidthStyle] = useState(noFullFieldWidth ? '' : ' w-full');
  const [noRoundedStyle] = useState(noRounded ? '' : ' rounded');
  const [fieldHeightStyle] = useState(fieldHeight ? ` ${fieldHeight}` : ' h-10');
  const [fieldWidthStyle] = useState(fieldWidth ? ` ${fieldWidth}` : '');
  const [fieldPaddingStyle] = useState(fieldPadding ? ` ${fieldPadding}` : '');
  const [fieldClassNameStyle] = useState(fieldClassName ? ` ${fieldClassName}` : '');
  const [optionsAttribute] = useState(options);

  const [labelComponent] = useState(
    label ? (
      <p className="light-primary-blue-text font-light mb-1">
        {label}
        {required && <span className="text-pursuit-red ml-1">*</span>}
      </p>
    ) : (
      ''
    )
  );

  const childProps = {
    placeholder,
    options: optionsAttribute,
    value,
    onChange,
    isMulti: multiSelect,
    className: `${noFullFieldWidthStyle} ${noRoundedStyle} ${fieldHeightStyle} ${fieldWidthStyle} ${fieldPaddingStyle} ${fieldClassNameStyle} ${className}`,
    getOptionLabel: option => (getOptionLabel ? getOptionLabel(option) : option.label),
    getOptionValue: option => (getOptionValue ? getOptionValue(option) : option.value),
    isLoading: loading || false,
    defaultOptions: true,
    cacheOptions: true,
    isClearable,
    tabIndex,
    loadOptions: loadOptions || (async () => options),
    styles,
    isDisabled: !!disabled,
  };

  return (
    <div className={`${widthStyle} ${heightStyle} ${classNameStyle} ${disabled ? 'cursor-not-allowed' : ''}`}>
      {labelComponent}

      {loadOptions ? <SelectAsync {...childProps} /> : <Select {...childProps} />}
    </div>
  );
};

export default DropDown;
