import { DateTime } from 'luxon';

import {
  ifContainNumbers,
  ifEmail,
  ifContainsUpperCase,
  ifContainsLowerCase,
  ifContainsSpecialCharacters,
  ifFieldValuesMatch,
} from '../validationHelpers.js';

export const nameValidator = (setAlert, name) => {
  const nameEmpty = !name;
  const nameContainsNumbers = ifContainNumbers(name);

  if (nameEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a name',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  } else if (nameContainsNumbers) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Your name cannot contain numbers',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !nameEmpty && !nameContainsNumbers;
};

export const firstNameValidator = (setAlert, name) => {
  const nameEmpty = !name;

  if (nameEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a first name',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !nameEmpty;
};

export const lastNameValidator = (setAlert, name) => {
  const nameEmpty = !name;

  if (nameEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a last name',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !nameEmpty;
};

export const emailValidator = (setAlert, email) => {
  const emailEmpty = !email;
  const validEmail = ifEmail(email);

  if (emailEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter an email',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  } else if (!validEmail) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a valid email',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !emailEmpty && validEmail;
};

export const passwordValidator = (setAlert, password) => {
  const passwordEmpty = !password;
  const passwordLength = password && password.length >= 8;
  const passwordHasNumber = ifContainNumbers(password);
  const passwordHasUpperCase = ifContainsUpperCase(password);
  const passwordHasLowerCase = ifContainsLowerCase(password);
  const passwordHasSpecialCharacter = ifContainsSpecialCharacters(password);

  if (passwordEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a password',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  } else if (
    !passwordLength ||
    !passwordHasNumber ||
    !passwordHasUpperCase ||
    !passwordHasLowerCase ||
    !passwordHasSpecialCharacter
  ) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message:
          'Your password must be 8 characters long, have at least one number, one upper case letter, one lower case letter, and one special character',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return (
    !passwordEmpty &&
    passwordLength &&
    passwordHasNumber &&
    passwordHasUpperCase &&
    passwordHasLowerCase &&
    passwordHasSpecialCharacter
  );
};

export const confirmPasswordValidator = (setAlert, confirmPassword, password) => {
  const confirmPasswordEmpty = !confirmPassword;
  const passwordsMatch = ifFieldValuesMatch(password, confirmPassword);

  if (confirmPasswordEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter your password confirmation',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  } else if (!passwordsMatch) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Your passwords do not match',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !confirmPasswordEmpty && passwordsMatch;
};

export const locationValidator = (setAlert, location, country) => {
  if (!location && country === 'United States') {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a state',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !!location || country !== 'United States';
};

export const zipValidator = (setAlert, zip, country) => {
  if (!zip && country === 'United States') {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a ZIP Code',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  if (zip && country === 'United States') {
    const regex = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');

    const isValid = regex.test(zip);

    if (!isValid) {
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'ZIP Code must be in format 12345 or 12345-1234',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    return isValid;
  }

  return true;
};

export const dateOfBirthValidator = (setAlert, dateOfBirth, isAdminCreate) => {
  if (!dateOfBirth) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: isAdminCreate ? 'Please enter a date of birth' : 'Please enter your date of birth',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  const luxonDate = DateTime.fromJSDate(dateOfBirth);

  if (!luxonDate.isValid) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Invalid date of birth provided',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  const now = DateTime.fromJSDate(new Date());

  const yearsDiff = now.diff(luxonDate, 'years').years;

  if (yearsDiff < 18) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: isAdminCreate
          ? 'Cannot create a new user under the age of 18 years old'
          : 'You must be at least 18 years old to register for this application',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });

    return false;
  }

  return true;
};
export const countryValidator = (setAlert, country) => {
  if (!country) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a country',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !!country;
};

export const heardAboutValidator = (setAlert, heardAbout) => {
  if (!heardAbout) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter how you heard about us',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !!heardAbout;
};
