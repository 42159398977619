import React, { createContext, useContext, useEffect, useState } from 'react';

const FocusContext = createContext({
  isFocused: null,
});

const FocusContextProvider = ({ children }) => {
  const [windowIsActive, setWindowIsActive] = useState(true);

  function handleActivity(forcedFlag) {
    if (typeof forcedFlag === 'boolean') {
      return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
    }

    return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  }

  useEffect(() => {
    const handleActivityFalse = () => handleActivity(false);
    const handleActivityTrue = () => handleActivity(true);

    document.addEventListener('visibilitychange', handleActivity);
    document.addEventListener('blur', handleActivityFalse);
    window.addEventListener('blur', handleActivityFalse);
    window.addEventListener('focus', handleActivityTrue);
    document.addEventListener('focus', handleActivityTrue);

    return () => {
      window.removeEventListener('blur', handleActivity);
      document.removeEventListener('blur', handleActivityFalse);
      window.removeEventListener('focus', handleActivityFalse);
      document.removeEventListener('focus', handleActivityTrue);
      document.removeEventListener('visibilitychange', handleActivityTrue);
    };
  }, []);

  return <FocusContext.Provider value={{ isFocused: windowIsActive }}>{children}</FocusContext.Provider>;
};

const useFocus = () => useContext(FocusContext);

export default FocusContext;

export { FocusContextProvider, useFocus };
