import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';

const PrivateRoute = ({ path, allowedGroups = [], children }) => {
  const { user, userGroups } = useUser();

  if (userGroups.some(group => allowedGroups.includes(group))) {
    return <Route path={path}>{children}</Route>;
  }

  if (!userGroups.length) {
    if (user && user.userStatus === 'PENDING') {
      return <Redirect to="/thank-you" />;
    }

    return <Redirect to="/required-training" />;
  }

  return <div>Unauthorized</div>;
};

export default PrivateRoute;
