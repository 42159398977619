import React from 'react';

const PrivacyPolicy = () => (
  <>
    <p>
      <strong>Privacy Policy</strong>
    </p>
    <br />
    <p>
      Guardian Group (&ldquo;Company,&rdquo; &ldquo; we,&rdquo; or &ldquo;us &rdquo;) respects your privacy and is
      committed to protecting it through this Privacy Policy.
    </p>
    <br />
    <p>
      This Privacy Policy explains how we collect, use, and disclose information when you access and use project1591.us
      and any other website on which this Privacy Policy is posted (the &ldquo;Website&rdquo;), whether as a guest or a
      registered user.
    </p>
    <br />
    <p>
      When accessing the Website, the Company will learn certain information about you, both automatically and through
      information you provide to us during your visit. This Privacy Policy applies to information we collect on the
      Website and in email, text, or other electronic messages between you and the Website.
    </p>
    <br />
    <p>
      Please read the Privacy Policy carefully before you start to use the Website. By using the Website or by clicking
      to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound
      and abide by the Privacy Policy. If you do not agree to the Privacy Policy, you must not access or use the
      Website.
    </p>
    <br />
    <p>
      <strong>Information We Collect About You</strong>
    </p>
    <br />
    <p>
      We collect the following categories and types of <strong>&ldquo;Personal Information&rdquo;</strong>:
    </p>
    <br />
    <p>
      • <strong>Contact Information: </strong>your first name and last name, mailing address, email address, and phone
      number;
    </p>
    <p>
      • <strong>Other Identifying Information: </strong>IP address, social media user names, passwords and other
      security information for authentication and access;
    </p>
    <p>
      • <strong>Financial Information: </strong>credit card and debit card information;
    </p>
    <p>
      • <strong>Demographic Information: </strong>military history, race, ethnicity, gender and age;{' '}
    </p>
    <p>
      • <strong>Commercial Information: </strong>products purchased or viewed on our Website;
    </p>
    <p>
      • <strong>Geolocation Information: </strong>imprecise location data based upon your IP address;
    </p>
    <p>
      • <strong>Internet or Other Electronic Activity: </strong>your operating system, browser type, and browsing and
      click history, including information about how you navigate within our website and which elements of our Website
      you use most;
    </p>
    <p>
      • <strong>Inferences: </strong>inferences we draw from the other categories of information that we collect which
      reflect your preferences, characteristics, behavior and attitude.{' '}
    </p>
    <br />
    <p>
      <strong>How We Use Personal Information</strong>
    </p>
    <br />
    <p>We use your personal information for the following categories of use:</p>
    <br />
    <p>
      • <strong>Transactional Purposes.</strong> We use your contact information, other identifying information,
      financial information, and internet or other electronic activity to: <br />
      - Communicate with you about our Website, services and products.
      <br />
      - Carry out any contract between you and the Company, collect donations, and administer our business activities.
      <br />- Provide customer service and making available other items and services to our customers and prospective
      customers.
    </p>
    <p>
      • <strong>Analytical Purposes.</strong> We use your other identifying information, internet activity or other
      electronic activity, financial information, demographic information, commercial information, geolocation data, and
      inferences to analyze preferences, trends and statistics.
    </p>
    <p>
      • <strong>Marketing and Promotional Purposes.</strong> We use your contact information, other identifying
      information, demographic information, commercial information, geolocation information, internet or other
      electronic activity, and inferences to:
      <br />
      - Provide you with offers, promotions, and information for and about our products and services.
      <br />
      - Keep you informed about the products and services you have selected to receive and any related products and/or
      services.
      <br />
      - Provide you offers to purchase products and services provided by third parties in exchange for a commission to
      be paid to us by such third parties.
      <br />- Display advertisements to you that are tailored to your personal characteristics, interests, and
      activities.
    </p>
    <p>
      •<strong>Maintenance and Improvement of the Website.</strong> We use your contact information, other identifying
      information, commercial information, and internet or other electronic activity to:
      <br />
      - Help diagnose technical and service problems and administer our Website, including determining technical design
      specifications, improve our overall performance, and identifying system performance or problem areas.
      <br />
      - Handle your customer service requests.
      <br />- Present our Website and its contents to you. The Company may use software programs to create summary
      statistics, which are used for such purposes as assessing the number of visitors to the different sections of our
      site, what information is of most and least interest.
    </p>
    <p>
      • <strong>Security and Fraud Prevention: </strong>We use contact information, other identifying information,
      commercial information, financial information, demographic information, geolocation information, internet activity
      and other electronic activity, audio and video information, and inferences to protect the Website, our Company,
      victims of sex trafficking, and others, and to prevent fraud, theft, and misconduct.
    </p>
    <br />
    <br />
    <p>
      <strong>Sources of Personal Information</strong>
    </p>
    <br />
    <p>We collect Personal Information from the following sources:</p>
    <br />
    <p>
      • <strong>We collect information directly from you.</strong> We collect contact, other identifying information,
      financial information, demographic information directly from you. We collect information that users provide by
      filling out forms on the Website, registering for a class, communicating with us via contact forms, responding to
      surveys, search queries on our search feature, providing comments or other feedback, and providing information
      when ordering a product or service via the Website.{' '}
    </p>
    <p>
      • <strong>We collect information about you from third parties.</strong> We collect your Personal Information from
      third parties, including our volunteers. Volunteers gather information about you from publicly available sources
      on the internet, such as social media and government websites.{' '}
    </p>
    <p>
      • <strong>We collect information from you passively.</strong> We collect other identifying information and
      internet or other electronic activity passively using tools like browser cookies and pixels. This activity is
      further described in the Cookies, Advertising and Online Tracking section below.
    </p>
    <br />
    <p>
      <strong>Categories of Third Parties We Share Personal Information With</strong>
    </p>
    <br />
    <p>We share Personal Information with the following categories of third parties:</p>
    <br />
    <p>
      <i>Service Providers</i>
    </p>
    <br />
    <p>
      From time to time, we may establish a business relationship with other businesses whom we believe trustworthy and
      who have confirmed that their privacy practices are consistent with ours (
      <strong>&ldquo;Service Providers&rdquo;</strong>). For example, we may contract with Service Providers to provide
      certain services, such as hosting and maintenance, data storage and management, and marketing and promotions. We
      only provide our Service Providers with the information necessary for them to perform these services on our
      behalf. Each Service Provider must agree to use reasonable security procedures and practices, appropriate to the
      nature of the information involved, in order to protect your Personal Information from unauthorized access, use,
      or disclosure. Service Providers are prohibited from using Personal Information other than as specified by us.
    </p>
    <br />
    <p>
      <i>Compliance with Laws and Law Enforcement</i>
    </p>
    <br />
    <p>
      We cooperate with government and law enforcement officials and private parties to enforce and comply with the law.
      We may disclose Personal Information and any other information about you to government or law enforcement
      officials or private parties if, in our discretion, we believe it is necessary or appropriate in order to respond
      to legal requests (including court orders and subpoenas), to protect the safety, property, or rights of our
      company or of any third party, to prevent or stop any illegal, unethical, or legally actionable activity, or to
      comply with the law. This includes disclosure of Personal Information to report human trafficking to law
      enforcement.{' '}
    </p>
    <br />
    <p>
      <i>Our Affiliates</i>
    </p>
    <br />
    <p>
      We may share Personal Information with businesses controlling, controlled by, or under common control with our
      Company.{' '}
    </p>
    <br />
    <p>
      <i>Corporate Transactions</i>
    </p>
    <br />
    <p>
      If our Company is merged, acquired, or sold, or in the event of a transfer of some or all of our assets, we may
      disclose or transfer Personal Information in connection with such transaction. You will have the opportunity to
      opt out of any such transfer if, in our discretion, it will result in the handling of your Personal Information in
      a way that differs materially from this Privacy Policy.
    </p>
    <br />
    <p>
      <strong>Use of Cookies</strong>
    </p>
    <br />
    <p>
      Our website utilizes &ldquo;cookies&rdquo; and similar technologies to collect information about how our Website
      is used. A cookie is a very small text document, which often includes an anonymous unique identifier, that is
      stored on your computer to identify your computer and web browser. Information gathered through cookies may
      include the date and time of visits, the pages viewed, time spent at our site, and the websites visited just
      before and just after our own, as well as your IP address.
    </p>
    <br />
    <p>
      Most web browsers are initially setup to accept cookies. You can reset you web browser to refuse all cookies or to
      indicate when a cookie is being sent, however, certain features of the Website may not work if you delete or
      disable cookies. Some of our Service Providers may use their own cookies, anonymous identifiers, or other tracking
      technology in connection with the services they perform on our behalf.
    </p>
    <br />
    <p>
      We use Google Analytics on the Website to analyze how users use the Website, and to provide advertisements to you
      on other websites. For more information about how to opt out of having your information used by Google Analytics,
      visit{' '}
      <a
        href="https://tools.google.com/dlpage/gaoptout/"
        className="underline text-guardian-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://tools.google.com/dlpage/gaoptout/
      </a>
      .
    </p>

    <br />
    <p>
      <strong>Advertising and Online Tracking</strong>
    </p>
    <br />
    <p>
      Some content or applications, including advertisements, on the Website are served by third-parties, including
      advertisers, ad networks and servers, content providers, and application providers. These third parties may use
      cookies alone or in conjunction with web beacons or other tracking technologies to collect personal information
      about you when you use our website. The information they collect may be associated with your personal information
      or they may collect information, including personal information, about your online activities over time and across
      different websites and other online services. They may use this information to provide you with interest-based
      (behavioral) advertising or other targeted content.
    </p>
    <br />
    <p>
      Our systems do not recognize browser &ldquo;Do Not Track&rdquo; signals, but several Service Providers who utilize
      cookies on our Website enable you to opt out of targeted advertising practices. To learn more about these
      advertising practices or to opt out of this type of advertising, you can visit{' '}
      <a
        href="https://www.networkadvertising.org"
        className="underline text-guardian-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.networkadvertising.org
      </a>{' '}
      or{' '}
      <a
        href="https://www.aboutads.info/choices/"
        className="underline text-guardian-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.aboutads.info/choices/
      </a>
      . We also provide you with additional tools to opt out of marketing from us. You can learn about this in the
      “Managing Your Information Preferences” section of this Privacy Policy.
    </p>
    <br />
    <p>
      <strong>Managing Your Information Preferences</strong>
    </p>
    <br />
    <p>
      You can review, correct, update, or change your Personal Information by changing the relevant settings in your
      account or by emailing us at{' '}
      <a
        href="mailto:project1591@guardiangroup.org?subject=Project 1591 App Feedback"
        className="underline text-guardian-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        project1591@guardiangroup.org
      </a>
      . We do not share your Personal Information with third parties for their direct marketing purposes. Our emails
      provide users the opportunity to opt-out of receiving communications from us, including our newsletter or
      promotional materials, through the “unsubscribe” link at the bottom of our emails. You are able to opt out of
      receiving marketing emails from us, however, you cannot opt out of receiving all emails from us, such as emails
      about the status of your account. If you ever have trouble unsubscribing or other questions about this Privacy
      Policy, you can send an email to{' '}
      <a
        href="mailto:project1591@guardiangroup.org?subject=Project 1591 App Feedback"
        className="underline text-guardian-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        project1591@guardiangroup.org
      </a>
      .
    </p>
    <br />
    <p>
      <strong>How Do We Protect Your Information and Secure Information Transmissions?</strong>
    </p>
    <br />
    <p>
      We employ physical, electronic, and procedural safeguards to ensure the security of the information we collect.
      This includes using standard security protocols and working only with reputable third-party vendors. However, no
      data transmission over the Internet or other network can be guaranteed to be 100% secure. As a result, while we
      strive to protect information transmitted on or through the Website, we cannot and do not guarantee the security
      of any information you transmit on or through the Website, and you do so at your own risk.
    </p>
    <br />
    <p>
      <strong>Links to Other Websites</strong>
    </p>
    <br />
    <p>
      Our Website may contain links to other websites or allow others to send you such links. A link to a third party's
      website does not mean that we endorse it or that we are affiliated with it. We do not exercise control over third
      party websites. You access such third party websites or content at your own risk. You should always read the
      Privacy Policy of a third party website before providing any information to that website.
    </p>
    <br />
    <p>
      <strong>Processing in the United States</strong>
    </p>
    <br />
    <p>
      Please be aware that your Personal Information and communications may be transferred to and maintained on servers
      or databases located outside your state, province, or country. If you are located outside of the United States,
      please be advised that we process and store all information in the United States. The laws in the United States
      may not be as protective of your privacy as those in your location. By using the Website, you are agreeing to the
      collection, use, transfer, and disclosure of your Personal Information and communications will be governed by the
      applicable laws in the United States.
    </p>
    <br />
    <p>
      <strong>Policy Changes</strong>
    </p>
    <br />
    <p>
      It is our policy to post any changes we make to our Privacy Policy on this page. If we make changes to how we
      treat our users' personal information, we will notify you by posting the revised Privacy Policy on the Website.
      The date the Privacy Policy was last revised is identified at the bottom of the page. By continuing to use the
      Website, you are consenting to the revised Privacy Policy.
    </p>
    <br />
    <p>
      <strong>Contact Us</strong>
    </p>
    <br />
    <p>Guardian Group welcomes your questions or comments regarding the Privacy Policy:</p>
    <br />
    <p>Guardian Group</p>
    <p>1900 NE 3rd St.</p>
    <p>Suite 106 #40</p>
    <p>Bend, OR 97701</p>
    <br />
    <p>
      Email Address:{' '}
      <a
        href="mailto:project1591@guardiangroup.org?subject=Project 1591 App Feedback"
        className="underline text-guardian-blue"
        target="_blank"
        rel="noopener noreferrer"
      >
        project1591@guardiangroup.org
      </a>
    </p>
    <p>Effective as of July 9, 2019; Last updated May 19, 2022</p>
    <p>&nbsp;</p>
  </>
);

export default PrivacyPolicy;
