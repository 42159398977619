import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Container, TextField, Button, HeaderText } from '../../../components';
import { passwordValidator, confirmPasswordValidator } from '../../../helpers/createAccount/fieldValidation.js';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import { useUser } from '../../../contexts/userContext';

const RequireNewPassword = ({ authUser }) => {
  const history = useHistory();
  const { loadUser } = useUser();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [, setAlert] = useContext(NotificationContext);

  const resetPassword = async () => {
    if (passwordValidator(setAlert, newPassword) && confirmPasswordValidator(setAlert, confirmPassword, newPassword)) {
      try {
        await Auth.completeNewPassword(authUser, newPassword);

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'Your password was successfully changed!',
            textColor: 'greenText',
            borderColor: 'greenBorder',
          },
        });

        await loadUser();

        history.push('/');
      } catch (error) {
        console.error('Error updating password: ', error);

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: error.message,
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    }
  };

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 mt-3 md:mx-12 lg:mx-auto"
    >
      <center>
        <HeaderText noBold className="text-3xl lg:text-4xl">
          Reset Your Password
        </HeaderText>
      </center>

      <TextField
        type="password"
        onChange={e => setNewPassword(e.target.value)}
        placeHolder="New Password"
        label="New Password"
        className="my-4"
        info="Your password must be 8 characters long, have at least one number, one upper case letter, one lower case letter, and one special character"
        infoTitle="Password Requirements"
      />

      <TextField
        type="password"
        onChange={e => setConfirmPassword(e.target.value)}
        placeHolder="Confirm New Password"
        label="Confirm New Password"
        className="mb-4"
      />

      <Button solidBlue className="mt-4" onClick={resetPassword}>
        SUBMIT
      </Button>
    </Container>
  );
};

export default RequireNewPassword;
