import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, HeaderText, Button } from '../../../components';

const Success = () => {
  const history = useHistory();

  return (
    <Container width="lg:w-2/3" margin="m-3 lg:m-auto" className="relative px-5 py-6 lg:px-8 lg:py-10">
      <HeaderText noBold fontSize="text-4xl">
        Success
      </HeaderText>

      <h2 className="text-pursuit-red text-sm font-bold lg:text-xl">Submitted</h2>

      <div className="bg-gray-100 px-8 py-4 mt-8">
        <p className="text-pursuit-gray font-bold text-lg mb-4">Thanks for the submission.</p>

        <p className="text-pursuit-gray">
          Next, a Guardian Group administrator will validate, corroborate, and determine the next steps for this lead.
          It may be approved and passed along to law enforcement and you’ll be awarded your points accordingly. However,
          it may need additional attention, so keep an eye out for it in your “Returned Cases” folder for review.
        </p>

        <p className="text-pursuit-gray mt-4">
          In the meantime, continue submitting new cases to help counter this crime.
        </p>

        <center>
          <Button solidBlue noPadding className="w-full lg:w-64 mx-auto mt-16 mb-12" onClick={() => history.push('/')}>
            BACK TO DASHBOARD
          </Button>
        </center>
      </div>
    </Container>
  );
};

export default Success;
