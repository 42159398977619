import React, { useEffect, useState, useContext, useRef } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { snakeCase } from 'change-case';
import ReactPlayer from 'react-player';
import * as Icons from 'react-icons/fa';
import {
  Container,
  HeaderText,
  DataGrid,
  Button,
  DropDown,
  PopUpConfirmation,
  TextField,
  FileUploader,
} from '../../../components';
import Thumbnail from '../../../assets/Images/Thumbnail.png';
import { createTutorial, updateTutorial, deleteTutorial } from '../../../generated/graphql/mutations';
import { listTutorials } from '../../../generated/graphql/queries';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';
import { TutorialCategory } from '../../../constants';
import { useUser } from '../../../contexts/userContext';
import { validateTutorial } from '../../../helpers/knowledgeBase/knowledgeBaseValidation';

/**
 * This has a limit set of 1000 since backend is not complete to handle pagination
 */

const TutorialCategoryOptions = [
  { value: TutorialCategory.Proj1591HowTo, label: 'Project 1591® - How To' },
  { value: TutorialCategory.OSINTTipsTricks, label: 'OSINT Tips/Tricks' },
  { value: TutorialCategory.Miscellaneous, label: 'Miscellaneous' },
];

const KnowledgeBase = () => {
  const { isAdmin, isEmployee } = useUser();

  const [, setAlert] = useContext(NotificationContext);

  const [nextTutorialToken, setNextTutorialToken] = useState();

  const [tutorials, setTutorials] = useState([]);
  const [tutorialsLoading, setTutorialsLoading] = useState(true);
  const [selectedTutorial, setSelectedTutorial] = useState({
    title: '',
    description: '',
    s3Key: '',
    thumbnailS3Key: '',
    category: '',
  });
  const [referenceTutorial, setReferenceTutorial] = useState({
    title: '',
    description: '',
    s3Key: '',
    category: '',
    thumbnailS3Key: '',
  });

  const [category, setCategory] = useState();

  const [showDelete, setShowDelete] = useState(false);
  const [displayVideo, setDisplayVideo] = useState(false);
  const [addUpdateVideo, setAddUpdateVideo] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [thumbnailsFetched, setThumbnailsFetched] = useState(false);

  const [filters, setFilters] = useState({
    category: '',
    searchTerm: '',
  });

  const [sort, setSort] = useState({
    fieldName: 'DATE_UPLOADED',
    direction: 'DESC',
  });

  const [filtersOnLastSearch, setFiltersOnLastSearch] = useState(null);
  const [sortOnLastSearch, setSortOnLastSearch] = useState({
    fieldName: '',
    direction: 'DESC',
  });

  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!thumbnailsFetched && tutorials.length > 0) {
      fetchThumbnails();
      setThumbnailsFetched(true);
    }
  }, [tutorials]);

  useEffect(() => {
    fetchTutorials();
  }, [sort]);

  useEffect(() => {
    fetchTutorials();
  }, [filters]);

  const fetchTutorials = async () => {
    setTutorialsLoading(true);

    try {
      let nextTokenToUse = nextTutorialToken;

      let oldTutorials = nextTokenToUse ? [...tutorials] : [];

      if (
        !filtersOnLastSearch ||
        filtersOnLastSearch.category !== filters.category ||
        filtersOnLastSearch.searchTerm !== filters.searchTerm ||
        sortOnLastSearch.fieldName !== sort.fieldName ||
        sortOnLastSearch.direction !== sort.direction
      ) {
        setNextTutorialToken(null);

        nextTokenToUse = null;
        oldTutorials = [];
      }

      setSortOnLastSearch(sort);
      setFiltersOnLastSearch(filters);

      const params = {
        category: filters.category,
        searchTerm: filters.searchTerm,
        nextToken: nextTokenToUse,
        sortField: sort.fieldName ? snakeCase(sort.fieldName).toUpperCase() : '',
        sortOrder: sort.direction,
        limit: 1000,
        // limit: 5,
      };

      let query = listTutorials;

      const regex = /authorName/i;

      if (!isAdmin && !isEmployee) {
        query = query.replace(regex, '');
      }

      let result = await API.graphql(graphqlOperation(query, params));

      // convert category, time, and date
      result = result.data.listTutorials.items.map(tutorial => {
        const categoryName = TutorialCategoryOptions.find(x => x.value === tutorial.category) || '';
        const convertedTime = convertHMS(parseInt(tutorial.time));
        let date = new Date(tutorial.dateUploaded);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
          dt = `0${dt}`;
        }
        if (month < 10) {
          month = `0${month}`;
        }
        date = `${month}-${dt}-${year}`;

        return {
          ...tutorial,
          time: convertedTime,
          dateUploaded: date,
          category: categoryName?.label,
        };
      });
      setThumbnailsFetched(false);
      setNextTutorialToken(result.nextToken);
      setTutorials([...oldTutorials, ...result]);
    } catch (error) {
      console.error('Error Loading tutorials: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading active tutorials',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setTutorialsLoading(false);
  };

  const fetchThumbnails = async () => {
    // get thumbnails after rest of state is updated
    const result = await Promise.all(
      tutorials.map(async tutorial => {
        let thumbnailS3URL = '';

        // If custom thumbnail, get presigned s3URL
        if (tutorial.thumbnailS3Key) {
          thumbnailS3URL = await getThumbnail(tutorial.thumbnailS3Key);
        }

        return {
          ...tutorial,
          thumbnailS3URL,
        };
      })
    );

    setTutorials([...result]);
  };

  function convertHMS(value) {
    const sec = parseInt(value, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  }

  const renderUpdate = item => (
    // put modal here
    <div className="flex justify-around w-1/2">
      <button onClick={() => toggleAddUpdateTutorial(item, true)}>
        <Icons.FaPencilAlt size={18} />
      </button>
      <button onClick={() => toggleDeleteTutorial(item)}>
        <Icons.FaTrash size={18} />
      </button>
    </div>
  );

  const toggleDeleteTutorial = tutorial => {
    if (tutorial) {
      setSelectedTutorial(tutorial);
      setShowDelete(true);
    }
  };

  const softDeleteTutorial = async () => {
    try {
      const params = {
        s3Key: selectedTutorial.s3Key,
      };

      await API.graphql(graphqlOperation(deleteTutorial, params));

      setTutorials(tutorials.filter(tut => tut.s3Key !== selectedTutorial.s3Key));
    } catch (error) {
      console.error('Error deleting tutorial: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An unexpected error occurred deleting the tutorial',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setShowDelete(false);
  };

  // Can only get called when adding a video. (not updating)
  const cancelVideoUpload = async () => {
    if (selectedTutorial?.s3Key) {
      try {
        await Storage.remove(selectedTutorial.s3Key, {
          level: 'public',
          customPrefix: { public: '' },
        });

        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const cancelThumbnailUpload = async () => {
    if (selectedTutorial?.thumbnailS3Key) {
      try {
        await Storage.remove(selectedTutorial.thumbnailS3Key, {
          level: 'public',
          customPrefix: { public: '' },
        });

        // if video already exists update dynamo
        if (selectedTutorial?.dateUploaded) {
          const params = {
            input: {
              s3Key: selectedTutorial.s3Key,
              thumbnailS3Key: '',
            },
          };

          await API.graphql(graphqlOperation(updateTutorial, params));
        }

        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const toggleAddUpdateTutorial = (tutorial = null, isUpdate) => {
    if (isUpdate) {
      setReferenceTutorial(tutorial);
      setSelectedTutorial(tutorial);
    } else setSelectedTutorial({ title: '', description: '', s3Key: '', thumbnailS3Key: '' });

    setIsUpdate(isUpdate);

    setAddUpdateVideo(true);
  };

  const toggleDisplayTutorial = async tutorial => {
    if (tutorial) {
      const vidURL = await Storage.get(tutorial.s3Key, {
        level: 'public',
        download: false,
        expires: 1800,
        customPrefix: { public: '' },
      });

      setSelectedTutorial({ ...tutorial, videoURL: vidURL });
      setDisplayVideo(true);
    }
  };

  const successfullUpload = async (s3Key, fileURL) => {
    const video = document.createElement('video');

    video.setAttribute('src', fileURL);

    video.onloadeddata = function(event) {
      const { duration } = event.srcElement;

      setSelectedTutorial({ ...selectedTutorial, s3Key, time: duration });
    };
  };

  const successfullThumbnailUpload = async s3Key => {
    setSelectedTutorial({ ...selectedTutorial, thumbnailS3Key: s3Key });
  };

  const submitTutorial = async () => {
    try {
      if (!selectedTutorial.category) selectedTutorial.category = '';

      const isValid = validateTutorial(
        setAlert,
        selectedTutorial.title,
        selectedTutorial.description,
        selectedTutorial.category,
        selectedTutorial.s3Key
      );

      if (!isValid) {
        return;
      }

      let params = {};

      if (isUpdate) {
        const updatedAttributes = {
          s3Key: selectedTutorial.s3Key,
        };

        for (const [key, value] of Object.entries(selectedTutorial)) {
          if (key !== 's3Key' && key !== 'updatedAt' && key !== 'dateUploaded') {
            if (value !== referenceTutorial[key]) {
              updatedAttributes[`${key}`] = value;
            }
          }
        }

        params = {
          input: {
            ...updatedAttributes,
          },
        };

        if (Object.entries(updatedAttributes).length > 1) {
          await API.graphql(graphqlOperation(updateTutorial, params));
        }
      } else {
        params = {
          input: {
            ...selectedTutorial,
          },
        };

        await API.graphql(graphqlOperation(createTutorial, params));
      }

      setSelectedTutorial({ title: '', description: '', s3Key: '', thumbnailS3Key: '' });
      fetchTutorials();
    } catch (error) {
      console.error('Error saving user on upload: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An unexpected error occurred',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setAddUpdateVideo(false);
  };

  const player = useRef(null);

  const getThumbnail = async thumbnailS3Key => {
    const thumbURL = await Storage.get(thumbnailS3Key, {
      level: 'public',
      download: false,
      expires: 1800,
      customPrefix: { public: '' },
    });

    return thumbURL;
  };

  const renderThumbnail = row => {
    let thumbURL = Thumbnail;

    if (row?.thumbnailS3URL) {
      thumbURL = row.thumbnailS3URL;
    }

    return <img src={thumbURL} alt="Thumbnail" className="w-11/12" onClick={() => toggleDisplayTutorial(row)} />;
  };

  const columns = [
    { title: 'Thumbnail', fieldName: 'thumbnail', value: renderThumbnail },
    { title: 'Title', fieldName: 'title', sortable: true },
    { title: 'Description', fieldName: 'description', sortable: false },
    { title: 'Time', fieldName: 'time', sortable: true },
    { title: 'Date Uploaded', fieldName: 'dateUploaded', sortable: true },

    { title: 'Category', fieldName: 'category', sortable: true },
  ];

  if (isAdmin) {
    columns.push({ title: 'Author', fieldName: 'authorName', sortable: true });
    columns.push({ title: 'Update', value: renderUpdate });
  } else if (isEmployee) {
    columns.push({ title: 'Author', fieldName: 'authorName', sortable: true });
  }

  return (
    <>
      <Container
        height="h-11/12 lg:h-152"
        width="lg:w-11/12"
        padding="p-4"
        margin="m-3 lg:m-auto"
        className="relative justify-c"
      >
        <div className="flex flex-col pt-4 lg:px-4">
          <HeaderText className="mb-4 text-2xl text-left lg:text-4xl">Knowledge Base</HeaderText>
          <div className="items-end justify-end mb-10 lg:flex">
            <div className="lg:mr-4">
              <DropDown
                value={TutorialCategoryOptions.find(x => x.value === category)}
                onChange={option => {
                  setCategory(option ? option.value : '');
                }}
                containerClassName="mb-4 lg:mb-0"
                width="w-full lg:w-48 xl:w-64"
                label="Category Options"
                options={TutorialCategoryOptions}
                placeholder="Categories"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <input
                type="text"
                value={search}
                onChange={event => setSearch(event.target.value)}
                placeholder="Search Term"
                className="w-full h-10 px-4 mt-2 mb-4 bg-gray-100 lg:mb-0 lg:mt-0 lg:mr-4 lg:w-48 xl:w-64"
                onKeyDown={event => {
                  const pressedKey = event.key;

                  if (pressedKey === 'Enter') {
                    setFilters({
                      category,
                      searchTerm: search,
                    });
                  }
                }}
              />
            </div>
            <Button
              solidBlue
              className="w-full px-4 mt-2 mb-4 mr-0 lg:w-24 lg:mr-48 lg:mb-0 lg:mt-0 xl:w-48"
              onClick={() => {
                setFilters({
                  category,
                  searchTerm: search,
                });
              }}
            >
              FILTER
            </Button>
            {isAdmin && (
              <Button
                solidRed
                className="w-full px-4 lg:w-48"
                onClick={() => {
                  toggleAddUpdateTutorial(false);
                }}
              >
                ADD VIDEO TUTORIAL
              </Button>
            )}
          </div>

          <DataGrid
            columns={columns}
            sort={sort}
            setSort={setSort}
            loading={tutorialsLoading}
            data={tutorials}
            noRecordsText="No tutorials found"
            loadNextPage={() => fetchTutorials(filters)}
            hasMore={!!nextTutorialToken}
            containerHeight="h-184 lg:h-88"
            gridKey="employee-tutorials"
          />
        </div>
      </Container>
      {showDelete && (
        <PopUpConfirmation
          title="Delete Tutorial"
          content={`Are you sure you want to delete ${selectedTutorial?.title || 'this tutorial'}?`}
          onConfirm={() => {
            softDeleteTutorial();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
          confirmText="DELETE"
          destructive
          className="w-11/12 lg:w-auto"
        />
      )}
      {displayVideo ? (
        <PopUpConfirmation
          title={selectedTutorial.title}
          content={
            <div
              padding="p-4 md:px-10 md:py-8 lg:px-20 bg-gray-300"
              className="flex flex-col w-full"
              margin="mx-3 mt-3 md:mx-12 lg:mx-auto"
            >
              <ReactPlayer
                ref={player}
                url={selectedTutorial.videoURL}
                width="100%"
                className="w-11/12 mt-4 bg-black"
                playing
                controls
                light
              />
            </div>
          }
          onConfirm={() => {
            setDisplayVideo(false);
          }}
          noCancel
          confirmText="CLOSE"
          className="w-11/12 lg:w-1/2 h-10/12 lg:h-auto"
        />
      ) : null}
      {addUpdateVideo ? (
        <PopUpConfirmation
          title={isUpdate ? 'Update Video Tutorial' : 'Add Video Tutorial'}
          content={
            <div
              className="flex flex-col w-full p-4 bg-gray-100 md:px-10 md:py-8 lg:px-20"
              margin="mx-3 mt-3 md:mx-12 lg:mx-auto"
            >
              <TextField
                value={selectedTutorial?.title || ''}
                onChange={e => setSelectedTutorial({ ...selectedTutorial, title: e.target.value })}
                placeholder="Title"
                label="Title"
                className="mb-4"
                fieldClassName="bg-white p-2"
              />

              <label className="font-light light-primary-blue-text">Description</label>
              <textarea
                value={selectedTutorial?.description || ''}
                onChange={e => setSelectedTutorial({ ...selectedTutorial, description: e.target.value })}
                placeholder="Description"
                label="Description"
                className="h-24 p-2 mb-4"
              />

              <DropDown
                value={TutorialCategoryOptions.find(x => x.label === selectedTutorial?.category)}
                onChange={option => setSelectedTutorial({ ...selectedTutorial, category: option ? option.value : '' })}
                label="Category"
                containerClassName="mb-12 lg:mr-2"
                width="w-full lg:w-48 xl:w-64"
                options={TutorialCategoryOptions}
                isClearable
              />
              <div className="lg:justify-around lg:flex">
                {!isUpdate ? (
                  <div className="mb-12 lg:w-1/3">
                    <FileUploader
                      onSuccess={successfullUpload}
                      saveText="UPLOAD"
                      prefix="tutorials"
                      noPrefix
                      level="tutorial"
                      errorMessage="You have unsuccessfully uploaded the Tutorial. Please try again."
                      onCancel={cancelVideoUpload}
                      isUpdate={false}
                      acceptedFileTypes="video/*"
                      uploadObject="Video"
                    />
                  </div>
                ) : null}
                <div className="lg:w-1/3">
                  <FileUploader
                    onSuccess={successfullThumbnailUpload}
                    saveText="UPLOAD"
                    prefix="tutorials/thumbnails"
                    noPrefix
                    level="tutorial"
                    errorMessage="You have unsuccessfully uploaded the Thumbnail. Please try again."
                    onCancel={cancelThumbnailUpload}
                    isUpdate={selectedTutorial?.thumbnailS3Key}
                    acceptedFileTypes="image/*"
                    uploadObject="Thumbnail"
                  />
                </div>
              </div>
            </div>
          }
          onConfirm={() => {
            submitTutorial();
          }}
          onCancel={async () => {
            if (!isUpdate) {
              if (selectedTutorial.s3Key) {
                // deletevideo
                await Storage.remove(selectedTutorial.s3Key, {
                  level: 'public',
                  customPrefix: { public: '' },
                });
              }
              if (selectedTutorial?.thumbnailS3Key) {
                await Storage.remove(selectedTutorial.thumbnailS3Key, {
                  level: 'public',
                  customPrefix: { public: '' },
                });
              }
            }
            setAddUpdateVideo(false);
          }}
          confirmText="SAVE"
          className="w-11/12 overflow-y-auto h-88pr"
        />
      ) : null}
    </>
  );
};

export default KnowledgeBase;
