import React from 'react';
/**
 * Custom hook to set page title
 * @param {string} title Page title
 * @param {string[]} parts Page title parts
 * @param {string} separator Page title separator
 */
export const useTitle = (title, parts = ['Project 1591®', 'Guardian Group'], separator = '|') => {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = [title, ...parts].join(` ${separator} `);
    return () => {
      document.title = prevTitle;
    };
  }, []);
};
