import moment from 'moment';

export const ifEmptyObj = fieldValue => {
  for (const key of Object.keys(fieldValue)) {
    if (fieldValue[key]) {
      return false;
    }
  }

  return true;
};
export const ifContainNumbers = fieldValue => /\d/.test(fieldValue);
export const ifOnlyContainsNumbers = fieldValue => /^\d+$/.test(fieldValue);
export const ifEmail = fieldValue =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    fieldValue
  );
export const ifLengthIsLessThan = (fieldValue, length) => fieldValue < length;
export const ifLengthIs = (fieldValue, length) => fieldValue === length;
export const ifContainsUpperCase = fieldValue => /[A-Z]/g.test(fieldValue);
export const ifContainsLowerCase = fieldValue => /[a-z]/g.test(fieldValue);
export const ifContainsSpecialCharacters = fieldValue => /[~`@!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(fieldValue);
export const ifFieldValuesMatch = (fieldValue1, fieldValue2) => fieldValue1 === fieldValue2;
export const ifYearAgoIs = (year, yearsAgo) => parseInt(moment().format('YYYY')) - year >= yearsAgo;
