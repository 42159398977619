import React from 'react';

const MobileDropdown = ({ className, optionsArray, selected, selectionCallback }) => (
  <select
    className={`custom-select ${className}`}
    value={selected}
    onChange={event => {
      selectionCallback(event.target.value);
    }}
  >
    {optionsArray.map((option, i) => (
      <option key={i}>{option}</option>
    ))}
  </select>
);

export default MobileDropdown;
