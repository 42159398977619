export const titleValidator = (setAlert, title) => {
  const titleEmpty = !title;

  if (titleEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a title',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !titleEmpty;
};

export const descriptionValidator = (setAlert, description) => {
  const descriptionEmpty = !description;

  if (descriptionEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please enter a description',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !descriptionEmpty;
};

export const uploadedVideoValidator = (setAlert, s3Key) => {
  const s3KeyEmpty = !s3Key;

  if (s3KeyEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please upload a video',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !s3KeyEmpty;
};

export const categoryValidator = (setAlert, category) => {
  const categoryEmpty = !category;

  if (categoryEmpty) {
    setAlert({
      type: 'SET_NOTIFICATION',
      payload: {
        occurs: true,
        message: 'Please select a category',
        textColor: 'redText',
        borderColor: 'redBorder',
      },
    });
  }

  return !categoryEmpty;
};
