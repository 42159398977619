import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { API, graphqlOperation } from 'aws-amplify';
import { getUserLeaderboardSummary } from '../../generated/graphql/queries';
import HeaderText from '../HeaderText';
import Button from '../Button';
import Spinner from '../Spinner';
import 'react-circular-progressbar/dist/styles.css';

const LeaderboardCard = ({ userId }) => {
  const [rankText, setRankText] = useState('');
  const [pointsText, setPointsText] = useState('');
  const [pointsPercent, setPointsPercent] = useState(0);
  const [leadsApproved, setLeadsApproved] = useState(0);
  const [leadsSubmitted, setLeadsSubmitted] = useState(0);
  const [leadApproval, setLeadApproval] = useState(0);
  const [loading, setLoading] = useState(true);
  // if userId is provided, load for that user? otherwise, load for authenticated user

  async function fetchData(id) {
    const {
      data: { getUserLeaderboardSummary: summary },
    } = await API.graphql(graphqlOperation(getUserLeaderboardSummary, { id: id || null, board: 'allTime' }));

    function rankRender() {
      if (summary.rank === 0) return `N/A`;
      if (summary.rank === 11 || summary.rank === 12 || summary.rank === 13) return `${summary.rank}th`;
      if (summary.rank % 10 === 1) return `${summary.rank}st`;
      if (summary.rank % 10 === 2) return `${summary.rank}nd`;
      if (summary.rank % 10 === 3) return `${summary.rank}rd`;

      return `${summary.rank}th`;
    }

    const nanToZero = n => (Number.isFinite(n) ? n : 0);

    setRankText(rankRender());
    setPointsText(nanToZero(summary.points));
    setPointsPercent(nanToZero(summary.pointsPercent));
    setLeadsApproved(nanToZero(summary.leadsApproved));
    setLeadsSubmitted(nanToZero(summary.leadsSubmitted));
    setLeadApproval(nanToZero(Math.floor((summary.leadsApproved / summary.leadsSubmitted) * 100)));
    setLoading(false);
  }

  useEffect(() => {
    fetchData(userId);
  }, [userId]);

  const containerClassNames = 'lg:px-4 pt-4 xl:overflow-auto';
  const cellClassNames = 'h-60 mb-5 col-span-1';

  return (
    <>
      <HeaderText fontSize="text-2xl" className="text-left lg:text-4xl lg:text-center">
        Leaderboard
      </HeaderText>

      <div className={containerClassNames}>
        {loading ? (
          <>
            <div className="flex justify-center w-full py-10">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1 col-span-1 row-span-2 md:grid-cols-2 md:col-span-2">
              <div className="mb-5 h-60">
                <p className="mb-4 font-bold text-center text-md">Community Ranking</p>
                <HeaderText fontSize="text-4xl" className="mb-6 text-4xl text-center text-pursuit-red">
                  {rankText}
                </HeaderText>
              </div>
              <div className={cellClassNames}>
                <p className="mb-4 font-bold text-center text-md">Points</p>
                <div className="p-2">
                  <CircularProgressbarWithChildren
                    value={pointsPercent}
                    strokeWidth={22}
                    styles={buildStyles({ pathColor: '#770303', rotation: 0.25, strokeLinecap: 'butt' })}
                  >
                    <HeaderText
                      fontSize="text-md"
                      className="text-md text-center align-middle text-pursuit-red"
                      mb="mb-1"
                    >
                      {pointsText}
                    </HeaderText>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
              <div className="mb-5 ">
                <p className="mb-4 font-bold text-center text-md">Leads Approved/ Leads Submitted</p>
                <HeaderText fontSize="text-4xl" className="mb-6 text-4xl text-center text-pursuit-red">
                  {leadsApproved}/{leadsSubmitted}
                </HeaderText>
              </div>

              <div className={cellClassNames}>
                <p className="mb-4 font-bold text-center text-md">Lead Approval</p>
                <div className="p-2">
                  <CircularProgressbarWithChildren
                    value={leadApproval}
                    strokeWidth={22}
                    styles={buildStyles({ pathColor: '#770303', rotation: 0.25, strokeLinecap: 'butt' })}
                  >
                    <HeaderText
                      fontSize="text-md"
                      className="text-md text-center align-middle text-pursuit-red"
                      mb="mb-1"
                    >
                      {leadApproval}%
                    </HeaderText>
                  </CircularProgressbarWithChildren>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="flex flex-row items-end justify-end flex-1 mt-4">
        <Link to="/leaderboard">
          <Button className="font-semibold lg:text-md text-pursuit-gray focus:outline-none">VIEW LEADERBOARD</Button>
        </Link>
        <div />
      </div>
    </>
  );
};

export default LeaderboardCard;
