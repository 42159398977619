const BUTTONDEFAULTCLASS = 'btton';

export function generateButtonClass(props) {
  let buttonClass;
  let padding;

  const disabledClasses = 'bg-gray-200 cursor-not-allowed text-white';
  const disabledTabClasses =
    'bg-gray-200 cursor-not-allowed text-gray border-none text-white hover:no-underline rounded px-2';

  if (props.solidBlue) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-solid-blue'}`;
  } else if (props.solidGray) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-solid-gray'}`;
  } else if (props.solidLightGray) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-solid-lightgray'}`;
  } else if (props.linedBlue) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-lined-blue'}`;
  } else if (props.linedRed) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-lined-red'}`;
  } else if (props.noLineBlue) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-noline-blue'}`;
  } else if (props.solidRed) {
    buttonClass = `${BUTTONDEFAULTCLASS} ${props.disabled ? disabledClasses : 'primary-solid-red'}`;
  } else if (props.tab) {
    buttonClass = `${props.disabled ? disabledTabClasses : 'btton-tab'}`;
  } else if (props.activeTab) {
    buttonClass = 'btton-tab btton-tab-active';
  } else if (props.anchorTag) {
    buttonClass = 'underline primary-blue-text';
  } else {
    buttonClass = BUTTONDEFAULTCLASS;
  }

  if (props.noPadding) {
    padding = '';
  } else if (props.padding) {
    padding = ` ${props.padding}`;
  } else {
    padding = '';
  }

  if (props.height) {
    buttonClass += ` ${props.height}`;
  } else {
    buttonClass += ` h-10`;
  }

  if (props.className) {
    buttonClass = `${buttonClass} ${props.className}`;
  }

  return buttonClass + padding;
}
