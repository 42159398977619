import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  HeaderText,
  Button,
  DropDown,
  TextField,
  AliasGenerator,
  CountryStateZipPicker,
  ToolTip,
  StatusPill,
  Spinner,
} from '../../../components';
import { API, graphqlOperation } from 'aws-amplify';
import { searchQuery } from '../../../generated/graphql/queries';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as FaIcons from 'react-icons/fa';
import { adminDownloadCase } from '../../../generated/graphql/mutations';
import { generateButtonClass } from '../../../components/Button/generateButtonClass';
import { useQueryState } from '../../../helpers/useQueryState';
// Mock data for example search results
// const searchResults = [
//   {
//     id: 1,
//     caseNumber: 'ABC123',
//     status: 'Open',
//     assignedTo: 'John Doe',
//   },
//   {
//     id: 2,
//     caseNumber: 'DEF456',
//     status: 'Closed',
//     assignedTo: 'Jane Smith',
//   },
//   // Add more example results here...
// ];

// Page to display search results
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const currentUrl = new URLSearchParams(window.location.search);
  // Update the page number in the query string
  return (
    <div className="flex justify-center">
      <ul className="flex">
        {pageNumbers.map(number => {
          currentUrl.set('page', number);
          const newUrl = `${window.location.pathname}?${currentUrl.toString()}`;
          return (
            <li key={number} className="flex-initial">
              {/* <a href="#" onClick={() => onPageChange(number)} className={number === currentPage ? 'active' : ''}> */}
              <Link
                to={newUrl}
                className={(number == currentPage ? 'bg-blue-500 text-white' : 'text-blue-500') + ' p-2'}>
                {number}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const SearchResult = ({ item, index = null, downloadAction = caseID => {}, searchTerm = null }) => {
  const [casePDFUrl, setCasePDFUrl] = useState(null);
  // const { id, highlights: highlightsJson, source } = item;
  const { id, highlights, source } = item;
  const { caseNumber, status, reportKey } = source;
  // const highlights = JSON.parse(highlightsJson) || {};

  const getCasePDF = async caseId => {
    const {
      data: {
        adminDownloadCase: { preSignedUrl },
      },
    } = await API.graphql({
      query: adminDownloadCase,
      variables: { input: { caseId } },
      authMode: 'AWS_IAM',
    });

    setCasePDFUrl(preSignedUrl);
  };

  // Filter keys they end in .keyword
  const filteredHighlights = Object.keys(highlights).reduce((acc, key) => {
    // if (!key.endsWith('.keyword')) {
    // if (!key.endsWith('.id') && !key.includes('.stepId') && !key.endsWith('.id.keyword')) {
    acc[key] = highlights[key];
    // }
    return acc;
  }, {});
  const formattedHighlights = Object.keys(filteredHighlights).map(key => {
    return (
      <dl key={key}>
        <dt className="ml-1 font-semibold ">{key}</dt>
        {/* Set the html to the value of the highlight */}
        <dd className="ml-3" dangerouslySetInnerHTML={{ __html: highlights[key] }} />
        {/* <dd>{highlights[key]}</dd> */}
      </dl>
    );
  });
  const className = generateButtonClass({ disabled: !reportKey, solidBlue: true });
  const downloadButton = (
    <button
      // solidBlue

      className={'w-24 ' + className}
      disabled={!reportKey}
      onClick={e => {
        e.preventDefault();
        downloadAction(id);
      }}>
      Download
    </button>
  );

  const openPDFButton = !casePDFUrl ? (
    <button
      className={'w-24 ' + className}
      disabled={!reportKey}
      onClick={e => {
        e.preventDefault();
        getCasePDF(id);
      }}>
      View PDF
    </button>
  ) : (
    <button
      className={'w-24 ' + className}
      disabled={!reportKey}
      onClick={e => {
        e.preventDefault();
        setCasePDFUrl(null);
      }}>
      Close PDF
    </button>
  );

  return (
    <div className="mb-8 search-result">
      <div className="grid grid-flow-col auto-cols-max gap-2 primary-blue-text mb-2">
        {index && <div className="pt-1 text-xl">{index}</div>}
        <div className="py-1 text-xl">
          <FaIcons.FaBriefcase size={25} />
        </div>
        <div className="pt-1 text-xl">
          <Link to={`/case-review/${id}`}>
            <strong>Case Number: {caseNumber}</strong>
          </Link>
        </div>
        <div>
          <StatusPill status={status} />
        </div>
        {downloadButton}
        {openPDFButton}
      </div>
      {/* If case pdf url is set show a iframe and highlight the searched term */}
      {casePDFUrl && (
        <div
          className="fixed top-0 bottom-0 left-0 right-0 h-screen"
          style={{ backgroundColor: 'rgb(100, 100, 100, 0.75)', zIndex: 40 }}
          onClick={e => {
            e.preventDefault();
            setCasePDFUrl(null);
          }}>
          <div
            className={`absolute flex flex-col bg-white py-6 px-8 shadow-xl ${className}`}
            style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '95vw', height: '90vh' }}>
            <iframe
              title="case-pdf"
              // Does not work yet https://issues.chromium.org/issues/40553548?pli=1
              src={casePDFUrl + '#search="' + searchTerm + '"'}
              // src={'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'}
              width="100%"
              height="100%"
              className="mb-4"
              style={{ border: 'none' }}
            />
            <div className="flex flex-row-reverse">
              <Button
                solidBlue
                className="px-3 mr-6 min-w-20 lg:min-w-24"
                onClick={e => {
                  // e.preventDefault();
                  setCasePDFUrl(null);
                }}>
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
      <Link to={`/case-review/${id}`}>
        {formattedHighlights && formattedHighlights.length > 0 && (
          <div className="grid grid-cols-4  gap-2">{formattedHighlights}</div>
        )}
      </Link>
      {/* {JSON.stringify(item)} */}
    </div>
  );
};

SearchResult.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
  downloadAction: PropTypes.func,
};

const SearchPage = () => {
  const [searchTerm, setSearchTerm] = useQueryState('q');
  // const [title, setTitle] = useQueryState('title', state && state.title);
  const [searchedTerm, setSearchedTerm] = useState(searchTerm);
  const [results, setResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchRunning, setSearchRunning] = useState(false);
  const [downloading, setDownloading] = useState(false);
  // const resultsPerPage = 10;
  const [resultsPerPage] = useQueryState('limit', 10);
  // const [currentPage, setCurrentPage] = useState(0);
  const [currentPage, setCurrentPage] = useQueryState('page', 1);
  const [totalPages, setTotalPages] = useState(0);
  const [errorMessage, setErrorMessage] = useState();

  // If search is set from the query string, perform the search
  useEffect(() => {
    if (searchTerm) {
      performSearch();
    }
  }, []);

  // Rerun search when page changes
  useEffect(() => {
    if (searchTerm) {
      performSearch();
    }
  }, [currentPage]);

  const cleanSearch = () => {
    setCurrentPage(1);
    performSearch();
  };

  const performSearch = useCallback(async () => {
    setSearchRunning(true);
    setErrorMessage(null);
    // console.log('params', searchParams);
    try {
      const from = (currentPage - 1) * resultsPerPage || 0;
      const {
        data: {
          searchQuery: { items, totalHits },
        },
        errors,
      } = await API.graphql(
        graphqlOperation(searchQuery, {
          searchTerm,
          limit: resultsPerPage,
          from,
        })
      );
      const totalPages = Math.ceil(totalHits / resultsPerPage);
      console.log('Current Page', currentPage);
      console.log('totalHits', totalHits);
      console.log('resultsPerPage', resultsPerPage);
      console.log('totalPages', totalPages);
      console.log('From', from);
      if (errors) {
        console.error('Error searching: ', errors);
        setResults([]);
        setErrorMessage(errors[0].message);
        setTotalPages(0);
        return;
      } else {
        setErrorMessage(null);
      }
      setTotalPages(totalPages);
      // console.log('response', response);

      // Loop item highlights for each item and remove those that only have fields containing '.stepId'
      items.forEach(item => {
        const { highlights } = item;
        const parsedHighlights = JSON.parse(highlights);
        const filteredHighlights = Object.keys(parsedHighlights).reduce((acc, key) => {
          if (
            !key.includes('.stepId') &&
            !key.endsWith('.id') &&
            !key.endsWith('.id.keyword') &&
            !key.includes('s3Key') &&
            !key.includes('assignedTo') &&
            !key.includes('author') &&
            !key.includes('owner')
          ) {
            acc[key] = parsedHighlights[key];
          }
          return acc;
        }, {});
        // item.highlights = JSON.stringify(filteredHighlights);
        item.highlights = filteredHighlights;
      });

      // Filter all items to those that have highlights
      const filteredItems = items.filter(item => {
        return Object.keys(item.highlights).length > 0;
      });

      setResults(filteredItems);
    } catch (error) {
      console.error('Error searching: ', error);
      setResults([]);
      setTotalPages(0);
      setErrorMessage(error.errors[0].message);
    }
    setSearchedTerm(searchTerm);
    setHasSearched(true);
    setSearchRunning(false);
  }, [searchTerm, resultsPerPage, currentPage]);

  const goToPage = async page => {
    setCurrentPage(page);
    performSearch();
    // setSearchRunning(true);
    // try {
    //   const {
    //     data: {
    //       searchQuery: { items, totalHits },
    //     },
    //   } = await API.graphql(
    //     graphqlOperation(searchQuery, {
    //       searchTerm,
    //       limit: resultsPerPage,
    //       from: (page - 1) * resultsPerPage,
    //     })
    //   );
    //   setResults(items);
    // } catch (error) {
    //   console.error('Error searching: ', error);
    //   setResults([]);
    // }
    // setSearchRunning(false);
  };

  const downloadCase = async caseId => {
    setDownloading(true);

    const {
      data: {
        adminDownloadCase: { preSignedUrl },
      },
    } = await API.graphql({
      query: adminDownloadCase,
      variables: { input: { caseId } },
      authMode: 'AWS_IAM',
    });

    setDownloading(false);

    window.open(preSignedUrl, '_blank');
  };

  const searchForm = (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row">
          <p className="mb-1 font-light light-primary-blue-text">Search Term</p>

          <ToolTip
            tooltipText="Find records with the search term included in the Case Number, First Name, Last Name, State, or Volunteer fields."
            title="Search Term"
            className="ml-2 text-pursuit-gray"
          />
        </div>

        <input
          type="text"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
          placeholder="Search Term"
          className="w-full h-10 px-4 mt-2 mb-4 bg-gray-100 lg:mb-0 lg:mt-0 lg:mr-4 lg:w-48 xl:w-64"
          onKeyDown={event => {
            const pressedKey = event.key;

            if (pressedKey === 'Enter') {
              // performSearch();
              cleanSearch();
            }
          }}
        />
      </div>

      <Button solidBlue className="w-full px-4 lg:w-24" onClick={cleanSearch} loading={searchRunning}>
        Search
      </Button>
    </>
  );

  return (
    <Container height="lg:min-h-152" padding="p-4" margin="m-3 lg:m-auto lg:mx-8 lg:my-8" className="relative">
      <div className="flex pt-4 lg:px-4 flex-wrap">
        <HeaderText fontSize="text-4xl" className="mb-12 flex-auto">
          Search
        </HeaderText>

        {/* Display large search form if hasSearched is false */}
        {!hasSearched && !searchRunning && (
          <div className="flex flex-grow w-full mb-10 mt-20 items-end justify-center">{searchForm}</div>
        )}

        {(hasSearched || searchRunning) && (
          <div className="flex flex-auto items-end justify-end mb-10">{searchForm}</div>
        )}
        {/* {loading && (
          <div className="w-full flex justify-center py-10">
            <Spinner />
          </div>
        )} */}
      </div>
      {searchRunning && (
        <div className="w-full flex justify-center py-10">
          <Spinner />
        </div>
      )}
      {errorMessage && (
        <div className="flex-1 justify-center">
          <p>{errorMessage}</p>
        </div>
      )}
      {!searchRunning && hasSearched && results && results.length == 0 && !errorMessage && (
        <div className="flex-1 justify-center">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p>No search results found for "{searchedTerm}".</p>
        </div>
      )}
      {results && !searchRunning && (
        <div className="flex flex-col pt-4 lg:px-4">
          <ul className="search-results">
            {results.map((result, index) => (
              <li key={result.id}>
                <SearchResult
                  item={result}
                  index={(currentPage > 1 ? currentPage * resultsPerPage : 0) + index + 1}
                  downloadAction={downloadCase}
                  searchTerm={searchTerm}
                />
              </li>
            ))}
          </ul>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={goToPage} />
        </div>
      )}
    </Container>
  );
};

export default SearchPage;
