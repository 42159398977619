import React from 'react';

const Container = ({
  id,
  grayedBackground,
  width,
  height,
  margin,
  padding,
  centerText,
  rounded,
  className,
  outerClassName,
  children,
}) => {
  let roundedClass = 'rounded';

  if (rounded === 'md') {
    roundedClass = 'rounded-md';
  } else if (rounded === 'lg') {
    roundedClass = 'rounded-lg';
  }

  const appliedOuterClassName = `bg-white shadow-container md:shadow-xl ${width} ${height} ${margin ||
    'm-auto'} ${roundedClass} ${grayedBackground ? 'z-20 relative' : ''} ${outerClassName}`;

  const appliedClassName = `h-full standard-text-font container-shadow ${padding || 'p-2'} ${
    centerText ? 'text-center' : ''
  } ${className}`;

  function renderGrayedBackground() {
    if (grayedBackground) {
      return <div className="container-grayed" />;
    }
  }

  return (
    <>
      <div className={appliedOuterClassName}>
        <div className={appliedClassName} id={id}>
          {children}
        </div>
      </div>
      {renderGrayedBackground()}
    </>
  );
};

export default Container;
