import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';
import { Container, Button, StatusPill, DataGrid, HeaderText, LeaderboardCard } from '../../../components';
import { listOwnedCases } from '../../../generated/graphql/queries';
import { CaseStatus } from '../../../constants';

const Dashboard = () => {
  const [tenRecentActiveCases, setTenRecentActiveCases] = useState([]);
  const [tenRecentReturnedCases, setTenRecentReturnedCases] = useState([]);

  const [activeCasesLoading, setActiveCasesLoading] = useState(true);
  const [returnedCasesLoading, setReturnedCasesLoading] = useState(true);

  const fetchActiveCases = async () => {
    try {
      const params = {
        status: [CaseStatus.Active, CaseStatus.Submitted, CaseStatus.Approved, CaseStatus.Resubmitted],
        limit: 50,
      };

      const {
        data: {
          listOwnedCases: { items },
        },
      } = await API.graphql(graphqlOperation(listOwnedCases.replace('assignedToName\n', ''), params));

      setTenRecentActiveCases(items && items.length ? items.slice(0, 8) : []);
      setActiveCasesLoading(false);
    } catch (error) {
      console.error('error on fetching cases', error);
    }
  };

  const fetchReturnedCases = async () => {
    try {
      const params = {
        status: ['RETURNED'],
        limit: 50,
      };

      const {
        data: {
          listOwnedCases: { items },
        },
      } = await API.graphql(graphqlOperation(listOwnedCases.replace('assignedToName\n', ''), params));

      setTenRecentReturnedCases(items && items.length ? items.slice(0, 8) : []);
      setReturnedCasesLoading(false);
    } catch (error) {
      console.error('error on fetching cases', error);
    }
  };

  useEffect(() => {
    fetchActiveCases();
    fetchReturnedCases();
  }, []);

  const renderStatus = ({ status }) => <StatusPill status={status} />;

  const renderPoints = ({ totalPoints }) => (
    <div
      className={`${getAwardColor(
        totalPoints
      )} h-6 w-8 rounded-full flex font-bold justify-center items-center p-0 ml-2`}>
      {totalPoints?.pointsAwarded || ''}
    </div>
  );

  const getAwardColor = totalPoints => {
    // if points aren't awared yet don't show
    if (!totalPoints) {
      return 'bg-white text-white';
    }

    if (!totalPoints.isFullPoints) {
      return 'bg-pursuit-amber text-white';
    }

    return 'bg-green-500 text-white';
  };

  const getName = item =>
    item && (item.firstName || item.lastName) ? `${item.firstName || ''} ${item.lastName || ''}` : '-';

  const getDateOpened = item => (item.createdAt ? DateTime.fromISO(item.createdAt).toLocaleString() : '-');
  const getDateReturned = item => (item.returnedAt ? DateTime.fromISO(item.returnedAt).toLocaleString() : '-');

  return (
    <div className="flex flex-col w-full mt-4 lg:mt-0 lg:flex-row lg:justify-center">
      <Container
        height="lg:h-152"
        width="lg:w-1/4"
        padding="p-4"
        margin="mx-3 mb-3 lg:mb-0 lg:ml-6"
        className="flex flex-col">
        <LeaderboardCard />
      </Container>

      <Container
        height="lg:h-152"
        width="lg:w-1/2"
        padding="p-4"
        margin="mx-3 mb-3 lg:mb-0 lg:ml-6"
        className="flex flex-col">
        <HeaderText fontSize="text-2xl" className="text-left lg:text-4xl lg:text-center">
          My Cases
        </HeaderText>

        <div className="flex flex-col pt-4 overflow-y-auto lg:px-4">
          <DataGrid
            columns={[
              { title: 'Case Number', fieldName: 'caseNumber' },
              { title: 'Name', value: getName },
              { title: 'Date Opened', value: getDateOpened },
              { title: 'Status', fieldName: 'status', renderFunction: renderStatus },
              { title: 'Points', fieldName: 'totalPoints', renderFunction: renderPoints },
            ]}
            loading={activeCasesLoading}
            data={tenRecentActiveCases}
            noRecordsText="You have not opened any cases."
            rowLink={item => `/investigation/${item.id}`}
            gridKey="recent-casees"
          />
        </div>

        <div className="flex flex-row items-end justify-between flex-1 mt-4">
          <Link to="/investigation/new" className="lg:ml-4" target="_blank" rel="noopener noreferrer">
            <Button linedBlue noPadding className="w-32 text-lg">
              New Case
            </Button>
          </Link>

          <Link to="/active-cases">
            <Button className="font-semibold lg:text-lg text-pursuit-gray focus:outline-none">VIEW ALL CASES</Button>
          </Link>
        </div>
      </Container>

      <Container
        height="lg:h-152"
        width="lg:w-1/4"
        padding="p-4"
        margin="mx-3 mb-3 lg:mb-0 lg:mx-6"
        className="flex flex-col">
        <HeaderText fontSize="text-2xl" className="text-left lg:text-4xl lg:text-center">
          Returned Cases
        </HeaderText>

        <div className="flex flex-col pt-4 overflow-y-auto lg:px-4">
          <DataGrid
            columns={[
              { title: 'Case Number', fieldName: 'caseNumber' },
              { title: 'Name', value: getName },
              { title: 'Date Returned', value: getDateReturned },
            ]}
            loading={returnedCasesLoading}
            data={tenRecentReturnedCases}
            noRecordsText="You do not have any returned cases."
            rowLink={item => `/investigation/${item.id}`}
            gridKey="returned-cases"
          />
        </div>

        <div className="flex flex-row items-end justify-between flex-1 mt-4">
          <div />

          <Link to="/active-cases?status=RETURNED">
            <Button className="font-semibold lg:text-lg text-pursuit-gray focus:outline-none">
              VIEW RETURNED CASES
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
