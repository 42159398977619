import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import Select, { components, MultiValueGenericProps, MultiValueProps, OnChangeValue, Props } from 'react-select';
import AsyncSelect from 'react-select/async';
import { listEmployees, listVolunteers } from '../../generated/graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import StatusPill from '../StatusPill';
import { VolunteerStatus, UserTypes } from '../../constants';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const promiseOptions = inputValue =>
  new Promise(async resolve => {
    const params = {
      // userStatus: filters.userStatus,
      userStatus: VolunteerStatus.Certified,
      searchTerm: inputValue,
      nextToken: null,
      sortField: 'ALIAS',
      sortOrder: 'ASC',
      limit: 1000,
    };
    const volunteerResponse = await API.graphql(graphqlOperation(listVolunteers, params));
    // console.log('response', response);
    const adminResponse = await API.graphql(
      graphqlOperation(listEmployees, {
        searchTerm: inputValue,
        userStatus: UserTypes.Admin,
        nextToken: null,
        sortField: 'ALIAS',
        sortOrder: 'ASC',
        limit: 1000,
      })
    );
    const staffResponse = await API.graphql(
      graphqlOperation(listEmployees, {
        searchTerm: inputValue,
        userStatus: UserTypes.Staff,
        nextToken: null,
        sortField: 'ALIAS',
        sortOrder: 'ASC',
        limit: 1000,
      })
    );
    const adminUsersWithRealName = adminResponse.data.listEmployees.items
      .map(user => ({
        ...user,
        // Change alias to real name
        // alias: `${user.firstName} ${user.lastName}`.trim(),
      }))
      // Sort by alias
      .sort((a, b) => a.alias.localeCompare(b.alias));
    const staffUsersWithRealName = staffResponse.data.listEmployees.items
      .map(user => ({
        ...user,
        // Change alias to real name
        // alias: `${user.firstName} ${user.lastName}`.trim(),
      }))
      // Sort by alias
      .sort((a, b) => a.alias.localeCompare(b.alias));

    // Combine the two lists
    // const users = [...volunteerResponse.data.listVolunteers.items, ...staffResponse.data.listEmployees.items];
    const users = [
      ...volunteerResponse.data.listVolunteers.items,
      ...adminUsersWithRealName,
      ...staffUsersWithRealName,
    ];

    // const possibleUsers = response.data.listVolunteers.items.map(user => ({
    const possibleUsers = users.map(user => ({
      label: user.alias,
      value: user.id,
      data: {
        // group: 'volunteer',
        status: user.userStatus,
      },
      meta: {
        realName: `${user.firstName} ${user.lastName}`.trim(),
        // group: 'volunteer',
        status: user.userStatus,
      },
    }));
    const adminUsersGroups = [
      {
        label: 'Project 1591',
        value: '#inbox-group#admin',
        data: {
          group: 'admin',
          status: 'shared',
        },
        meta: {
          realName: 'Project 1591',
          group: 'admin',
          status: 'shared',
        },
      },
    ];
    possibleUsers.push(...adminUsersGroups);
    // console.log('possibleUsers', possibleUsers);
    const filtered = possibleUsers.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    // Group by status, set label to status and add records to options
    const grouped = filtered.reduce((acc, cur) => {
      const { status } = cur.data;
      if (!acc[status]) {
        acc[status] = {
          label: status,
          options: [],
        };
      }
      acc[status].options.push(cur);
      return acc;
    }, {});
    resolve(Object.values(grouped));

    // resolve(filtered);
  });
/**
 *
 * @param {object} props Component options
 * @returns {components.Option} - The page JSX
 */
const CheckableOption = ({ isSelected, children, ...rest }) => {
  // const { value, data } = rest;
  // const thisOption = options.find(({ value: optionValue }) => optionValue === value);
  const { status, group } = rest.data.data;
  const statusTag = status && <StatusPill status={status} />;
  // const groupTag = group && <StatusPill status={group} />;
  return (
    <components.Option isSelected={isSelected} {...rest}>
      <div className="flex">
        {/* <div className="grid grid-cols-min grid-cols-3 gap-x-1 justify-items-start justify-start w-96 grid-rows-1" > */}
        {/* <div className="grid grid-cols-[max-content_1fr]"> */}
        {/* <div className="col-span-min"> */}
        <div className="flex-none pr-4">
          <input type="checkbox" checked={isSelected} onChange={() => null} />
        </div>
        <div className="flex-initial w-48">
          <label>{children}</label>
        </div>
        <div className="flex-initial">{statusTag}</div>
      </div>
      {/* {groupTag} */}
      {/* {JSON.stringify({ options: options.slice(0, 2), status, group })} */}
    </components.Option>
  );
};

const formatOptionLabel = ({ label, meta = {}, ...rest }) => {
  // Display real names for admins and staff
  const { realName, group, status } = meta;
  // console.log('label', { label, rest });
  if (status === 'ADMIN' || status === 'STAFF') {
    return <span title={`Alias: ${label}`}>{realName}</span>;
    // return realName;
  }
  return label;
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

CheckableOption.propTypes = {
  isSelected: PropTypes.bool,
};

const UserSelector = ({ selected = [], onChange = _ => {}, isDisabled = false }) => {
  const [internalSelected, setInternalSelected] = useState(selected);

  const updateSelected = values => {
    console.log('values', values);
    setInternalSelected(values);
    onChange(values);
  };

  useEffect(() => {
    // console.log('selected', selected);
    setInternalSelected(selected);
  }, [selected]);

  return (
    <>
      <AsyncSelect
        isMulti
        cacheOptions
        value={internalSelected}
        defaultOptions
        isDisabled={isDisabled}
        closeMenuOnSelect={false}
        allowSelectAll={true}
        hideSelectedOptions={false}
        loadOptions={promiseOptions}
        components={{
          Option: CheckableOption,
        }}
        onChange={updateSelected}
        onError={e => {
          console.log('Error', e);
        }}
        // menuIsOpen={true}
        classNames={{
          option: state => {
            return 'flex space-x-2';
          },
        }}
        getOptionLabel={formatOptionLabel}
        formatGroupLabel={formatGroupLabel}
      />
      {/* {JSON.stringify({ selected, internalSelected })} */}
    </>
  );
};

export default UserSelector;
