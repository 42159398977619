import React, { useState, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';

import { Container, HeaderText, TextField, Button, DropDown, CountryStateZipPicker } from '../../../components';
import { useUser } from '../../../contexts/userContext';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import { howYouHeardAboutUsOptions } from '../../../helpers/dropdownOptions';
import { zipValidator } from '../../../helpers/createAccount/fieldValidation';
import { updateUser } from '../../../generated/graphql/mutations';

const Settings = () => {
  const { user, setUser } = useUser();

  const [capitalizedFirstName, setCapitalizedFirstName] = useState(
    user.firstName && user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)
  );

  const [capitalizedLastName, setCapitalizedLastName] = useState(
    user.lastName && user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)
  );

  const [, setAlert] = useContext(NotificationContext);

  const [firstName, setFirstName] = useState(capitalizedFirstName || '');
  const [lastName, setLastName] = useState(capitalizedLastName || '');
  const [country, setCountry] = useState(user.country);
  const [state, setState] = useState(user.state);
  const [zip, setZip] = useState(user.zip || '');
  const [heardAboutUs, setHeardAboutUs] = useState(user.heardAboutUs);
  const [dateOfBirth] = useState(user.dateOfBirth);

  const { alias, email } = user;

  const saveUser = async () => {
    try {
      if (
        !firstName ||
        !lastName ||
        (country === 'United States' && !state) ||
        (country === 'United States' && !zip) ||
        !heardAboutUs
      ) {
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'Please fill out all fields',
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });

        return;
      }

      const zipValid = zipValidator(setAlert, zip, country);

      if (!zipValid) {
        return;
      }

      const params = {
        input: {
          firstName,
          lastName,
          country,
          state: (country === 'United States' && state) || null,
          zip: (country === 'United States' && zip) || null,
          heardAboutUs,
        },
      };

      const {
        data: { updateUser: updatedUser },
      } = await API.graphql(graphqlOperation(updateUser, params));

      setCapitalizedFirstName(firstName.charAt(0).toUpperCase() + firstName.slice(1));
      setCapitalizedLastName(lastName.charAt(0).toUpperCase() + lastName.slice(1));

      setUser({ ...user, ...updatedUser });

      toast.success('Successfully Saved', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });
    } catch (error) {
      console.error('Error saving user: ', error);

      toast.error('Error Saving User', {
        progress: false,
        className: 'bg-pursuit-red text-white',
        autoClose: false,
        closeButton: true,
        icon: () => <Icons.FaExclamationCircle size={18} className="text-white" />,
      });
    }
  };

  return (
    <Container
      height="lg:min-h-104"
      width="lg:w-4/5 xl:w-3/5"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto mt-4"
    >
      <HeaderText fontSize="text-4xl" className="mb-6">
        Personal Information
      </HeaderText>

      <div>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:mr-4">
            <TextField
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              label="First Name"
              className="mb-4"
              required
            />

            <TextField
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              label="Last Name"
              className="mb-4"
              required
            />

            <CountryStateZipPicker
              country={country}
              setCountry={setCountry}
              state={state}
              setState={setState}
              zip={zip}
              setZip={setZip}
            />

            <DropDown
              value={howYouHeardAboutUsOptions.find(x => x.value === heardAboutUs)}
              label="How Did You Hear About Us?"
              onChange={option => setHeardAboutUs(option.value)}
              options={howYouHeardAboutUsOptions}
              className="mb-4"
              containerClassName="mb-4"
              required
            />
          </div>

          <div className="w-full lg:mr-4">
            <TextField value={alias} label="Alias" className="mb-4" readonly />

            <TextField value={email} label="Email Address" className="mb-4" readonly />

            <TextField value={dateOfBirth} label="Date of Birth" className="mb-4" readonly />
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <Button
            linedBlue
            noPadding
            className="w-20 lg:w-24 mr-6"
            onClick={() => {
              setFirstName(capitalizedFirstName || '');
              setLastName(capitalizedLastName || '');
              setCountry(user.country);
              setState(user.state);
              setHeardAboutUs(user.heardAboutUs);
            }}
          >
            CANCEL
          </Button>
          <Button
            solidBlue
            noPadding
            className="w-20 lg:w-24"
            onClick={() => {
              saveUser();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
