import React, { useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';
import { validatePageOne } from '../../../helpers/createAccount/createAccountValidation.js';
import HeaderText from '../../../components/HeaderText';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import Container from '../../../components/Container';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';

const PageOne = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  pageChange,
}) => {
  const [, setAlert] = useContext(NotificationContext);
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/create-account_step1' });
  }, []);

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 mt-3 md:mx-12 lg:m-auto"
    >
      <center>
        <HeaderText noBold className="text-3xl lg:text-4xl">
          Create an Account
        </HeaderText>
      </center>
      <div>
        <TextField
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          placeHolder="First Name"
          label="First Name"
          className="mb-4"
          required
        />

        <TextField
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          placeHolder="Last Name"
          label="Last Name"
          className="mb-4"
          required
        />

        <TextField
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeHolder="Email"
          label="Email"
          className="mb-4"
          required
        />

        <TextField
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeHolder="Password"
          label="Password"
          className="mb-4"
          info="Your password must be 8 characters long, have at least one number, one upper case letter, one lower case letter, and one special character"
          infoTitle="Password Requirements"
          required
        />

        <TextField
          type="password"
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          placeHolder="Password"
          label="Confirm Password"
          className="mb-8"
          required
        />

        <div className="flex justify-between">
          <Button
            linedBlue
            noPadding
            className="w-32"
            onClick={() => {
              history.push('login');
            }}
          >
            CANCEL
          </Button>
          <Button
            solidBlue
            noPadding
            className="w-32"
            onClick={() => {
              if (validatePageOne(setAlert, firstName, lastName, email, password, confirmPassword)) {
                pageChange('page-two');
              }
            }}
          >
            NEXT
          </Button>
        </div>
        <p className="flex items-center justify-center mt-4 lg:justify-end primary-blue-text">
          Already have an account?&nbsp;
          <Button
            anchorTag
            onClick={() => {
              history.push('login');
            }}
          >
            Click Here
          </Button>
        </p>
      </div>
    </Container>
  );
};

export default PageOne;
