import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as Icons from 'react-icons/fa';
import { API, graphqlOperation } from 'aws-amplify';
import { getNotificationCount, listNotifications } from '../../generated/graphql/queries';
import { clearNotificationCount } from '../../generated/graphql/mutations';
import { onNotificationCreated } from '../../generated/graphql/subscriptions';
import Spinner from '../Spinner';
import NotificationRow from '../NotificationRow';
import { useUser } from '../../contexts/userContext';
import { useFocus } from '../../contexts/focusContext';
const changeFavicon = (hasUnread = false) => {
  const link = document.querySelector('link[rel="icon"]');
  // console.log('link', link, hasUnread);
  if (link && hasUnread === true) {
    link.setAttribute('href', '/p1591-icon-unread.svg');
  } else if (link) {
    link.setAttribute('href', '/p1591-icon-read.svg');
  }
};

const Notifications = ({ limit = 50 }) => {
  const { user } = useUser();
  const { isFocused } = useFocus();

  const [dropdownIsActive, setDropdownIsActive] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState();
  const [nextToken, setNextToken] = useState(null);

  const [loading, setLoading] = useState(false);

  const clickOutsideRef = useRef(null);

  const userId = user?.id;

  const queryNotificationCount = useCallback(async () => {
    try {
      const {
        data: { getNotificationCount: count = null },
      } = await API.graphql(graphqlOperation(getNotificationCount));

      setNotificationCount(count || 0);
    } catch (error) {
      console.error('Error fetching notification count: ', error);
    }
  }, []);

  const queryNotifications = async ({ loadNext = false } = {}) => {
    setLoading(true);

    try {
      const prevNotifications = loadNext && notifications && nextToken ? [...notifications] : [];

      const params = { limit };

      if (loadNext && nextToken) {
        params.nextToken = nextToken;
      }

      const {
        data: { listNotifications: result = null },
      } = await API.graphql(graphqlOperation(listNotifications, params));

      const newNotifications = result.items;

      const updatedNotifications = [...prevNotifications, ...newNotifications];

      setNotifications(updatedNotifications);
      setNextToken(result.nextToken || null);
      setLoading(false);

      setNotificationCount(0);

      try {
        await API.graphql(graphqlOperation(clearNotificationCount));
      } catch (error) {
        console.error('Error clearing notification count: ', error);
      }
    } catch (error) {
      console.error('Error fetching notifications: ', error);
    }

    setLoading(false);
  };

  const loadMoreNotifications = () => {
    queryNotifications({ loadNext: true });
  };

  useEffect(() => {
    if (isFocused) {
      queryNotificationCount();
    }
  }, [queryNotificationCount, userId, isFocused]);

  useEffect(() => {
    if (dropdownIsActive) {
      queryNotifications();
    }
  }, [dropdownIsActive]);

  useEffect(() => {
    let subscription = null;

    const initSubscription = async () => {
      try {
        subscription = await API.graphql(graphqlOperation(onNotificationCreated, { recipient: userId })).subscribe({
          error: err => {
            console.error('Error subscribing to onNotificationCreated: ', err);
          },
          next: data => {
            queryNotificationCount();
            // TODO: revisit this logic at a later time to avoid additional queries if possible

            // const existingNotifications = notifications ? [...notifications] : [];

            // if (existingNotifications.length) {
            //   const notification = data.value.data.onNotificationCreated;

            //   const matchingNotification = existingNotifications.find(x => x.id === notification.id);

            //   if (!matchingNotification) {
            //     const updatedNotifications = [...existingNotifications, notification];

            //     const updatedNextToken = {
            //       id: notification.id,
            //       createdAt: notification.createdAt,
            //       recipient: notification.recipient,
            //     };

            //     setNextToken(updatedNextToken);
            //     setNotifications(updatedNotifications);
            //   }
            // }
          },
        });
      } catch (error) {
        console.error('Error on subscription: ', error);
      }
    };

    initSubscription();

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [notifications, userId, dropdownIsActive]);

  const deactivevateDropDoneOnOutsideClick = event => {
    if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
      setDropdownIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', deactivevateDropDoneOnOutsideClick);

    return () => {
      document.removeEventListener('mousedown', deactivevateDropDoneOnOutsideClick);
    };
  }, [clickOutsideRef]);

  function renderDropdown() {
    if (dropdownIsActive) {
      return (
        <div ref={clickOutsideRef} className="absolute mt-4 rounded-md shadow-lg right-0 lg:overflow-visible">
          <div className="bg-white rounded-md shadow-xs mx-2 lg:mx-0 lg:w-104 notifications">
            <div className="flex flex-col items-center py-4 h-80 overflow-y-auto px-4">
              {loading && (
                <div className="flex w-full justify-center items-center">
                  <Spinner />
                </div>
              )}

              {!loading && notifications && !notifications.length && (
                <div className="flex w-full justify-center items-center">You have no notifications</div>
              )}

              {notifications && notifications.length
                ? notifications.map(notification => (
                    <NotificationRow notification={notification} key={`notification-${notification.id}`} />
                  ))
                : null}
              <div>
                <a onClick={loadMoreNotifications}>Load more</a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  // Change favicon if user has unread messages
  useEffect(() => {
    // console.log({ notificationCount });
    changeFavicon(!!notificationCount);
  }, [notificationCount]);

  const getFormattedNotificationCount = () => {
    if (!notificationCount) {
      return null;
    }

    if (notificationCount > 99) {
      return '99+';
    }

    return notificationCount;
  };

  return (
    <div className="lg:relative">
      <button
        onClick={() => {
          setDropdownIsActive(true);
        }}
        type="button"
        className="flex flex-row items-center focus:outline-none mx-3">
        <div className="relative mx-1 px-2 py-1 lg:mt-2">
          <Icons.FaRegBell className="text-pursuit-gray text-2xl lg:text-4xl" />

          {notificationCount ? (
            <>
              <div className="bg-pursuit-red text-pursuit-red animate-ping rounded-full absolute top-0 right-0 text-xs lg:text-sm min-w-4 lg:min-w-5 h-4 lg:h-5 px-1">
                {getFormattedNotificationCount()}
              </div>

              <div className="bg-pursuit-red rounded-full text-white absolute top-0 right-0 text-xs lg:text-sm min-w-4 lg:min-w-5 h-4 lg:h-5 px-1">
                {getFormattedNotificationCount()}
              </div>
            </>
          ) : null}
        </div>
      </button>

      {renderDropdown()}
    </div>
  );
};

export default Notifications;
