import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { isArray } from 'highcharts';
import highchartsMap from 'highcharts/modules/map';

highchartsMap(Highcharts);

const LEAD_GRAPH_DEFINITION = {
  title: 'Submitted & Approved',
  type: 'line',
  yAxis: 'Quantity',
  series: ['Approved', 'Submitted'],
};
const USER_COUNT_GRAPH_DEFINITION = {
  title: 'User Count',
  type: 'line',
  yAxis: ['Total Users', 'New Users'],
  series: ['Total Users', 'New Users'],
};
const HOURS_GRAPH_DEFINITION = {
  title: 'Hours In App',
  type: 'line',
  yAxis: ['Total Hours', 'User Hours'],
  series: ['Total Hours', 'User Hours'],
};

const createGraph = (data1, data2, { title, type, yAxis, series: seriesNames }, legendOpts) => {
  const series = [
    {
      name: seriesNames[0],
      yAxis: 0,
      data: data1,
    },
    {
      name: seriesNames[1],
      yAxis: isArray(yAxis) ? 1 : 0,
      data: data2,
    },
  ];

  let plotOptions = {};

  if (type === 'area') {
    plotOptions = {
      area: {
        stacking: 'normal',
        fillOpacity: 0.25,
      },
    };
  }

  const options = {
    chart: {
      height: 100,
      margin: 20,
      spacing: [0, 10, 15, 10],
      type,
    },
    colors: ['#770303', '#01558B', '#e4d354'],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: title,
      align: 'left',
      widthAdjust: 0,
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
    plotOptions,
    xAxis: {
      type: 'datetime',
      labels: { style: { fontSize: '8px' } },
      tickLength: 5,
    },
    yAxis: isArray(yAxis)
      ? [
          {
            title: { text: yAxis[0], margin: 3, style: { fontSize: '8px' } },
            labels: { distance: 0, padding: 0, x: 0, style: { fontSize: '8px' } },
            tickPixelInterval: 30,
          },
          {
            title: { text: yAxis[1], margin: 3, style: { fontSize: '8px' } },
            labels: { distance: 0, padding: 0, x: 0, style: { fontSize: '8px' } },
            opposite: true,
            tickPixelInterval: 30,
            softMin: 0,
          },
        ]
      : {
          title: { text: yAxis, margin: 3, style: { fontSize: '8px' } },
          labels: { distance: 0, padding: 0, x: 0, style: { fontSize: '8px' } },
          tickPixelInterval: 30,
          softMin: 0,
        },
    series,
  };

  return (
    <div className="mt-2 mb-2">
      <HighchartsReact constructorType="mapChart" highcharts={Highcharts} options={options} />
      {buildLegend(legendOpts)}
    </div>
  );
};

export { LEAD_GRAPH_DEFINITION, USER_COUNT_GRAPH_DEFINITION, HOURS_GRAPH_DEFINITION, createGraph };

const buildLegend = legendInput => (
  <div className="flex justify-center">
    {legendInput.map((legend, index) => {
      const containerClass = index > 0 ? 'flex items-center pt-1 ml-4' : 'flex items-center pt-1';

      return (
        <div className={containerClass} key={index}>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="8" height="8" fill={legend.color} />
          </svg>{' '}
          <span className="ml-1 text-xs">{legend.value}</span>
        </div>
      );
    })}
  </div>
);
