/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import SideBarData from '../SidebarData';
import PopUpConfirmation from '../PopupConfirmation';
// import { shareMFACredentials } from '../../generated/graphql/mutations';
import { shareMfaCredentials as shareMFACredentials } from '../../generated/graphql/mutations';
import { NotificationContext } from '../../helpers/AlertContext/AlertContext.js';
import { emailValidator } from '../../helpers/createAccount/fieldValidation.js';

const NavBar = ({ open, setOpen, hidden, showRules, showIntroVideo, useEmployeeRouting, isAdmin }) => {
  const location = useLocation();
  const [isShareQRCodeModalOpen, setIsShareQRCodeModalOpen] = useState(false);
  const [shareQRCodeEmail, setShareQRCodeEmail] = useState('');
  const [shareQRButtonLoading, setShareQRButtonLoading] = useState(false);
  const [, setAlert] = useContext(NotificationContext);

  if (hidden) {
    return null;
  }

  const handleShareQRCodeModal = () => {
    setShareQRCodeEmail('');
    setIsShareQRCodeModalOpen(!isShareQRCodeModalOpen);
  };

  const shareQRCode = async () => {
    setShareQRButtonLoading(true);
    const isValidEmail = emailValidator(setAlert, shareQRCodeEmail);

    if (isValidEmail) {
      try {
        await API.graphql(graphqlOperation(shareMFACredentials, { email: shareQRCodeEmail }));
        setShareQRCodeEmail('');

        handleShareQRCodeModal();

        toast.success('MFA Credentials Shared', {
          progress: false,
          className: 'bg-green-500 text-white',
          autoClose: 1500,
          closeButton: false,
          icon: () => <Icons.FaCheck size={18} className="text-white" />,
        });
      } catch (error) {
        console.error(error);
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'Unable to share MFA Credentials',
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    }
    setShareQRButtonLoading(false);
  };

  const { volunteerLinks, employeeLinks, adminLinks } = SideBarData({
    openIntroVideo: showIntroVideo,
    openRulesModal: showRules,
    handleShareQRCodeModal,
  });

  const buildLinks = () => {
    if (useEmployeeRouting) {
      if (isAdmin) {
        return [...employeeLinks, ...adminLinks];
      }

      return [...employeeLinks];
    }

    return [...volunteerLinks];
  };

  const links = buildLinks();

  return (
    <>
      <div className="flex-col hidden lg:flex">
        <motion.nav
          initial={{ width: '4rem' }}
          animate={{ width: open ? '13rem' : '4rem' }}
          transition={{ duration: 0.15, type: 'keyframes' }}
          className="flex flex-col flex-1 w-0 mt-24 bg-pursuit-darkred lg:w-auto"
        >
          <div className="fixed mt-4">
            {links.map((item, index) => (
              <Link
                to={item.path}
                key={`desktop-navitem-${index}`}
                onClick={item.onClick || null}
                target={item.newTab ? '_blank' : null}
                rel="noopener noreferrer"
              >
                <li
                  key={index}
                  className={`flex flex-row items-center px-5 
                ${
                  location.pathname === item.path ? 'text-pursuit-red bg-white' : 'text-white'
                } text-lg hover:bg-gray-200 hover:text-pursuit-red`}
                >
                  <div className="my-3">{item.icon}</div>

                  {open && (
                    <motion.div
                      animate={{ width: open ? '9.25rem' : '0rem' }}
                      transition={{ duration: 0.15, type: 'keyframes' }}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <div className="my-0 ml-4">{item.title}</div>
                    </motion.div>
                  )}
                </li>
              </Link>
            ))}
          </div>
        </motion.nav>
      </div>

      <div className="flex flex-col lg:hidden">
        <motion.nav
          initial={{ top: 0, left: 0, right: 0, bottom: '100%' }}
          animate={{ bottom: open ? 0 : '100%' }}
          transition={{ duration: 0.25, type: 'keyframes' }}
          className="fixed z-30 flex flex-col mt-12 bg-pursuit-darkred"
          style={{ opacity: 0.95 }}
        >
          {links.map((item, index) => (
            <Link
              to={item.path}
              className={index === 0 ? 'mt-2' : ''}
              onClick={() => {
                if (setOpen) {
                  setOpen(false);
                }

                if (item.onClick) {
                  item.onClick();
                }
              }}
              key={`mobile-navitem-${index}`}
            >
              <li
                key={index}
                className={`flex flex-row items-center px-5 
                ${
                  location.pathname === item.path ? 'text-pursuit-red bg-white' : 'text-white'
                } text-lg hover:bg-gray-200 hover:text-pursuit-red`}
              >
                {open && (
                  <>
                    <div className="my-3">{item.icon}</div>
                    <div className="my-0 ml-4">{item.title}</div>
                  </>
                )}
              </li>
            </Link>
          ))}
        </motion.nav>
      </div>
      <div>
        {isShareQRCodeModalOpen && (
          <PopUpConfirmation
            title="Share MFA Credentials"
            content={
              <div className="flex-col justify-center">
                <div>Share MFA QR code with:</div>
                <div>
                  <input
                    type="text"
                    value={shareQRCodeEmail}
                    onChange={event => setShareQRCodeEmail(event.target.value)}
                    placeholder="Email"
                    className="bg-gray-100 h-10 mb-4 mt-2 lg:mb-0 lg:mt-0 lg:mr-4 px-4 w-full lg:w-80"
                  />
                </div>
              </div>
            }
            onConfirm={shareQRCode}
            onCancel={handleShareQRCodeModal}
            confirmText="SHARE"
            className="w-11/12 lg:w-auto"
            confirmLoading={shareQRButtonLoading}
            confirmLoadingText="SHARE"
          />
        )}
      </div>
    </>
  );
};

export default NavBar;
