import React, { useState, useContext, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import states from 'states-us';

import {
  Container,
  HeaderText,
  Button,
  DropDown,
  TextField,
  AliasGenerator,
  CountryStateZipPicker,
} from '../../../components';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import { adminCreateLeo } from '../../../generated/graphql/mutations';
import { UserTypes } from '../../../constants';
import { validateAdminCreateLEOUser } from '../../../helpers/createAccount/createAccountValidation';

const CreateLEO = () => {
  const history = useHistory();

  const { state: routeState } = useLocation();

  const [, setAlert] = useContext(NotificationContext);

  const defaultState = {
    firstName: '',
    lastName: '',
    email: '',
    agency: '',
    department: '',
    position: '',
    zip: '',
    city: '',
    userType: UserTypes.LEO,
    state: '',
  };

  const [newUser, setNewUser] = useState(defaultState);

  // const selectedState = useMemo(
  // const selectedState = states.find(x => x.abbreviation === newUser.state);
  //   [states, newUser]
  // );

  const [alias, setAlias] = useState('');
  const [aliasClearedAt, setAliasClearedAt] = useState(null);

  const [saving, setSaving] = useState();

  const userTypeOptions = [
    { label: 'Volunteer', value: UserTypes.Volunteer },
    { label: 'Staff', value: UserTypes.Staff },
    { label: 'Admin', value: UserTypes.Admin },
  ];

  const createUser = async () => {
    try {
      setSaving(true);
      console.log({ newUser });

      const stateIsValid = validateAdminCreateLEOUser(setAlert, {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        // dateOfBirth: newUser.dateOfBirth,
        country: newUser.country,
        state: newUser.state,
        // zip: newUser.zip,
      });

      if (stateIsValid) {
        // const formattedDOB = DateTime.fromJSDate(newUser.dateOfBirth).toFormat('MM/dd/yyyy');

        const {
          data: { adminCreateLEO: user = null },
        } = await API.graphql(
          graphqlOperation(adminCreateLeo, {
            input: {
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              email: newUser.email,
              // alias,
              // dateOfBirth: formattedDOB,
              country: 'United States',
              agency: newUser.agency,
              department: newUser.department,
              position: newUser.position,
              city: newUser.city,
              state: newUser.state,
              zip: newUser.zip,
              userType: 'LEO',
            },
          })
        );

        toast.success('User Created', {
          progress: false,
          className: 'bg-green-500 text-white',
          autoClose: 1500,
          closeButton: false,
          icon: () => <Icons.FaCheck size={18} className="text-white" />,
        });

        // history.push(`/user/${user.id}`);
        history.push(`/admin/leo`);
      }
    } catch (error) {
      let errorMessage = 'An error occurred attempting to create the user.';

      if (error.errors && Array.isArray(error.errors)) {
        const userExistsError = error.errors.find(x => x.errorType === 'UsernameExistsException');

        if (userExistsError) {
          errorMessage = `Error creating user - ${userExistsError.message}`;
        }
      }

      console.error('Error creating user: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: errorMessage,
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setSaving(false);
  };

  return (
    <Container
      height="lg:min-h-104"
      width="lg:w-4/5"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto mt-4">
      <HeaderText fontSize="text-4xl" className="mb-12">
        Create New User
      </HeaderText>

      <div>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="flex flex-col lg:w-1/2 lg:mr-8">
            <TextField
              value={newUser.firstName}
              onChange={e => setNewUser({ ...newUser, firstName: e.target.value })}
              label="First Name"
              className="mb-4"
              required
              tabindex="1"
            />
            <TextField
              value={newUser.email}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
              label="Email Address"
              className="mb-4"
              required
              tabindex="3"
            />

            <TextField
              value={newUser.department}
              onChange={e => setNewUser({ ...newUser, department: e.target.value })}
              label="Department"
              className="mb-4"
              tabindex="5"
            />

            <TextField
              value={newUser.city}
              onChange={e => setNewUser({ ...newUser, city: e.target.value })}
              label="City"
              className="mb-4"
              required
              tabindex="7"
            />
          </div>

          <div className="flex flex-col lg:w-1/2 lg:ml-8">
            <TextField
              value={newUser.lastName}
              onChange={e => setNewUser({ ...newUser, lastName: e.target.value })}
              label="Last Name"
              className="mb-4"
              required
              tabindex={2}
            />

            <TextField
              value={newUser.agency}
              onChange={e => setNewUser({ ...newUser, agency: e.target.value })}
              label="Agency"
              className="mb-4"
              tabindex={4}
            />

            <TextField
              value={newUser.position}
              onChange={e => setNewUser({ ...newUser, position: e.target.value })}
              label="Position"
              className="mb-4"
              tabindex={6}
            />

            <div className="flex flex-col lg:flex-row">
              <DropDown
                // value={newUser.state}
                // value={selectedState}
                value={states.find(x => x.abbreviation === newUser.state)}
                // onChange={option => setState(option.abbreviation)}
                onChange={option => setNewUser({ ...newUser, state: option.abbreviation })}
                label="State"
                containerClassName="mb-4 lg:mr-2"
                options={states}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.abbreviation}
                required
                tabIndex={8}
              />

              <TextField
                value={newUser.zip}
                onChange={e => setNewUser({ ...newUser, zip: e.target.value })}
                label="Zip Code"
                className="mb-4"
                required
                tabindex={10}
              />
            </div>
            {/* <AliasGenerator alias={alias} setAlias={setAlias} excludeHeader clearedAt={aliasClearedAt} /> */}

            {/* <div className="flex flex-col w-full lg:w-auto">
              <DropDown
                value={userTypeOptions.find(x => x.value === newUser.userType) || null}
                onChange={option => setNewUser({ ...newUser, userType: option.value })}
                width="w-full"
                containerClassName="mr-2 mb-4"
                options={userTypeOptions}
                label="User Type"
                required
              />
            </div> */}
          </div>
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button
            linedBlue
            noPadding
            className="px-4 lg:ml-6 w-full lg:w-auto mr-2"
            onClick={() => {
              setNewUser(defaultState);
              setAliasClearedAt(new Date().toISOString());
            }}>
            CLEAR
          </Button>

          <Button solidBlue noPadding className="px-4 ml-2 w-full lg:w-auto" onClick={createUser} loading={saving}>
            {saving ? 'CREATING' : 'CREATE'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CreateLEO;
