import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { Dashboard, Volunteers, UserDetails, CaseReview, MessageIndex, MessageCreate } from '../pages/employee';
import { MessageRead, MessageViewSent } from '../pages/employee/Messaging';

const EmployeeRouting = () => {
  const location = useLocation();

  return (
    <Switch location={location} key={location.pathname}>
      <Route exact path={['/', '/dashboard']}>
        <Dashboard />
      </Route>

      <Route path="/case-review/:idParam">
        <CaseReview />
      </Route>

      <Route path="/user/:userId">
        <UserDetails />
      </Route>

      <Route path="/volunteers">
        <Volunteers />
      </Route>

      <Route path="/messaging/read/:messageId">
        <MessageRead />
      </Route>
      <Route path="/messaging/view/:createRequestId">
        <MessageViewSent />
      </Route>
      <Route path="/messaging/create">
        <MessageCreate />
      </Route>
      <Route path="/messaging">
        <MessageIndex />
      </Route>

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default EmployeeRouting;
