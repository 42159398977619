import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, DropDown, TextField, UserSelector, PopUpConfirmation, FileUploader } from '../../components';
import { NotificationContext } from '../../helpers/AlertContext/AlertContext';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import { getUserById } from '../../generated/graphql/queries';
import { API, graphqlOperation, label } from 'aws-amplify';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useMessages } from '../../helpers/useMessages';
import { useUser } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';

const USE_VOLUNTEER_VIEW = 'USE_VOLUNTEER_VIEW';
// Format a date object to a string in the format DD-MMM-YYYY
const formatDate = dateObject => {
  const date = Object.fromEntries(
    Intl.DateTimeFormat(undefined, { year: 'numeric', month: 'short', day: '2-digit' })
      .formatToParts(dateObject)
      .map(({ type, value }) => [type, value])
  );
  if (!date) {
    return '';
  }
  // console.log({ dateObject, date });
  // return dateObject;
  return `${date.day}-${date.month}-${date.year}`.toUpperCase();
};

// Unformatted date string to date object, for use in date picker, dateString is in the format DD-MMM-YYYY
const unformatDate = dateString => {
  const date = dateString.split('-');
  if (date.length != 3) {
    return null;
  }
  const [day, month, year] = date;
  return new Date(`${month} ${day}, ${year}`);
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // console.log('Encoding file', file);
    reader.onload = () => {
      // console.log('Reader', reader);
      // console.log('Result', reader.result);
      const response = {
        name: file.name,
        type: file.type,
        size: file.size,
        data: reader.result,
      };
      // return resolve(reader.result.replace(/^data:.+;base64,/, ''));
      return resolve(response);
      // return resolve(reader.result);
    };
    reader.onerror = error => reject(error);
  });

const messageTitles = [
  // {
  //   label: 'Operational Support',
  //   value: 'OPERATIONAL_SUPPORT',
  // },
  {
    label: 'Operational Support',
    value: 'OPERATIONAL_SUPPORT',
    fields: [
      {
        label: 'Start Date',
        name: 'startDate',
        type: 'date',
        required: true,
        formatter: formatDate,
        unformatter: unformatDate,
      },
      {
        label: 'End Date',
        name: 'endDate',
        type: 'date',
        formatter: formatDate,
        unformatter: unformatDate,
      },
    ],
  },
  {
    label: 'Operational Support - 24-hour Reminder',
    value: 'OPERATIONAL_SUPPORT_24HR_REMINDER',
    fields: [
      {
        label: 'Start Date',
        name: 'startDate',
        type: 'date',
        required: true,
        formatter: formatDate,
        unformatter: unformatDate,
      },
      {
        label: 'End Date',
        name: 'endDate',
        type: 'date',
        formatter: formatDate,
        unformatter: unformatDate,
      },
    ],
  },
  {
    label: 'Operational Support - Kickoff',
    value: 'OPERATIONAL_SUPPORT_KICKOFF',
    fields: [
      {
        label: 'Start Date',
        name: 'startDate',
        type: 'date',
        required: true,
        formatter: formatDate,
        unformatter: unformatDate,
      },
      {
        label: 'End Date',
        name: 'endDate',
        type: 'date',
        formatter: formatDate,
        unformatter: unformatDate,
      },
    ],
  },
  {
    label: 'New Feature Update',
    value: 'NEW_FEATURE',
  },
  {
    label: 'Case Feedback',
    value: 'CASE_FEEDBACK',
  },
  {
    label: 'General Information',
    value: 'GENERAL_INFORMATION',
  },
  {
    label: 'User Inquiry',
    value: 'USER_INQUIRY',
  },
  {
    label: 'Custom',
    value: 'CUSTOM_MESSAGE',
    fields: [
      {
        label: 'Custom Title',
        name: 'customTitle',
        type: 'text',
        required: true,
        // formatter: formatDate,
        // unformatter: unformatDate,
      },
      {
        label: 'Include Message in email body?',
        name: 'includeMessage',
        type: 'checkbox',
      },
      {
        label: 'File attachments',
        name: 'fileAttachments',
        type: 'file',
        formatter: toBase64,
        maxInstances: 3,
      },
    ],
  },
];

const userDetails = id =>
  new Promise(async resolve => {
    const params = {
      id,
    };
    if (id == '#inbox-group#admin') {
      resolve({
        label: 'Project 1591',
        value: '#inbox-group#admin',
      });
    }
    const response = await API.graphql(graphqlOperation(getUserById, params));
    // console.log('response', response);
    const { alias } = response.data.getUserById || {};
    // const possibleUsers = response.data.listEmployees.items.map(user => ({
    //   label: user.alias,
    //   value: user.id,
    // }));
    // resolve(possibleUsers.filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase())));
    resolve({ label: alias, value: id });
    // resolve(possibleUsers);
    // setTimeout(() => {
    //   resolve(filterColors(inputValue));
    // }, 1000);
  });

// Message defaults
const defaults = {
  recipients: '',
  title: '',
  message: '',
};
/**
 * Message form
 * @param {Object} props - React props
 * @param {Object} props.notification - The notification object
 * @param {string} props.notification.recipients - The recipients
 * @param {string} props.notification.title - The title
 * @param {string} props.notification.message - The message
 * @param {String} props.createLabel - Button label
 * @param {String} props.cancelLink - Link of cancel button
 * @param {Boolean} props.isReply - Disable title an subject
 * @returns {JSX.Element} - The component
 */
const MessageForm = ({
  notification = defaults,
  createLabel = 'Create',
  cancelLabel = 'Back',
  clearLabel = 'Clear',
  isReply = false,
  cancelLink = '/messaging',
  cancelCallback,
  createCallback,
  noUserSelector = false,
  subjectDisabled = false,
  noTitle = false,
  noSubject = false,
  enableCreate = true,
  confirmSend = false, // Show confirmation dialog before sending
  manualSelectorComponent = null,
}) => {
  const [data, setData] = useState({ ...notification });
  // const [data, setData] = useState({});
  const [saving, setSaving] = useState(false);
  const [validData, setValid] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  // const { addNotification, loading } = useNotifications();
  const { isEmployee } = useUser();
  const history = useHistory();
  const checkForVolunteerView = () => {
    const useVolunteerSavedPref = localStorage.getItem(USE_VOLUNTEER_VIEW);

    if (useVolunteerSavedPref && useVolunteerSavedPref.toLowerCase() === 'true') {
      return true;
    }

    return false;
  };

  // Create list of message titles. If isReply is true add User Inquiry option
  const titles = messageTitles.filter(({ value }) => isReply || value != 'USER_INQUIRY');

  const [useVolunteerView, setUseVolunteerView] = useState(checkForVolunteerView());
  const { addMessage, loading } = useMessages({ asAdmin: isEmployee && !useVolunteerView });
  const [, setAlert] = useContext(NotificationContext);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(
    // data && data.title && (messageTitles.find(({ label }) => label == title) || { value: undefined }).value
    data && data.title && messageTitles.find(({ label }) => label == data.title)
  );
  const [extraFields, setExtraFields] = useState([]);

  // useEffect(() => {
  //   setData(notification);
  // }, [notification]);

  useEffect(() => {
    // All all custom required fields are filled in
    const extraValid = extraFields.every(({ name, required }) => {
      const attributes = data.attributes || {};
      const value = attributes[name] || '';
      return !required || value;
    });
    // Validate data
    // console.log({ data, notification });
    // const titleOk = data.title || noTitle;
    // console.log({ data, enableCreate, extraValid, titleOk });
    if (data.title && data.message && data.subject && enableCreate && extraValid) {
      setValid(true);
    } else {
      setValid(false);
    }

    if (data.title) {
      const title = messageTitles.find(({ label }) => label == data.title);
      // console.log({ data, messageTitles, title });
      if (title) {
        setSelectedTitle(title);
        // setSelectedTitle(messageTitles.find(({ label }) => label == data.title));
      }
    }
  }, [data]);

  useEffect(() => {
    // setData(notification);
    // Validate data
    // console.log({ notification });
    const parseRecipients = async () => {
      if (notification.recipients) {
        // const ids = notification.recipients.split(',');
        const ids = notification.recipients.map(({ recipient }) => recipient);
        const sr = [];
        // Fetch the details for each of the ids
        // ids.forEach(async id => {
        //   const user = await userDetails(id);
        //   sr.push(user);
        // });
        for await (const id of ids) {
          // Check if ID is a placeholder
          if (id.length < 10) {
            continue;
          }
          const user = await userDetails(id);
          sr.push(user);
        }
        setSelectedRecipients(sr);
        // console.log({ sr, ids });
      }
    };
    parseRecipients();
  }, [notification]);

  // useEffect(() => {
  //   // Validate data
  //   // console.log(JSON.stringify({ data, notification }));
  //   const { title = data.title, message = data.message } = notification;
  //   // setData({ ...data, title, message });
  // }, [notification]);

  const createNotification = useCallback(async () => {
    setSaving(true);
    try {
      const type = 'STAFF_MESSAGE';
      const result = await addMessage({ type, ...data });
      if (Array.isArray(result) && !result[0].error && result[0].createdCount > 0) {
        // toast.success(`Sent ${result.createdCount} messages.`, {
        // toast.success(`Message sent.`, {
        //   progress: false,
        //   className: 'bg-green-500 text-white',
        //   autoClose: 1500,
        //   closeButton: false,
        //   icon: () => <Icons.FaEnvelope size={18} className="text-white" />,
        // });
        if (createCallback) {
          createCallback();
        }
      } else {
        console.log('Error sending message', { result });
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'An Error occurred sending message',
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    } catch (e) {
      console.error('error', e);
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'An Error occurred sending message',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
    setSaving(false);
  }, [data, createCallback]);

  const handleSend = useCallback(async () => {
    // console.log({ confirmSend });
    if (confirmSend) {
      // Show confirmation dialog
      setShowConfirmationDialog(true);
    } else {
      // Send message
      await createNotification();
    }
  }, [confirmSend, createNotification]);

  // const selectedRecipients = data.recipients.map(recipient => ({
  //   // label: recipient.name,
  //   value: recipient,
  // }));
  // const selectedRecipients = data.recipients;
  const extraFieldsComponent = extraFields.map(({ label, name, type, required = false, formatter, unformatter }) => {
    const attributes = data.attributes || {};
    const value = attributes[name] || '';
    const id = `extra-field-${name}`;
    const formatValue = value => (formatter && typeof formatter === 'function' ? formatter(value) : value);
    const unformatValue = value => (unformatter && typeof unformatter === 'function' ? unformatter(value) : value);
    return (
      // <TextField
      //   key={name}
      //   value={data[name] || ''}
      //   onChange={e => setData({ ...data, [name]: e.target.value })}
      //   label={label}
      //   className="mb-4"
      //   type={type}
      //   required={required}
      // />
      <div key={name} className={`font-light light-primary-blue-text${type !== 'date' ? ' w-full' : ''}`}>
        {type === 'date' && (
          <>
            <div className="flex">
              <label className="light-primary-blue-text font-light mb-1 mr-1">
                {label} {required && <span className="text-pursuit-red ml-1">*</span>}
              </label>
            </div>
            <div className="flex items-center mr-4">
              <DatePicker
                title={label}
                // selected={data.attributes[name] || null}
                selected={typeof unformatter === 'function' ? unformatter(value) : value}
                // onChange={date => setDateOfBirth(date)}
                onChange={date => {
                  const attributes = data.attributes || {};
                  attributes[name] = typeof formatter === 'function' ? formatter(date) : date;
                  // return setData({ ...data, [name]: date });
                  return setData({ ...data, attributes });
                }}
                // minDate={new Date()}
                // Set min date to 30 days in the past
                // minDate={new Date(new Date().setDate(new Date().getDate() - 30))}
                // Max date is 60 days in the future
                // maxDate={new Date(new Date().setDate(new Date().getDate() + 60))}
                className="w-full h-10 px-2 textfield-bg-color"
                required={required}
              />
              <Icons.FaCalendarDay size={30} className="ml-4 cursor-pointer" />
            </div>
          </>
        )}
        {type === 'text' && (
          <TextField
            value={value}
            onChange={e => {
              const attributes = data.attributes || {};
              attributes[name] = e.target.value;
              return setData({ ...data, attributes });
            }}
            label={label}
            // className="w-full"
            // type={type}
            required={required}
          />
        )}
        {type === 'checkbox' && (
          <div className="flex items-center">
            <input
              type="checkbox"
              className="w-4 h-4 mr-2"
              checked={value}
              onChange={e => {
                const attributes = data.attributes || {};
                attributes[name] = e.target.checked;
                return setData({ ...data, attributes });
              }}
            />
            <label className="light-primary-blue-text font-light mb-1 mr-1">{label}</label>
          </div>
        )}
        {type === 'file' && (
          <>
            <div className="flex">
              <label htmlFor={id} className="light-primary-blue-text font-light mb-1 mr-1">
                {label} {required && <span className="text-pursuit-red ml-1">*</span>}
              </label>
            </div>
            <div className="flex items-center mr-4">
              {/* <div className="flex flex-auto items-center"> */}
              {/* <input
                  id={id}
                  type="file"
                  onChange={async e => {
                    // console.log({ e });
                    const attributes = data.attributes || {};
                    if (attributes[name] == undefined) {
                      attributes[name] = [];
                    }
                    attributes[name][0] = await formatValue(e.target.files[0]);
                    return setData({ ...data, attributes });
                  }}
                /> */}
              {/**
               * For each file in the array + 1 upto the maxInstances, create a file uploader
               */}
              {Array.from(Array(extraFields.find(({ name }) => name == 'fileAttachments').maxInstances).keys()).map(
                index => {
                  const attributes = data.attributes || {};
                  const file = attributes[name] && attributes[name][index];
                  // console.log({ file });
                  return (
                    <div key={index} className="flex-1 items-center mx-4">
                      <FileUploader
                        onSuccess={(key, dataUrl, meta) => {
                          // convert the data url to a file to get the filename
                          console.log({ key, dataUrl, meta });
                          const { filename } = meta;
                          const attributes = data.attributes || {};
                          if (attributes[name] == undefined) {
                            attributes[name] = [];
                          }
                          attributes[name][index] = {
                            name: filename,
                            path: key,
                          };
                          setData({ ...data, attributes });
                        }}
                        autoSave
                        saveText="UPLOAD"
                        prefix="email_attachments"
                        noPrefix
                        level="email_attachments"
                        // level="public"
                        errorMessage="You have unsuccessfully uploaded the file. Please try again."
                        onCancel={result => {
                          // Remove the file from the array
                          const attributes = data.attributes || {};
                          if (attributes[name] == undefined) {
                            attributes[name] = [];
                          }
                          attributes[name][index] = null;
                          setData({ ...data, attributes });
                          return true;
                          // console.log({ result });
                        }}
                        isUpdate={false}
                        // acceptedFileTypes="video/*"
                        // uploadObject="Video"
                      />
                    </div>
                  );
                }
              )}
              {/* </div> */}
            </div>
          </>
        )}
      </div>
    );
  });

  const fields = (
    <div className="">
      {manualSelectorComponent}
      {isEmployee && !noUserSelector && !manualSelectorComponent && (
        <div className="mb-4">
          <div className="flex">
            <p className="light-primary-blue-text font-light mb-1 mr-1">
              To <span className="text-pursuit-red ml-1">*</span>
            </p>
          </div>
          <UserSelector
            // selected={(data.recipients || '').split(',')}
            isDisabled={isReply}
            selected={selectedRecipients}
            onChange={values => {
              console.log({ values });
              // const recipients = values.map(recipient => recipient.value).join(',');
              const recipients = values.map(recipient => ({
                recipient: recipient.value,
                recipientName: recipient.meta ? recipient.meta.realName : null,
                recipientAlias: recipient.label,
              }));
              console.log({ ...data, recipients });
              setData({
                ...data,
                recipients,
              });
            }}
          />
        </div>
      )}
      {/* <TextField
        value={data.title || ''}
        onChange={e => setData({ ...data, title: e.target.value })}
        label="Title"
        className="mb-4"
        required
      /> */}

      {!noTitle && (
        <>
          <div className="mb-4">
            <div className="flex">
              <p className="light-primary-blue-text font-light mb-1 mr-1">
                Title <span className="text-pursuit-red ml-1">*</span>
              </p>
            </div>
            <Select
              // value={(messageTitles.find(({ label }) => label == data.title) || {}).value}
              isDisabled={isReply}
              value={selectedTitle}
              // options={messageTitles}
              options={titles}
              onChange={({ label, value, fields = [] }) => {
                setSelectedTitle({ label, value });
                setData({ ...data, title: label, attributes: {} });
                console.log({ fields });
                setExtraFields(fields);
              }}
            />
          </div>
          {/* Extra fields */}
          <div className="flex flex-col mb-4 w-full">{extraFieldsComponent}</div>
        </>
      )}
      {!noSubject && (
        <TextField
          value={data.subject || ''}
          readonly={isReply || subjectDisabled}
          // readonly={true}
          onChange={e => setData({ ...data, subject: e.target.value })}
          label="Subject"
          className="mb-4"
          maxLength={75}
          required
        />
      )}
      {/* Message */}
      <div>
        <div className="flex">
          <p className="light-primary-blue-text font-light mb-1 mr-1">
            Message <span className="text-pursuit-red ml-1">*</span>
          </p>
        </div>
        <textarea
          // value={notification.message !== data.message ? data.message || '' : ''}
          value={data.message || ''}
          onChange={e => {
            // console.log({ e });
            return setData({ ...data, message: e.target.value });
          }}
          label="Message"
          className="textfield-bg-color w-full h-24 px-2 mt-2 mb-4 shadow"
          required
          placeholder="Type your message here..."
        />
      </div>
    </div>
  );
  const cancelButton = (cancelLink || cancelCallback) && (
    <Link
      to={cancelLink}
      //<Button
      className="flex items-center font-semibold focus:outline-none text-guardian-darkblue h-10 uppercase"
      onClick={e => {
        e.preventDefault();
        if (typeof cancelCallback === 'function') {
          cancelCallback();
          return;
        }
        return history.goBack();
      }}
      //  >
    >
      <Icons.FaChevronLeft size={18} className="mr-4" />
      {cancelLabel}
    </Link>
  );
  // flex flex-col-reverse justify-between mt-8 text-xs lg:flex-row"
  const actions = (
    <div className="flex flex-row justify-end mt-4">
      {/* {isReply && 'test'} */}
      <div className="flex-grow">
        <span className="w-auto inline-block">{cancelButton}</span>
      </div>
      <Button
        linedBlue
        noPadding
        className="px-4 lg:ml-6 w-full lg:w-auto mr-2 uppercase"
        onClick={() => {
          setSelectedTitle(null);
          setData(notification);
          // setData(defaults);
        }}
        disabled={saving || loading}>
        {clearLabel}
      </Button>

      <Button
        solidBlue
        noPadding
        className="px-4 ml-2 w-full lg:w-auto uppercase"
        // onClick={createNotification}
        onClick={handleSend}
        loading={loading || saving}
        disabled={!validData || saving}>
        {createLabel}
      </Button>
    </div>
  );

  return (
    <div>
      {showConfirmationDialog && (
        <PopUpConfirmation
          title="Send Message"
          content={`Send message to ${data.recipients.length} recipients?`}
          // noCancel
          onConfirm={async () => {
            // Navigate to the messaging page
            setShowConfirmationDialog(false);
            await createNotification();
            // history.push('/messaging');
          }}
          onCancel={() => setShowConfirmationDialog(false)}
          // createCallback={() => setShowSentConfirmation(true)}
          confirmText={'Send'}
          confirmLoadingText={'Sending...'}
          className="w-11/12 lg:w-auto"
          destructive
        />
      )}
      {fields}
      {actions}
      {/* {JSON.stringify({ data, notification, validData, saving })} */}
    </div>
  );
};

MessageForm.propTypes = {
  notification: PropTypes.shape({
    recipients: PropTypes.arrayOf(
      PropTypes.shape({
        recipient: PropTypes.string,
        recipientAlias: PropTypes.string,
        recipientName: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  createLabel: PropTypes.string,
  createCallback: PropTypes.func,
  cancelLabel: PropTypes.string,
  clearLabel: PropTypes.string,
  cancelCallback: PropTypes.func,
  cancelLink: PropTypes.string,
  isReply: PropTypes.bool,
  noUserSelector: PropTypes.bool,
  noTitle: PropTypes.bool,
  noSubject: PropTypes.bool,
  enableCreate: PropTypes.bool,
  subjectDisabled: PropTypes.bool,
  confirmSend: PropTypes.bool,
};

export default MessageForm;
