import React from 'react';
/**
 * Returns the previous value of a given value.
 * @template T
 * @param {T} val - The current value.
 * @returns {T} The previous value.
 */
export const usePrevious = val => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = val;
  }, [val]);
  return ref.current;
};
