import React from 'react';

import { listEmployees, listLeos } from '../../../generated/graphql/queries';
import { LEOGrid, StatusPill } from '../../../components';
import { EmployeeType, UserTypes } from '../../../constants';

const statusOptions = [
  { value: UserTypes.LEO, label: 'Active' },
  { value: 'DISABLED_LEO', label: 'Disabled' },
];

const LEOUsers = () => {
  const renderStaffType = ({ userStatus }) => (
    <StatusPill
      type="leo"
      status={userStatus === 'DISABLED_LEO' ? 'DISABLED' : userStatus === 'LEO' ? 'ACTIVE' : userStatus}
    />
  );

  const statusColumn = { title: 'Status', fieldName: 'userStatus', renderFunction: renderStaffType, sortable: false };

  return (
    <LEOGrid
      query={listLeos}
      queryName="listLeos"
      statusOptions={statusOptions}
      title="LEO Users"
      statusColumn={statusColumn}
      statusFilterLabel="Status"
      createUserDefaultType={UserTypes.Staff}
    />
  );
};

export default LEOUsers;
