import React, { useState, useEffect } from 'react';
import { formatPhoneNumber } from './phoneFieldHelper';

const PhoneField = ({ className, placeholder, value, setState }) => {
  const [input, setInput] = useState(value);

  useEffect(() => {
    if (input !== value) setState(input);
  }, [input, setState, value]);

  function handleFormatting(e) {
    const formattedNumber = formatPhoneNumber(e.target.value);

    setInput(formattedNumber);
  }

  return (
    <input
      type="text"
      className={className}
      placeholder={placeholder}
      value={input}
      onChange={e => handleFormatting(e)}
    />
  );
};

export default PhoneField;
