/**
 * Custom date display and formatter
 */
import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
/**
 * Date Formatter
 * @param {Object} props - React props
 * @param {Date} props.date - Date to format
 * @param {String} props.format - Format of date
 * @param {String} props.defaultReturn - Default display if no date
 * @returns {JSX.Element} - The component
 */

const DateDisplay = ({ date, defaultReturn = '-', format = 'DATE_SHORT' }) => {
  if (!date) {
    return defaultReturn;
  }
  const dt = DateTime.fromJSDate(new Date(date));
  const title = dt.toLocaleString(DateTime.DATETIME_FULL);
  const formattedDate = dt.toLocaleString(DateTime[format]);
  return (
    <>
      <span title={title}>{formattedDate}</span>
    </>
  );
};

DateDisplay.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  defaultReturn: PropTypes.string,
  format: PropTypes.string,
};

export default DateDisplay;
