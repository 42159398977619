import React from 'react';
import * as FaIcons from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { P1591Icon } from '../P1591Icon';

const SideBarData = ({ openIntroVideo, openRulesModal, isAdmin, handleShareQRCodeModal }) => {
  const volunteerLinks = [
    {
      title: 'My Cases',
      path: '/',
      icon: <FaIcons.FaBriefcase size={20} />,
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: <FaIcons.FaCog size={20} />,
    },
    {
      title: 'New Case',
      path: '/investigation/new',
      icon: <FaIcons.FaPlusCircle size={20} />,
      newTab: true,
    },
    {
      title: 'Leaderboard',
      path: '/leaderboard',
      icon: <FaIcons.FaChartArea size={20} />,
    },
    {
      title: 'Knowledge Base',
      path: '/knowledge',
      icon: <FaIcons.FaLightbulb size={20} />,
    },
    {
      title: 'Messaging',
      path: '/messaging',
      icon: <FaIcons.FaEnvelope size={20} />,
    },
    {
      title: 'Send Feedback',
      path: '#',
      icon: <FaIcons.FaQuestionCircle size={20} />,
      onClick: e => {
        window.location = 'mailto:project1591@guardiangroup.org?subject=Project 1591 App Feedback';
        e.preventDefault();
      },
    },
    {
      title: 'Rules/Guidelines',
      path: '#',
      icon: <FaIcons.FaHandshake size={20} />,
      onClick: openRulesModal,
    },
    {
      title: 'Intro Video',
      path: '#',
      icon: <FaIcons.FaPlayCircle size={20} />,
      onClick: openIntroVideo,
    },
  ];

  const employeeLinks = [
    {
      title: isAdmin ? 'Cases' : 'My Cases',
      path: '/',
      icon: <FaIcons.FaBriefcase size={20} />,
    },
    {
      title: 'Volunteers',
      path: '/volunteers',
      icon: <FaIcons.FaUsers size={20} />,
    },
    {
      title: 'Settings',
      path: '/settings',
      icon: <FaIcons.FaCog size={20} />,
    },
    {
      title: 'Leaderboard',
      path: '/leaderboard',
      icon: <FaIcons.FaChartArea size={20} />,
    },
    {
      title: 'Knowledge Base',
      path: '/knowledge',
      icon: <FaIcons.FaLightbulb size={20} />,
    },
  ];
  if (process.env.ENABLE_VOLUNTEER_MESSAGING === 'true') {
    employeeLinks.push({
      title: 'Messaging',
      path: '/messaging',
      icon: <FaIcons.FaEnvelope size={20} />,
    });
  }

  const adminLinks = [
    {
      title: 'Messaging',
      path: '/messaging',
      icon: <FaIcons.FaEnvelope size={20} />,
    },
    {
      title: 'Admin',
      path: '/admin/staff',
      icon: <FaIcons.FaUserCog size={20} />,
    },
    // {
    //   title: 'LEO',
    //   path: '/admin/leo',
    //   // icon: <FontAwesomeIcon icon={icon({ name: 'badge-sheriff', style: 'regular' })} />,
    //   icon: <FontAwesomeIcon icon={P1591Icon} size="1x" />,
    // },
    // {
    //   title: 'LEO',
    //   path: '/admin/leo',
    //   icon: <FontAwesomeIcon icon={icon({ name: 'badge-sheriff', style: 'regular' })} />,
    // },
    {
      title: 'LEO',
      path: '/admin/leo',
      icon: <FontAwesomeIcon icon={icon({ name: 'user-police', style: 'solid' })} />,
    },
    {
      title: 'Search',
      path: '/admin/search',
      icon: <FontAwesomeIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />,
    },
    // {
    //   title: 'LEO',
    //   path: '/admin/leo',
    //   icon: <FontAwesomeIcon icon={icon({ name: 'building-shield', style: 'solid' })} />,
    // },
    // {
    //   title: 'LEO',
    //   path: '/admin/leo',
    //   icon: <FontAwesomeIcon icon={icon({ name: 'building-shield', style: 'regular' })} />,
    // },
    // {
    //   title: 'LEO',
    //   path: '/admin/leo',
    //   icon: <FontAwesomeIcon icon={icon({ name: 'id-badge', style: 'regular' })} />,
    // },
    // {
    //   title: 'Share MFA Creds',
    //   path: '#',
    //   icon: <FaIcons.FaQrcode size={20} />,
    //   onClick: handleShareQRCodeModal,
    // },
  ];

  return { volunteerLinks, employeeLinks, adminLinks };
};

export default SideBarData;
