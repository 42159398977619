const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_region": "us-west-2",
  "aws_appsync_region": "us-west-2",
  "aws_user_files_s3_bucket_region": "us-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_login_mechanisms": [
    "PREFERRED_USERNAME"
  ],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_cognito_identity_pool_id": "us-west-2:2d3ee422-9518-43cb-8e6d-3be6cf42fc7f",
  "aws_user_pools_id": "us-west-2_tSeggNUqv",
  "aws_user_pools_web_client_id": "h4bjsivhpmodnia3nr1120v15",
  "aws_appsync_graphqlEndpoint": "https://27otzvn3vjgb7f6y5chabuiila.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_user_files_s3_bucket": "pursuit-storage-production"
}; 

export default awsmobile;