import React from 'react';
import moment from 'moment';

const year = moment().format('YYYY');

const Footer = () => (
  <footer className="w-full py-6 pl-8 bg-pursuit-gray">
    <div className="items-center w-full h-full font-medium lg:flex lg:justify-between">
      <div className="flex flex-col">
        <div className="text-white header-text-font lg:text-3xl ">
          PURSUIT<span className="mx-1 text-xs align-top lg:text-base">®</span>
        </div>
        <div className="text-sm text-white header-text-font lg:text-2xl ">
          <p className="inline font-bold text-white"> P</p>eople
          <p className="inline font-bold text-white"> U</p>nited
          <p className="inline font-bold text-white"> R</p>esponsibly
          <p className="inline font-bold text-white"> S</p>uppressing
          <p className="inline font-bold text-white"> U</p>nderage
          <p className="inline font-bold text-white"> I</p>llicit
          <p className="inline font-bold text-white"> T</p>rafficking
        </div>
      </div>

      <div className="block my-auto mt-6 font-semibold text-white lg:mt-0 lg:mr-8 lg:text-right standard-text-font">
        <p className="mb-1">Copyright {year} &copy;Guardian Group</p>
        <p className="mb-1">
          <a href="/terms-of-use" className="hover:underline">
            Terms of Use
          </a>
          &nbsp; | &nbsp;
          <a href="/privacy" className="hover:underline">
            Privacy Policy
          </a>
        </p>
        <a href="https://www.GuardianGroup.org" className="hover:underline">
          www.GuardianGroup.org
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
