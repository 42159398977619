import React, { Component } from 'react';
import Container from '../../../components/Container';
import HeaderText from '../../../components/HeaderText';
import PrivacyPolicy from './privacyPolicy';

class Privacy extends Component {
  render() {
    return (
      <Container height="h-168" width="w-160" padding="px-20 py-8" className="flex flex-col" margin="mx-auto">
        <center>
          <HeaderText noBold fontSize="text-4xl" className="mt-2pr mb-4">
            Privacy Policy
          </HeaderText>
        </center>
        <br />
        <p className="overflow-y-auto">
          <PrivacyPolicy />
        </p>
        <br />
      </Container>
    );
  }
}

export default Privacy;
