import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, HeaderText } from '../../../components';

class RequiredTraining extends Component {
  render() {
    return (
      <Container
        grayedBackground
        height="lg:h-168"
        width="lg:w-160"
        padding="p-4 md:px-10 md:py-8 lg:px-20"
        className="flex flex-col"
        margin="mx-3 md:mx-12 lg:mx-auto"
      >
        <center>
          <HeaderText noBold fontSize="text-4xl" className="mt-2 mb-4">
            Required Training
          </HeaderText>
        </center>

        <br />
        <div className="overflow-y-auto mb-4 px-1">
          <p>Welcome!</p>

          <br />

          <p>
            Thanks for signing up and wanting to join Guardian Group in our efforts to counter Commercial Sexual
            Exploitation of Children (CSEC) here in the United States. Yes, you the volunteer can help us! We believe
            that through a coordinated collaborative partnership of people with varying skills, motivations and desires,
            we can have a greater impact as a collective group. This is your opportunity to contribute to a problem that
            exists in your own city and state. You can have a life-changing impact on the lives of our most vulnerable
            population.
          </p>

          <br />

          <p>
            Before allowing you access to this site, you must first complete the “PURSUIT® - Volunteer Training &
            Assessment” course at our PURSUIT® Group Academy. A certificate of completion will only be given to those
            that have successfully completed 3 practical exams with a passing score. Once complete and with certificate
            in hand, return here to upload your certificate for access approval.
          </p>

          <br />

          <p>
            <strong>Important:</strong> When creating your user account at PURSUIT® Group Academy, ensure you use the
            same credentials you used in creating this account. This is how we will match up the two accounts across two
            different platforms.
          </p>
          <br />
          <p>Thanks. We look forward to you returning with your certificate.</p>
        </div>
        <Link to="#" className="link-signup">
          <Button
            solidBlue
            className="btton-signup w-full"
            onClick={e => {
              window.location = 'https://courses.pursuitgroupacademy.us/courses/pursuit-volunteer-training-assessment';
              e.preventDefault();
            }}
          >
            <span className="m-0 p-0 hidden md:inline">START PURSUIT –&nbsp;</span>VOLUNTEER TRAINING & ASSESSMENT
            <span className="m-0 p-0 hidden md:inline">&nbsp;COURSE</span>
          </Button>
        </Link>

        <Link to="/certificate-upload" className="link-signup mt-4 mb-2">
          <Button linedBlue className="btton-signup w-full">
            ALREADY FINISHED YOUR TRAINING?
          </Button>
        </Link>
      </Container>
    );
  }
}

export default RequiredTraining;
