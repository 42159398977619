import { webSourceEscortSiteOptions, webSourceFieldLabels, webSourceFieldPlaceholders } from './webSourceFields';

export function readableFormat(fieldName) {
  if (!fieldName) {
    return fieldName;
  }

  return (
    fieldName
      .split(/(?=[A-Z])/)
      .join(' ')
      // capitalize first letter of first word
      .replace(/\w\S*/g, word => word.charAt(0).toUpperCase() + word.substr(1))
  );
}

export const webSourceFieldLabel = (field, source, hideDetails = false) => {
  const label = webSourceFieldLabels[`${source}_${field}`] || webSourceFieldLabels[field] || readableFormat(field);
  if (hideDetails) {
    // If hide details is true, remove the details from the label. Remove anything between [].
    return label.replace(/\s\[.*\]/, '');
  } else {
    return label;
  }
};
export const webSourceFieldPlaceholder = field => webSourceFieldPlaceholders[field] || readableFormat(field);

export const fieldValue = (uniqueFieldKey, keyValue) => {
  if (!uniqueFieldKey) {
    return keyValue;
  }

  if (uniqueFieldKey === 'escortSiteTitle') {
    const escortSiteOption = webSourceEscortSiteOptions.find(({ value }) => value === keyValue);

    if (escortSiteOption) {
      return escortSiteOption.label;
    }
  }

  if (keyValue && ['adDate', 'dateOfBirth', 'date'].includes(uniqueFieldKey)) {
    const date = new Date();
    // const date = new Date(keyValue);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
      // hour12: true, // Use 12-hour format
    };
    const dateParts = keyValue.split('-');
    date.setFullYear(dateParts[0]);
    date.setMonth(dateParts[1] - 1);
    date.setDate(dateParts[2]);

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
    // return new Date(keyValue).toLocaleDateString();
  }

  // Default
  return keyValue;
};

export const usesOldEscortAdFormat = record => {
  const { escortAd } = record;

  if (!escortAd) {
    return false;
  }
  const escortAdData = [escortAd.url, ...escortAd.phones.map(({ phone }) => phone), escortAd.description];
  const escortAdEmpty = escortAdData.filter(Boolean).length === 0;

  // console.log({ escortAdEmpty, escortAdData });

  // If all fields are empty, it's the old format
  return !escortAdEmpty;
};

export const sortWebSourceFields = order => {
  // if (!order) {
  //   return fields;
  // }

  return ([a], [b]) => {
    const aIndex = order.indexOf(a);
    const bIndex = order.indexOf(b);

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  };
};
