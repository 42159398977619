import React from 'react';
import * as Icons from 'react-icons/fa';
import ToolTip from '../ToolTip';

const TextField = props => {
  const {
    fieldClassName = '',
    className = '',
    fieldWidth = '',
    fieldHeight = 'h-10',
    fieldPadding = 'px-3',
    width = 'w-full',
    height = '',
    noRounded,
    noFullFieldWidth,
    label,
    info,
    infoTitle,
    id,
    type,
    value,
    checked,
    onChange,
    placeHolder,
    readonly,
    required,
    tabindex,
    maxLength
  } = props;

  return (
    <div className={[width, height, className].join(' ')}>
      <div className="flex">
        {label ? (
          <p className="light-primary-blue-text font-light mb-1 mr-1">
            {label} {required && <span className="text-pursuit-red ml-1">*</span>}
          </p>
        ) : null}

        {info ? <ToolTip title={infoTitle} tooltipText={info} className="light-primary-blue-text font-light" /> : null}
      </div>

      <div className="flex flex-row items-center textfield-bg-color">
        <input
          id={id}
          readOnly={readonly}
          type={type}
          value={value}
          checked={checked}
          onChange={onChange}
          tabIndex={tabindex}
          maxLength={maxLength}
          placeholder={placeHolder}
          className={`textfield-bg-color ${readonly ? 'cursor-not-allowed' : ''} ${
            noFullFieldWidth ? '' : 'w-full'
          } ${noRounded} ${fieldHeight} ${fieldWidth} ${fieldPadding} ${fieldClassName} ${
            readonly ? 'focus:outline-none' : ''
          }`}
        />

        <div className={`${!readonly ? 'hidden' : ''}`}>
          <Icons.FaLock size={16} className="mr-2" />
        </div>
      </div>
    </div>
  );
};

export default TextField;
