import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import states from 'states-us';
import * as Icons from 'react-icons/fa';

import { Container, HeaderText, Spinner, Button, DropDown, StatusPill, PopUpConfirmation } from '../../../components';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import {
  approveUser,
  enableUser,
  disableUser,
  updateUserType,
  updateUserRecord,
} from '../../../generated/graphql/mutations';
import { getLeoUserById } from '../../../generated/graphql/queries';
import { VolunteerStatus, UserTypes, EmployeeType } from '../../../constants';
import { useUser } from '../../../contexts/userContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const UserDetails = () => {
  const { user: signedInUser, isAdmin } = useUser();
  const [, setAlert] = useContext(NotificationContext);

  const { userId } = useParams();

  const [user, setUser] = useState();
  const [userType, setUserType] = useState({ label: 'Volunteer', value: UserTypes.Volunteer });

  const [loading, setLoading] = useState();
  const [approving, setApproving] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [showConfirmEnable, setShowConfirmEnable] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [changingType, setChangingType] = useState(false);
  const [disabledReason, setDisabledReason] = useState(null);
  const [enableEditing, setEnableEditing] = useState(false);

  // const userTypeOptions = [
  //   { label: 'Admin', value: UserTypes.Admin },
  //   { label: 'Staff', value: UserTypes.Staff },
  //   { label: 'Volunteer', value: UserTypes.Volunteer },
  // ];

  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);

        const {
          data: { getLeoUserById: returnedUser = null },
        } = await API.graphql(
          graphqlOperation(getLeoUserById, {
            id: userId,
          })
        );

        if (!returnedUser) {
          setAlert({
            type: 'SET_NOTIFICATION',
            payload: {
              occurs: true,
              message: 'User not found',
              textColor: 'redText',
              borderColor: 'redBorder',
            },
          });
        }

        setUser(returnedUser);
        // setUserType(getUserType(returnedUser.userStatus));
      } catch (error) {
        console.error('Error fetching user by id:', error);

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'Error fetching user details',
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });

        setUser(null);
      }

      setLoading(false);
    };

    getUser(userId);
  }, [userId, setAlert]);

  const parseState = stateCode => {
    if (!stateCode) {
      return '-';
    }

    const matchingState = states.find(x => x.abbreviation === stateCode);

    if (matchingState) {
      return matchingState.name;
    }

    return stateCode;
  };

  const parseDate = date => {
    if (!date) {
      return '-';
    }

    const luxonDate = DateTime.fromISO(date);

    if (!luxonDate.isValid) {
      return 'Invalid Date';
    }

    return luxonDate.toLocaleString();
  };

  const getCertificate = async () => {
    const key = user.certificatePath;
    const { identityId } = user;

    const fileUrl = await Storage.get(key, { level: 'protected', identityId });

    window.open(fileUrl, '_blank');
  };

  const onApproveUser = async () => {
    try {
      setApproving(true);

      const {
        data: { approveUser: returnedUser = null },
      } = await API.graphql(
        graphqlOperation(approveUser, {
          id: userId,
        })
      );

      if (!returnedUser) {
        throw new Error('No user returned');
      }

      toast.success('User Approved', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });

      setUser(returnedUser);
    } catch (error) {
      console.error('Error approving user:', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error approving user',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setApproving(false);
  };

  const onEnableUser = async () => {
    try {
      setEnabling(true);

      const {
        data: { enableUser: returnedUser = null },
      } = await API.graphql(
        graphqlOperation(enableUser, {
          id: userId,
        })
      );

      if (!returnedUser) {
        throw new Error('No user returned');
      }

      toast.success('Enabled User', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });

      setUser(returnedUser);
      setShowConfirmEnable(false);
    } catch (error) {
      console.error('Error enabling user:', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error enabling user',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setEnabling(false);
  };

  const onDisableUser = useCallback(async () => {
    try {
      setDisabling(true);

      const {
        data: { disableUser: returnedUser = null },
      } = await API.graphql(
        graphqlOperation(disableUser, {
          id: userId,
          disableReason: disabledReason,
        })
      );

      if (!returnedUser) {
        throw new Error('No user returned');
      }

      toast.success('Disabled User', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });

      setUser(returnedUser);
      setShowConfirmDisable(false);
    } catch (error) {
      console.error('Error disabling user:', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error disabling user',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setDisabling(false);
  }, [userId, disabledReason]);

  // const getUserType = userStatus => {
  //   let type = null;

  //   if (userStatus === EmployeeType.Admin || userStatus === EmployeeType.Staff) {
  //     type = userTypeOptions.find(x => x.value === userStatus);
  //   } else {
  //     type = userTypeOptions.find(x => x.value === UserTypes.Volunteer);
  //   }

  //   return type;
  // };

  const onUpdateUserType = async () => {
    const selectedType = userType?.value;

    try {
      setChangingType(true);

      if (user?.userStatus === 'DISABLED') {
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: "Error - Can't change user type of a disabled user",
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });

        setChangingType(false);

        return;
      }

      const {
        data: { updateUserType: returnedUser = null },
      } = await API.graphql(
        graphqlOperation(updateUserType, {
          id: userId,
          type: selectedType,
        })
      );

      if (!returnedUser) {
        throw new Error('No user returned');
      }

      toast.success('User Type Updated', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });

      setUser(returnedUser);
    } catch (error) {
      console.error('Error updating user type:', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error updating user type',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setChangingType(false);
  };

  // const redirectToMessageUser = useCallback(() => {
  //   history.push(`/messages/create?userIds=${userId}`);
  // }, [history, userId]);

  const disabledReasonInput = (
    <textarea
      value={disabledReason}
      onChange={e => setDisabledReason(e.target.value)}
      placeholder="Reason for disabling"
      className="w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
    />
  );

  const disabledContent = (
    <div className="flex flex-col">
      <p className="text-pursuit-gray mb-8">
        Are you sure you want to disable {user?.firstName && user.firstName} {user?.lastName && user.lastName}
        {!user?.firstName && !user?.lastName ? 'this user' : ''}?
      </p>
      {disabledReasonInput}
    </div>
  );

  const handleEditSave = async () => {
    console.log('save');
    setEnableEditing(false);
    console.log(user);
    const attributesToSave = ['firstName', 'lastName', 'agency', 'department', 'position', 'city', 'zip', 'state'];
    const userDoc = attributesToSave.reduce((acc, key) => {
      if ((user[key] && user[key] !== '-') || '' === user[key]) {
        acc[key] = user[key];
      }
      return acc;
    }, {});
    // console.log({ userDoc });
    try {

    const { data } = await API.graphql(graphqlOperation(updateUserRecord, { id: user.id, input: userDoc }));
    // console.log( data.updateUserRecord);
    if (data.updateUserRecord && data.updateUserRecord.id) {
      setUser(data.updateUserRecord);
      toast.success('User details updated successfully', {
        progress: false,
        className: 'bg-green-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaCheck size={18} className="text-white" />,
      });
    } else {
      toast.error('Error updating user details', {
        progress: false,
        className: 'bg-red-500 text-white',
        autoClose: 1500,
        closeButton: false,
        icon: () => <Icons.FaTimes size={18} className="text-white" />,
      });
    }
    } catch (error) {
      console.error('Error updating user details:', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error updating user details',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
  };

  const editButton = !enableEditing ? (
    <FontAwesomeIcon
      size="xl"
      icon={icon({ name: 'edit', style: 'solid' })}
      onClick={() => setEnableEditing(true)}
      //  className="text-pursuit-red"
    />
  ) : (
    // <Button solidBlue noPadding className="px-4 w-full lg:w-auto" onClick={() => setEnableEditing(!enableEditing)}>
    //   SAVE
    // </Button>
    <Button linedBlue noPadding className="px-4 w-full lg:w-auto uppercase" onClick={() => handleEditSave()}>
      Save
    </Button>
  );
  // <FontAwesomeIcon
  //   icon={icon({ name: 'edit', style: 'solid' })}
  //   //  className="text-pursuit-red"
  // />

  return (
    <>
      {showConfirmDisable && (
        <PopUpConfirmation
          title="Disable User"
          // content={`Are you sure you want to disable ${user?.firstName && user.firstName} ${user?.lastName &&
          //   user.lastName}${!user?.firstName && !user?.lastName ? 'this user' : ''}?`}
          content={disabledContent}
          onCancel={() => setShowConfirmDisable(false)}
          onConfirm={() => onDisableUser()}
          confirmText="DISABLE"
          confirmLoading={disabling}
          confirmDisabled={!disabledReason}
          confirmLoadingText="DISABLING"
          destructive
          className="w-11/12 lg:w-auto"
        />
      )}

      {showConfirmEnable && (
        <PopUpConfirmation
          title="Enable User"
          content={`Are you sure you want to re-enable ${user?.firstName && user.firstName} ${user?.lastName &&
            user.lastName}${!user?.firstName && !user?.lastName ? 'this user' : ''}?`}
          onCancel={() => setShowConfirmEnable(false)}
          onConfirm={() => onEnableUser()}
          confirmText="ENABLE"
          confirmLoading={enabling}
          confirmLoadingText="ENABLING"
          className="w-11/12 lg:w-auto"
        />
      )}
      <Container
        height="lg:min-h-104"
        width="lg:w-4/5"
        padding="p-4 md:px-10 md:py-8 lg:px-20"
        className="flex flex-col"
        margin="mx-3 md:mx-12 lg:mx-auto mt-4">
        <div
          className="flex flex-row gap-x-6 items-center mb-12
         ">
          <HeaderText fontSize="text-4xl" mb={'mb-noclass'} className={''}>
            LEO User Details
          </HeaderText>
          <div>{editButton}</div>
        </div>

        {loading ? (
          <div className="w-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div>
            <div className="flex flex-col-reverse lg:flex-row">
              <div className="w-full lg:mr-4 lg:border-r border-gray-200">
                <div className="flex flex-col">
                  <div className="flex flex-row w-full">
                    <div className="flex flex-col w-1/2">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">First Name</p>
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <input
                            type="text"
                            value={user?.firstName}
                            onChange={e => setUser({ ...user, firstName: e.target.value })}
                            // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                            className="textfield-bg-color  w-full h-10  px-3"
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{user?.firstName || '-'}</p>
                      )}
                      {/* <p className="text-pursuit-gray mb-8">{user?.firstName || '-'}</p> */}
                    </div>

                    <div className="flex flex-col w-1/2">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">Last Name</p>
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <input
                            type="text"
                            value={user?.lastName}
                            onChange={e => setUser({ ...user, lastName: e.target.value })}
                            // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                            className="textfield-bg-color  w-full h-10  px-3"
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{user?.lastName || '-'}</p>
                      )}
                      {/* <p className="text-pursuit-gray mb-8">{user?.lastName || '-'}</p> */}
                    </div>
                  </div>

                  <div className="flex flex-row w-full">
                    <div className="flex flex-col w-1/2">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">Agency</p>
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <input
                            type="text"
                            value={user?.agency === '-' ? '' : user?.agency}
                            onChange={e => setUser({ ...user, agency: e.target.value })}
                            // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                            className="textfield-bg-color  w-full h-10  px-3"
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{user?.agency || '-'}</p>
                      )}
                    </div>

                    <div className="flex flex-col w-1/2">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">Department</p>
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <input
                            type="text"
                            value={user?.department === '-' ? '' : user?.department}
                            onChange={e => setUser({ ...user, department: e.target.value })}
                            // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                            className="textfield-bg-color  w-full h-10  px-3"
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{user?.department || '-'}</p>
                      )}
                      {/* <p className="text-pursuit-gray mb-8">{user?.department || '-'}</p> */}
                    </div>
                  </div>

                  <p className="light-primary-blue-text font-semibold mb-1 mr-1">Position</p>
                  {enableEditing ? (
                    <div className="flex flex-col w-full pr-4">
                      <input
                        type="text"
                        value={user?.position === '-' ? '' : user?.position}
                        onChange={e => setUser({ ...user, position: e.target.value })}
                        // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                        className="textfield-bg-color  w-full h-10  px-3"
                      />
                    </div>
                  ) : (
                    <p className="text-pursuit-gray mb-8">{user?.position || '-'}</p>
                  )}
                  {/* <p className="text-pursuit-gray mb-8">{user?.position || '-'}</p> */}

                  {/* <p className="light-primary-blue-text font-semibold mb-1 mr-1">Heard About Us</p>
                  <p className="text-pursuit-gray mb-8">{user?.heardAboutUs || '-'}</p>

                  <p className="light-primary-blue-text font-semibold mb-1 mr-1">Date of Birth</p>
                  <p className="text-pursuit-gray mb-8">{user?.dateOfBirth || '-'}</p> */}
                </div>
              </div>

              <div className="w-full lg:pl-16">
                {/* <div className="flex flex-row">
                  <div className="flex flex-col w-1/2">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Alias</p>
                    <p className="text-pursuit-gray mb-8">{user?.alias || '-'}</p>
                  </div>

                  <div className="flex flex-col w-1/2">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Status</p>
                    <StatusPill status={user?.userStatus} />
                  </div>
                </div> */}

                <p className="light-primary-blue-text font-semibold mb-1 mr-1">Email</p>
                <div className="mb-8">
                  <a href={`mailto:${user?.email}`} className="text-guardian-blue">
                    {user?.email || '-'}
                  </a>
                </div>

                {user?.country === 'United States' && (
                  <div className="flex flex-row">
                    <div className="flex flex-col w-1/3">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">City</p>
                      {/* <p className="text-pursuit-gray mb-8">{user?.city || '-'}</p> */}
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <input
                            type="text"
                            value={user?.city === '-' ? '' : user?.city}
                            onChange={e => setUser({ ...user, city: e.target.value })}
                            // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                            className="textfield-bg-color  w-full h-10  px-3"
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{user?.city || '-'}</p>
                      )}
                    </div>
                    <div className="flex flex-col w-1/3">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">State</p>
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <DropDown
                            // value={user?.state}
                            value={{
                              label: parseState(user?.state),
                              value: user?.state,
                            }}
                            onChange={async option => {
                              // console.log({ option });
                              setUser({ ...user, state: option.value });
                            }}
                            width="w-full"
                            containerClassName="mr-2"
                            options={states.map(state => ({ label: state.name, value: state.abbreviation }))}
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{parseState(user?.state)}</p>
                      )}
                    </div>

                    <div className="flex flex-col w-1/3">
                      <p className="light-primary-blue-text font-semibold mb-1 mr-1">Zip</p>
                      {/* <p className="text-pursuit-gray mb-8">{user?.zip || '-'}</p> */}
                      {enableEditing ? (
                        <div className="flex flex-col w-full pr-4">
                          <input
                            type="text"
                            value={user?.zip === '-' ? '' : user?.zip}
                            onChange={e => setUser({ ...user, zip: e.target.value })}
                            // className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-guardian-blue"
                            className="textfield-bg-color  w-full h-10  px-3"
                          />
                        </div>
                      ) : (
                        <p className="text-pursuit-gray mb-8">{user?.zip || '-'}</p>
                      )}
                    </div>
                  </div>
                )}

                <div className="flex flex-row">
                  <div className="flex flex-col w-1/3">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Created</p>
                    <p className="text-pursuit-gray mb-8">{parseDate(user?.createdAt)}</p>
                  </div>

                  <div className="flex flex-col w-1/3">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Downloads</p>
                    <p className="text-pursuit-gray mb-8">{user?.downloads || '-'}</p>
                  </div>

                  <div className="flex flex-col w-1/3">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Last Download</p>
                    <p className="text-pursuit-gray mb-8">{parseDate(user?.lastDownloadedAt)}</p>
                  </div>
                </div>

                {user?.certificatePath && (
                  <div className="mb-8">
                    <Button
                      onClick={() => getCertificate()}
                      className="text-guardian-blue underline focus:outline-none">
                      View Certificate
                    </Button>
                  </div>
                )}

                {`${user?.userStatus}`.includes('DISABLED') && (
                  <div className="flex flex-col">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Disabled Reason</p>
                    <p className="text-pursuit-gray mb-8">{user?.disabledReason || '-'}</p>
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">Disabled at</p>
                    <p className="text-pursuit-gray mb-8"> {user?.disabledAt || '-'} </p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between">
              {isAdmin && user?.id !== signedInUser?.id && (
                <div className="flex flew-row items-end">
                  {/* <div className="flex flex-col w-full lg:w-auto">
                    <p className="light-primary-blue-text font-semibold mb-1 mr-1">User Type</p>
                    <DropDown
                      value={userType}
                      onChange={option => setUserType(option)}
                      width="w-full lg:w-64"
                      containerClassName="mr-2"
                      options={userTypeOptions}
                    />
                  </div>

                  <Button solidBlue noPadding className="px-4 ml-6" onClick={onUpdateUserType} loading={changingType}>
                    {changingType ? 'SAVING' : 'SAVE'}
                  </Button> */}
                </div>
              )}

              {user?.id !== signedInUser?.id && (
                <div className="flex justify-end mt-8 w-full lg:w-auto">
                  {/* User notification button */}
                  <Link to={`/messaging/create?recipients=${userId}`}>
                    <Button
                      linedBlue
                      noPadding
                      className="px-4 w-full lg:w-auto uppercase"
                      // onClick={() => setShowNotificationModal(true)}
                      disabled>
                      Message
                    </Button>
                  </Link>

                  {!`${user?.userStatus}`.includes(VolunteerStatus.Disabled) ? (
                    <Button
                      linedRed
                      noPadding
                      className="px-4 lg:ml-6 w-full lg:w-auto"
                      onClick={() => setShowConfirmDisable(true)}>
                      DISABLE
                    </Button>
                  ) : (
                    <Button
                      linedBlue
                      noPadding
                      className="px-4 lg:ml-6 w-full lg:w-auto"
                      onClick={() => setShowConfirmEnable(true)}>
                      ENABLE
                    </Button>
                  )}

                  {user?.userStatus === VolunteerStatus.Pending && (
                    <Button
                      solidBlue
                      noPadding
                      className="px-4 lg:ml-6 w-full lg:w-auto"
                      onClick={onApproveUser}
                      loading={approving}>
                      {approving ? 'APPROVING' : 'APPROVE'}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default UserDetails;
