import React, { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import HeaderText from '../../../components/HeaderText';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import Container from '../../../components/Container';
import { passwordValidator, confirmPasswordValidator } from '../../../helpers/createAccount/fieldValidation.js';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [renderedCode, setRenderedCode] = useState(false);
  const [, setAlert] = useContext(NotificationContext);
  const history = useHistory();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/forgot-password' });
  }, []);

  const sendCode = async () => {
    if (!email) {
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Please enter an email',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    } else {
      try {
        await Auth.forgotPassword(email);

        setRenderedCode(true);
      } catch (error) {
        let errorMessage = '';

        console.error('Error sending code: ', error);

        let redirect = null;

        if (error.message === 'User password cannot be reset in the current state.') {
          errorMessage = 'Please attempt to login with password provided in the invitation email';
        } else if (error.message === 'Attempt limit exceeded, please try after some time.') {
          errorMessage = 'Too many attempts, please wait a few minutes before trying again';
        } else if (
          error.message ===
          'Cannot reset password for the user as there is no registered/verified email or phone_number'
        ) {
          // User has not verified their email and needs to be sent a new verification code.
          // Update the message to let user know and send them a new verification code.

          errorMessage = 'Please verify your email before resetting your password';
          try {
            await Auth.resendSignUp(email);
            redirect = 'login';
          } catch (error) {
            if (error.message === 'User is already confirmed.') {
              errorMessage = 'Please confirm your email before resetting your password';
              const verifyResponse = await Auth.verifyCurrentUserAttribute('email');
              console.log({ verifyResponse });
              redirect = 'login';
            }
            console.error('Error resending signup: ', error);
            // return;
          }
          // history.push('login', { origin: 'createAccount', password, email });
        } else {
          errorMessage = 'Please contact an admin for support to have your password reset';
        }

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: errorMessage,
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
        if (redirect) {
          history.push(redirect, { origin: 'createAccount', email });
        }
      }
    }
  };

  const resetPassword = async () => {
    if (passwordValidator(setAlert, newPassword) && confirmPasswordValidator(setAlert, confirmPassword, newPassword)) {
      try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);

        setRenderedCode(false);
        setEmail('');

        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: 'Your password was successfully changed!',
            textColor: 'greenText',
            borderColor: 'greenBorder',
          },
        });

        history.push('/login');
      } catch (error) {
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: error.message,
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    }
  };

  const renderCode = () => {
    if (renderedCode) {
      return (
        <>
          <TextField
            type="code"
            onChange={e => setCode(e.target.value)}
            placeHolder="Code"
            label="Confirmation Code"
            className="mb-4"
          />

          <TextField
            type="password"
            onChange={e => setNewPassword(e.target.value)}
            placeHolder="New Password"
            label="New Password"
            className="mb-4"
          />

          <TextField
            type="password"
            onChange={e => setConfirmPassword(e.target.value)}
            placeHolder="Confirm New Password"
            label="Confirm New Password"
            className="mb-4"
          />

          <Button solidBlue className="mt-4" onClick={resetPassword}>
            SUBMIT
          </Button>

          <div className="flex justify-end mt-4">
            <Button anchorTag onCLick={sendCode}>
              Resend Code
            </Button>
          </div>
        </>
      );
    }
  };

  const renderEmail = () => {
    if (!renderedCode) {
      return (
        <>
          <TextField type="Email" placeHolder="Email" label="Email" onChange={e => setEmail(e.target.value)} />
          <Button solidBlue className="mt-4" onClick={sendCode}>
            SEND CODE
          </Button>
          <div className="flex justify-between mt-4">
            <Button
              anchorTag
              onClick={() => {
                history.push('create-account');
              }}>
              Create Account
            </Button>
            <Button
              anchorTag
              onClick={() => {
                history.push('login');
              }}>
              Return to Log In
            </Button>
          </div>
        </>
      );
    }
  };

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 mt-3 md:mx-12 lg:mx-auto">
      <center>
        <HeaderText noBold className="text-3xl lg:text-4xl">
          Forgot Password
        </HeaderText>
      </center>
      {renderCode()}
      {renderEmail()}
    </Container>
  );
};

export default ForgotPassword;
