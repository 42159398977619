import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import HeaderText from '../HeaderText';
import Button from '../Button';
import Container from '../Container';
import ReviewStep from '../ReviewStep';

import { getCasesByNo } from '../../generated/graphql/queries';

const PickSourcesModal = ({ checkedSources, setCheckedSources, linkedCases, onCancel, onConfirm, loading }) => {
  const [sources, setSources] = useState([]);

  useEffect(() => {
    getSources();
  }, []);

  useEffect(() => {
    if (sources.length > 0) {
      renderSteps();
    }
  }, [sources]);

  const getSources = async () => {
    let sourcesArr = [];
    const params = { caseNoList: JSON.stringify(linkedCases) };
    const {
      data: { getCasesByNo: cases },
    } = await API.graphql(graphqlOperation(getCasesByNo, params));

    // make array of sources
    cases.forEach(item => {
      sourcesArr = [...sourcesArr, ...item.webSources];
    });

    // pass into renderSteps
    setSources(sourcesArr);
  };

  const toggleCheckbox = input => {
    if (input.isChecked) {
      setCheckedSources([...checkedSources, input.source.id]);
    } else {
      setCheckedSources([...checkedSources.filter(src => src !== input.source.id)]);
    }
  };

  const renderSteps = () => {
    const steps = [];

    sources.forEach(source => {
      steps.push({
        title: source.website ? source.website : '[no source type selected]',
        body: source,
        id: source.id,
      });
    });

    return steps.map((data, idx) => {
      const isChecked = checkedSources.find(elem => elem === data.id);

      return (
        <ReviewStep
          key={`Source_${data.id}`}
          data={data}
          index={idx}
          numSteps={steps.length}
          isLast={idx === steps.length - 1}
          forceExpanded
          noSwaps
          showAwardOptions={false}
          showCheckboxes
          toggleCheckbox={toggleCheckbox}
          isChecked={isChecked}
          hideReview
        />
      );
    });
  };

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="w-full"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col justify-between"
      margin="mx-3 md:mx-12 lg:mx-auto"
    >
      <center>
        <HeaderText noBold className="text-2xl lg:text-3xl">
          Choose sources to be included in PDF.
        </HeaderText>
      </center>
      <div className="overflow-auto">{renderSteps()}</div>

      <div className="flex justify-end mt-8">
        <Button linedBlue noPadding className="px-3 mr-6 min-w-20 lg:min-w-24" onClick={onCancel}>
          CANCEL
        </Button>

        <Button
          solidBlue
          noPadding
          className="px-3 min-w-20 lg:min-w-24"
          onClick={() => {
            onConfirm();
          }}
          loading={loading}
        >
          SAVE COMBINED SOURCES
        </Button>
      </div>
    </Container>
  );
};

export default PickSourcesModal;
