export const howYouHeardAboutUsOptions = [
  { value: 'Guardian Group member', label: 'Guardian Group member' },
  { value: 'Friend | Coworker', label: 'Friend | Coworker' },
  { value: 'Guardian Group website', label: 'Guardian Group website' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'Conference', label: 'Conference' },
  {
    value: 'Online search of Open Source Intelligence (OSINT)',
    label: 'Online search of Open Source Intelligence (OSINT)',
  },
  {
    value: 'Online search of counter sex trafficking',
    label: 'Online search of counter sex trafficking',
  },
  { value: 'Other', label: 'Other' },
];
