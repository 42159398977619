import React from 'react';

import countryList from 'react-select-country-list';
import states from 'states-us';

import DropDown from '../DropDown';
import TextField from '../TextField';

const countries = countryList().getData();

const CountryStateZipPicker = ({ country, setCountry, state, setState, zip, setZip }) => {
  const selectedCountry = countries.find(x => x.label === country);
  const selectedState = states.find(x => x.abbreviation === state);

  return (
    <>
      <DropDown
        value={selectedCountry}
        onChange={option => setCountry(option.label)}
        label="Country"
        containerClassName="mb-4"
        options={countries}
        required
        key={`${selectedCountry}-country-selection`}
      />

      {country === 'United States' && (
        <div className="flex flex-col lg:flex-row">
          <DropDown
            value={selectedState}
            onChange={option => setState(option.abbreviation)}
            label="State"
            containerClassName="mb-4 lg:mr-2"
            options={states}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.abbreviation}
            required
            key={`${selectedState}-state-selection`}
          />

          <TextField
            value={zip}
            onChange={e => setZip(e.target.value)}
            placeHolder="12345"
            label="ZIP Code"
            className="mb-4 lg:ml-2"
            required
          />
        </div>
      )}
    </>
  );
};

export default CountryStateZipPicker;
