import React, { useEffect } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Button, Container, HeaderText } from '../../../components';
import { onUserApproved } from '../../../generated/graphql/subscriptions';
import { useUser } from '../../../contexts/userContext';

const ThankYou = () => {
  const { user, setUser } = useUser();

  const history = useHistory();

  useEffect(() => {
    let subscription = null;

    const initSubscription = async () => {
      try {
        subscription = await API.graphql(graphqlOperation(onUserApproved, { id: user?.id })).subscribe({
          error: err => {
            console.error('Error subscribing to onApproveUser: ', err);
          },
          next: () => {
            window.location.reload(true);
          },
        });
      } catch (error) {
        console.error('Error on subscription: ', error);
      }
    };

    initSubscription();

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [user.id]);

  return (
    <Container
      grayedBackground
      height="lg:h-168"
      width="lg:w-160"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto">
      <center>
        <HeaderText noBold fontSize="text-4xl" className="my-4">
          Thank You
        </HeaderText>
      </center>

      <br />

      <p className="overflow-y-auto">
        Your training certificate was uploaded successfully. Upon review and approval, you will receive an email with
        your account confirmation. At that point you can log back in and finish the signup process where you choose your
        own alias for the system. From there, you'll be allowed access to start contributing to countering sex
        trafficking victims in the United States. Good luck and thanks for joining us in this effort.
      </p>

      <Button solidBlue className="w-full mt-12" onClick={() => history.push('certificate-upload')}>
        RE-UPLOAD CERTIFICATE
      </Button>

      <Button
        linedBlue
        className="w-full mt-4"
        onClick={async () => {
          await Auth.signOut();

          setUser(null);

          history.push('/');
        }}>
        SIGN OUT
      </Button>
    </Container>
  );
};

export default ThankYou;
