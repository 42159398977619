/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import debounce from 'lodash.debounce';
import Spinner from '../Spinner';
import { generateButtonClass } from './generateButtonClass.js';

const Button = ({ type, onClick, loading, justify, inline, disabled, debounceDelay = 250, id, ...props }) => {
  const className = generateButtonClass({ loading, disabled, ...props });
  const attributes = { id };
  // const DEBOUNCE_SAVE_DELAY_MS = 250;

  const onClickWithDebounce = useCallback(
    debounce(async () => {
      if (onClick) {
        onClick();
      }
    }, debounceDelay),
    [onClick]
  );

  return (
    <button
      type={type}
      onClick={onClickWithDebounce}
      className={`${!inline && 'flex'} flex-row items-center ${justify || 'justify-center'} ${className}`}
      disabled={loading || disabled}
      {...attributes}
    >
      {props.children}
      {loading && <Spinner className="h-5 w-5 ml-2" />}
    </button>
  );
};

export default Button;
