/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import AuthCode from 'react-auth-code-input';
import { Container, HeaderText, Button, TextField } from '../../../components';
import { downloadCase } from '../../../generated/graphql/mutations';
import { getCase } from '../../../generated/graphql/queries';
import useAnalyticsEventTracker from '../../../helpers/GoogleAnalytics/eventTracker';

const CaseDownload = () => {
  const [mfaCode, setMfaCode] = useState('');
  const [email, setEmail] = useState('');
  const [cognitoUser, setCognitoUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const gaEventTracker = useAnalyticsEventTracker('Case Download');
  const [caseNumber, setCaseNumber] = useState('');

  // useEffect(() => {
  //   const getCaseRecord = async () => {
  //     const result = await API.graphql(
  //       graphqlOperation(getCase, {
  //         id: getCaseIdFromUrl(),
  //       })
  //     );

  //     setCaseNumber(result.data.getCase.caseNumber);
  //   };

  //   getCaseRecord();
  // }, []);

  const onMFAInputChange = res => {
    setMfaCode(res);
  };

  const caseId = getCaseIdFromUrl();

  const requestDownloadCase = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const {
        data: {
          downloadCase: { preSignedUrl },
        },
      } = await API.graphql({
        query: downloadCase,
        variables: { input: { mfaCode, caseId } },
        authMode: 'AWS_IAM',
      });

      window.open(preSignedUrl, '_blank');
      gaEventTracker('successful-case-download', caseNumber);

      setIsLoading(false);
    } catch (error) {
      if (error?.errors[0]?.message) {
        const errorMessage = error.errors[0].message;

        setError(errorMessage);
      } else {
        setError('Something went try wrong. Please try again');
      }

      gaEventTracker('unsuccessful-case-download', caseNumber);
      console.error('error', error);
      setIsLoading(false);
    }
  };

  const sendEmail = useCallback(async () => {
    setIsLoading(true);
    setError('')
    try {
      // const cogUser = await Auth.signIn(email, '');
      const cogUser = await Auth.signIn(email);
      console.log({ cogUser }, { depth: null });
      setCognitoUser(cogUser);
      setEmailSent(true);
    } catch (e) {
      setError(e.message);
    }
    setIsLoading(false);
  }, [email]);

  const verifyCode = useCallback(async () => {
    // console.log({ cognitoUser, mfaCode });
    // console.log({ cogUser });

    // It we get here, the answer was sent successfully,
    // but it might have been wrong (1st or 2nd time)
    // So we should test if the user is authenticated now
    setError('');
    setIsLoading(true);
    try {
      // This will throw an error if the user is not yet authenticated:
      const cogUser = await Auth.sendCustomChallengeAnswer(cognitoUser, mfaCode);
      await Auth.currentSession();
      await requestDownloadCase();
      await Auth.signOut();
      setEmailSent(false);
    } catch {
      console.log('Apparently the user did not enter the right code');
      setError('Unable to validate code');
    }
    setIsLoading(false);
  }, [mfaCode, cognitoUser]);

  return (
    <Container
      height="h-168"
      width="w-full md:w-160"
      padding="px-4 md:px-20 py-8"
      className="flex flex-col"
      margin="mx-auto">
      <center>
        <HeaderText noBold fontSize="text-4xl" className="mb-4 mt-2pr">
          PAIR Report Download
        </HeaderText>
      </center>
      <br />

      <div className="flex flex-col items-center">
        <p>Enter your verified LEA email address to begin authentication.</p>
        <div className="w-3/4">
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Email"
            className="mb-4"
            tabindex={6}
          />
        </div>
      </div>
      {emailSent && (
        <div className="flex flex-col items-center">
          <p>Enter the MFA code sent to your email address.</p>
          <AuthCode
            length={6}
            onChange={onMFAInputChange}
            containerClassName="flex justify-center my-4"
            inputClassName="input shadow text-center bg-gray-200 w-1/12 mx-2 h-10"
          />
        </div>
      )}
      {error && <div className="flex justify-center text-pursuit-darkred">{error}</div>}
      <div className="flex justify-end mt-8">
        {!emailSent ? (
          <Button solidBlue noPadding className="px-3 min-w-20 lg:min-w-24" onClick={sendEmail} loading={isLoading}>
            VERIFY EMAIL
          </Button>
        ) : (
          <Button
            solidBlue
            noPadding
            className="px-3 min-w-20 lg:min-w-24"
            // onClick={requestDownloadCase}
            onClick={verifyCode}
            loading={isLoading}>
            VALIDATE CODE
          </Button>
        )}
      </div>
      <br />
    </Container>
  );
};

const getCaseIdFromUrl = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const caseId = queryParams.get('caseId');

  return caseId;
};

export default CaseDownload;
