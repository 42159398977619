import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import PageOne from './PageOne.js';
import PageTwo from './PageTwo.js';
import TermsModal from './TermsModal.js';
import PrivacyModal from './PrivacyModal';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';

const CreateAccount = ({ initialPage }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alias, setAlias] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState();
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [zip, setZip] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [heardAbout, setHeardAbout] = useState('');
  const [pageState, setPageState] = useState(initialPage || 'page-one');

  const [, setAlert] = useContext(NotificationContext);
  const history = useHistory();

  const signUp = async () => {
    try {
      await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          email: email.toLowerCase(),
          nickname: alias,
          given_name: firstName,
          family_name: lastName,
          birthdate: dateOfBirth ? DateTime.fromJSDate(dateOfBirth).toFormat('MM/dd/yyyy') : '',
          'custom:country': country,
          'custom:state': (country === 'United States' && location) || '',
          'custom:zip': (country === 'United States' && zip) || '',
          'custom:heard_about_us': heardAbout,
        },
      });

      setFirstName('');
      setLastName('');
      setConfirmPassword('');
      setDateOfBirth(null);
      setLocation('');

      history.push('login', { origin: 'createAccount', password, email });
    } catch (error) {
      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: error.message,
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
  };

  switch (pageState) {
    case 'page-one':
      return (
        <PageOne
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          pageChange={setPageState}
        />
      );
    case 'page-two':
      return (
        <PageTwo
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          alias={alias}
          setAlias={setAlias}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          country={country}
          setCountry={setCountry}
          location={location}
          setLocation={setLocation}
          zip={zip}
          setZip={setZip}
          agreeToTerms={agreeToTerms}
          setAgreeToTerms={setAgreeToTerms}
          pageChange={setPageState}
          heardAbout={heardAbout}
          setHeardAbout={setHeardAbout}
          signUp={signUp}
        />
      );
    case 'terms-of-use':
      return <TermsModal pageChange={setPageState} />;
    case 'privacy-policy':
      return <PrivacyModal pageChange={setPageState} />;
    default:
      return (
        <PageOne
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          pageChange={setPageState}
        />
      );
  }
};

export default CreateAccount;
