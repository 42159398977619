import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryState = (key, defaultValue) => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const value = params.get(key) || defaultValue;

  const setValue = useCallback(
    newValue => {
      if (newValue) {
        params.set(key, newValue);
      } else {
        params.delete(key);
      }
      history.push({ search: params.toString() });
    },
    [key, params, history]
  );

  return [value, setValue];
};
