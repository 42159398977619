import React, { useState } from 'react';
import * as Icons from 'react-icons/fa';
import PopUpConfirmation from '../PopupConfirmation';

const ToolTip = ({ title, tooltipText, size, className }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show ? (
        <PopUpConfirmation
          title={title}
          content={tooltipText}
          onConfirm={() => setShow(false)}
          confirmText="OK"
          noCancel
          className="w-11/12 lg:w-auto"
        />
      ) : null}
      <Icons.FaInfoCircle size={size || 16} className={`cursor-pointer ${className}`} onClick={() => setShow(true)} />
    </>
  );
};

export default ToolTip;
