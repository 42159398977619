import React, { useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import DatePicker from 'react-datepicker';

import {
  Container,
  HeaderText,
  Button,
  DropDown,
  TextField,
  AliasGenerator,
  CountryStateZipPicker,
} from '../../../components';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import { adminCreateUser } from '../../../generated/graphql/mutations';
import { UserTypes } from '../../../constants';
import { validateAdminCreateUser } from '../../../helpers/createAccount/createAccountValidation';

const CreateUser = () => {
  const history = useHistory();

  const { state: routeState } = useLocation();

  const [, setAlert] = useContext(NotificationContext);

  const defaultState = {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    country: '',
    state: '',
    zip: '',
    email: '',
    userType: routeState?.userType || UserTypes.Volunteer,
  };

  const [newUser, setNewUser] = useState(defaultState);

  const [alias, setAlias] = useState('');
  const [aliasClearedAt, setAliasClearedAt] = useState(null);

  const [saving, setSaving] = useState();

  const userTypeOptions = [
    { label: 'Volunteer', value: UserTypes.Volunteer },
    { label: 'Staff', value: UserTypes.Staff },
    { label: 'Admin', value: UserTypes.Admin },
  ];

  const createUser = async () => {
    try {
      setSaving(true);

      const stateIsValid = validateAdminCreateUser(setAlert, {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        dateOfBirth: newUser.dateOfBirth,
        country: newUser.country,
        state: newUser.state,
        zip: newUser.zip,
      });

      if (stateIsValid) {
        const formattedDOB = DateTime.fromJSDate(newUser.dateOfBirth).toFormat('MM/dd/yyyy');

        const {
          data: { adminCreateUser: user = null },
        } = await API.graphql(
          graphqlOperation(adminCreateUser, {
            input: {
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              email: newUser.email,
              alias,
              dateOfBirth: formattedDOB,
              country: newUser.country,
              state: newUser.state,
              zip: newUser.zip,
              userType: newUser.userType,
            },
          })
        );

        toast.success('User Created', {
          progress: false,
          className: 'bg-green-500 text-white',
          autoClose: 1500,
          closeButton: false,
          icon: () => <Icons.FaCheck size={18} className="text-white" />,
        });

        history.push(`/user/${user.id}`);
      }
    } catch (error) {
      let errorMessage = 'An error occurred attempting to create the user.';

      if (error.errors && Array.isArray(error.errors)) {
        const userExistsError = error.errors.find(x => x.errorType === 'UsernameExistsException');

        if (userExistsError) {
          errorMessage = `Error creating user - ${userExistsError.message}`;
        }
      }

      console.error('Error creating user: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: errorMessage,
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setSaving(false);
  };

  return (
    <Container
      height="lg:min-h-104"
      width="lg:w-4/5"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto mt-4"
    >
      <HeaderText fontSize="text-4xl" className="mb-12">
        Create New User
      </HeaderText>

      <div>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="flex flex-col lg:w-1/2 lg:mr-8">
            <TextField
              value={newUser.firstName}
              onChange={e => setNewUser({ ...newUser, firstName: e.target.value })}
              label="First Name"
              className="mb-4"
              required
            />

            <TextField
              value={newUser.lastName}
              onChange={e => setNewUser({ ...newUser, lastName: e.target.value })}
              label="Last Name"
              className="mb-4"
              required
            />

            <label className="light-primary-blue-text font-light mr-1 mb-4">
              <span>
                Date of Birth <span className="text-pursuit-red ml-1">*</span>
              </span>
              <div className="flex items-center mt-1">
                <DatePicker
                  selected={newUser.dateOfBirth}
                  onChange={date => setNewUser({ ...newUser, dateOfBirth: date })}
                  maxDate={new Date()}
                  className="textfield-bg-color w-full h-10 px-2"
                  shouldCloseOnSelect
                />
                <Icons.FaCalendarDay size={30} className="ml-4 cursor-pointer" />
              </div>
            </label>

            <CountryStateZipPicker
              country={newUser.country}
              setCountry={country => setNewUser({ ...newUser, country })}
              state={newUser.state}
              setState={state => setNewUser({ ...newUser, state })}
              zip={newUser.zip}
              setZip={zip => setNewUser({ ...newUser, zip })}
            />
          </div>

          <div className="flex flex-col lg:w-1/2 lg:ml-8">
            <TextField
              value={newUser.email}
              onChange={e => setNewUser({ ...newUser, email: e.target.value })}
              label="Email Address"
              className="mb-4"
              required
            />

            <AliasGenerator alias={alias} setAlias={setAlias} excludeHeader clearedAt={aliasClearedAt} />

            <div className="flex flex-col w-full lg:w-auto">
              <DropDown
                value={userTypeOptions.find(x => x.value === newUser.userType) || null}
                onChange={option => setNewUser({ ...newUser, userType: option.value })}
                width="w-full"
                containerClassName="mr-2 mb-4"
                options={userTypeOptions}
                label="User Type"
                required
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end mt-4">
          <Button
            linedBlue
            noPadding
            className="px-4 lg:ml-6 w-full lg:w-auto mr-2"
            onClick={() => {
              setNewUser(defaultState);
              setAliasClearedAt(new Date().toISOString());
            }}
          >
            CLEAR
          </Button>

          <Button solidBlue noPadding className="px-4 ml-2 w-full lg:w-auto" onClick={createUser} loading={saving}>
            {saving ? 'CREATING' : 'CREATE'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default CreateUser;
