import React from 'react';
import { CaseStatus, VolunteerStatus, EmployeeType, UserTypes } from '../../constants';

const StatusPill = ({ status, type = 'user' }) => {
  let bgColor = '';

  if (type === 'leo' && (status === 'LEO' || status == 'ACTIVE')) {
    bgColor = 'bg-guardian-blue';
  } else if (type === 'leo' && status === 'DISABLED_LEO') {
    bgColor = 'bg-red-500';
  }

  if ((type == 'user' && status === CaseStatus.Active) || status === VolunteerStatus.Certified) {
    bgColor = 'bg-green-500';
  } else if (
    status === CaseStatus.Submitted ||
    status === VolunteerStatus.Pending ||
    status === CaseStatus.Resubmitted
  ) {
    bgColor = 'bg-pursuit-amber';
  } else if (
    status === VolunteerStatus.Disabled ||
    status === 'DISABLED_LEO' ||
    status === VolunteerStatus.Deleted ||
    status === CaseStatus.Returned
  ) {
    bgColor = 'bg-pursuit-red';
  } else if (status === VolunteerStatus.Uncertfied || status === CaseStatus.Approved) {
    bgColor = 'bg-pursuit-gray';
  } else if (status === EmployeeType.Admin) {
    bgColor = 'bg-yellow-500';
  } else if (status === EmployeeType.Staff || status === UserTypes.LEO) {
    bgColor = 'bg-guardian-blue';
  }

  return (
    <div className={`rounded-full flex justify-center w-32 py-1 px-1 ${bgColor}`}>
      <span className="text-xs font-semibold text-white lg:text-sm ">
        {status ? status.charAt(0).toUpperCase() + status.slice(1) : ''}
      </span>
    </div>
  );
};

export default StatusPill;
