import React, { useState, useEffect, useContext } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { DateTime } from 'luxon';
import { Container, HeaderText, Button, CaseMap, DataGrid, Spinner } from '../../../components';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext';
import { getTopLeaderboard, getHistoryStatistics } from '../../../generated/graphql/queries';
import {
  LEAD_GRAPH_DEFINITION,
  USER_COUNT_GRAPH_DEFINITION,
  HOURS_GRAPH_DEFINITION,
  createGraph,
} from './microstatGraph';

const microStatsToCrowdCharts = ({ leadsApproved, leadsSubmitted, usersTotal, usersNew, hoursCrowd, hoursUser }) => [
  createGraph(leadsApproved, leadsSubmitted, LEAD_GRAPH_DEFINITION, [
    { color: '#01558B', value: 'Submitted' },
    { color: '#770303', value: 'Approved' },
  ]),
  createGraph(usersTotal, usersNew, USER_COUNT_GRAPH_DEFINITION, [
    { color: '#01558B', value: 'New' },
    { color: '#770303', value: 'Total' },
  ]),
  createGraph(hoursCrowd, hoursUser, HOURS_GRAPH_DEFINITION, [
    { color: '#01558B', value: 'User' },
    { color: '#770303', value: 'Crowd' },
  ]),
];

const microStatsToUserCharts = ({ userLeadsApproved, userLeadsSubmitted, hoursUser }) => [
  createGraph(userLeadsApproved || [], userLeadsSubmitted || [], LEAD_GRAPH_DEFINITION, [
    { color: '#01558B', value: 'Submitted' },
    { color: '#770303', value: 'Approved' },
  ]),
  createGraph([], hoursUser, HOURS_GRAPH_DEFINITION, [{ color: '#01558B', value: 'User' }]),
];

const DEFAULT_MICRO_STATISTICS = {
  leadsApproved: [],
  leadsSubmitted: [],
  userLeadsApproved: [],
  userLeadsSubmitted: [],
  usersTotal: [],
  usersNew: [],
  hoursCrowd: [],
  hoursUser: [],
};

const DEFAULT_MICRO_CHARTS = {
  crowd: microStatsToCrowdCharts(DEFAULT_MICRO_STATISTICS),
  user: microStatsToUserCharts(DEFAULT_MICRO_STATISTICS),
};

const Leaderboard = () => {
  const [, setAlert] = useContext(NotificationContext);
  const [board, setBoard] = useState('allTime');
  const [leaderboardLoading, setLeaderboardLoading] = useState(false);
  const [nextLeaderboardToken, setNextLeaderboardToken] = useState();
  const [leaderboard, setLeaderboard] = useState([]);
  const [userCrowdToggle, setUserCrowdToggle] = useState('crowd');
  const [globalStatistics, setGlobalStatistics] = useState({
    totalLeadsSubmitted: 0,
    totalLeadsApproved: 0,
  });
  const [userStatistics, setUserStatistics] = useState({
    userLeadsSubmitted: 0,
    userLeadsApproved: 0,
  });
  const [globalStatisticsLoading, setGlobalStatisticsLoading] = useState(false);
  const [userStatisticsLoading, setUserStatisticsLoading] = useState(false);
  const [microCharts, setMicroCharts] = useState(DEFAULT_MICRO_CHARTS);
  const [caseMap, setCaseMap] = useState(<CaseMap board={board} />);

  useEffect(() => {
    fetchLeaderboard();
    setCaseMap(<CaseMap board={board} userCrowdToggle={userCrowdToggle} />);
    fetchHistoryStatistics(board);
  }, [board, userCrowdToggle]);

  const fetchHistoryStatistics = async boardName => {
    setUserStatisticsLoading(true);
    setGlobalStatisticsLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(getHistoryStatistics, { board: boardName }));
      const {
        leadsApproved,
        leadsSubmitted,
        userLeadsApproved,
        userLeadsSubmitted,
        usersTotal,
        usersNew,
        hoursCrowd,
        hoursUser,
      } = response.data.getHistoryStatistics;

      const toHighchartsData = ({ time, value }) => [DateTime.fromISO(time).toMillis(), value];
      const sumLeads = (prev, curr) => prev + curr.value;

      const microStatistics = {
        leadsApproved: leadsApproved.map(toHighchartsData),
        leadsSubmitted: leadsSubmitted.map(toHighchartsData),
        userLeadsApproved: userLeadsApproved.map(toHighchartsData),
        userLeadsSubmitted: userLeadsSubmitted.map(toHighchartsData),
        usersTotal: usersTotal.map(toHighchartsData),
        usersNew: usersNew.map(toHighchartsData),
        hoursCrowd: hoursCrowd.map(toHighchartsData),
        hoursUser: hoursUser.map(toHighchartsData),
      };

      setUserStatistics({
        userLeadsSubmitted: userLeadsSubmitted.reduce(sumLeads, 0),
        userLeadsApproved: userLeadsApproved.reduce(sumLeads, 0),
      });

      setGlobalStatistics({
        totalLeadsSubmitted: leadsSubmitted.reduce(sumLeads, 0),
        totalLeadsApproved: leadsApproved.reduce(sumLeads, 0),
      });
      setMicroCharts({
        crowd: microStatsToCrowdCharts(microStatistics),
        user: microStatsToUserCharts(microStatistics),
      });
    } catch (error) {
      console.error('Error Loading history statistics: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading history statistics',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }
    setUserStatisticsLoading(false);
    setGlobalStatisticsLoading(false);
  };

  const fetchLeaderboard = async () => {
    setLeaderboardLoading(true);

    try {
      const nextTokenToUse = nextLeaderboardToken;

      const oldLeaderboard = nextTokenToUse ? [...leaderboard] : [];
      // UPDATE BOARD to be in correct case formate
      const params = {
        board,
        nextToken: nextTokenToUse,
        limit: 100,
      };

      const response = await API.graphql(graphqlOperation(getTopLeaderboard, params));

      let result = response.data.getTopLeaderboard.rankings || [];

      setNextLeaderboardToken(result.nextToken);
      result = result.map(item => ({
        ...item,
        percentSuccess: parseInt((item.leadsApproved / item.leadsSubmitted) * 100),
      }));
      setLeaderboard([...oldLeaderboard, ...result]);
    } catch (error) {
      console.error('Error Loading leaderboard: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading leaderboard',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setLeaderboardLoading(false);
  };

  const renderToggles = (currentValue, setter, options) =>
    options.map(({ value, label }) =>
      value === currentValue ? (
        <Button height="h-8" solidBlue padding="ml-1 px-2" onClick={() => setter(value)}>
          {label}
        </Button>
      ) : (
        <Button height="h-8" linedBlue padding="ml-1 px-2" onClick={() => setter(value)}>
          {label}
        </Button>
      )
    );
  const userCrowdToggleOptions = [
    { value: 'user', label: 'USER' },
    { value: 'crowd', label: 'CROWD' },
  ];
  const boardOptions = [
    { value: 'oneMonth', label: '1M' },
    { value: 'sixMonths', label: '6M' },
    { value: 'oneYear', label: '1Y' },
    { value: 'yearToDate', label: 'YTD' },
    { value: 'allTime', label: 'ALL' },
  ];

  const renderStatisticsContent = () => {
    if (userCrowdToggle === 'user') {
      return userStatisticsLoading ? (
        <div className="flex justify-center">
          <Spinner className="w-16 h-16 text-pursuit-red" />{' '}
        </div>
      ) : (
        <div>
          <p className="mb-2 font-bold text-md">User Leads Submitted: {userStatistics.userLeadsSubmitted}</p>
          <p className="mt-2 mb-2 font-bold text-md">User Leads Approved: {userStatistics.userLeadsApproved}</p>
        </div>
      );
    }

    return globalStatisticsLoading ? (
      <div className="flex justify-center">
        <Spinner className="w-16 h-16 text-pursuit-red" />{' '}
      </div>
    ) : (
      <div>
        <p className="mb-2 font-bold text-md">Total Leads Submitted: {globalStatistics.totalLeadsSubmitted}</p>
        <p className="mt-2 mb-2 font-bold text-md">Total Leads Approved: {globalStatistics.totalLeadsApproved}</p>
      </div>
    );
  };

  return (
    <Container width="lg:w-2/3" margin="m-3 lg:m-auto lg:mt-6" className="relative px-5 py-6 lg:px-8 lg:py-10">
      <HeaderText fontSize="text-4xl" className="text-center">
        PURSUIT® Community
      </HeaderText>
      <HeaderText noBold fontSize="text-2xl italic" className="text-center">
        Harnessing the power of many to find the vulnerable
      </HeaderText>

      <div className="flex flex-wrap justify-between px-8 mt-8">
        <div className="flex justify-start mt-1">
          {renderToggles(userCrowdToggle, setUserCrowdToggle, userCrowdToggleOptions)}
        </div>
        <div className="flex justify-end mt-1">{renderToggles(board, setBoard, boardOptions)}</div>
      </div>

      <div className="flex flex-col px-8 py-4 mt-8">
        <div className="flex grid grid-cols-3 gap-10">
          <div className="w-full col-span-3 lg:col-span-1">
            <div className="flex grid grid-cols-2">
              <div className="col-span-2 sm:col-span-1 lg:col-span-2">
                <HeaderText>{userCrowdToggle === 'user' ? 'User' : 'Global'} Statistics</HeaderText>
                {renderStatisticsContent()}
              </div>
              {microCharts[userCrowdToggle].map(chart => (
                <div className="col-span-2 sm:col-span-1 lg:col-span-2">{chart}</div>
              ))}
            </div>
          </div>
          <div className="w-full col-span-3 lg:col-span-2">
            {leaderboardLoading ? (
              <div className="flex justify-center">
                <Spinner className="w-24 h-24 text-pursuit-red" />{' '}
              </div>
            ) : (
              caseMap
            )}
          </div>
        </div>
        <div className="mt-16">
          <HeaderText>Leaderboard</HeaderText>
          <DataGrid
            columns={[
              { title: 'Rank', fieldName: 'rank' },
              { title: 'Alias', fieldName: 'alias' },
              { title: 'Leads Approved', fieldName: 'leadsApproved' },
              { title: 'Leads Submitted', fieldName: 'leadsSubmitted' },
              { title: '% Success', fieldName: 'percentSuccess' },
              { title: 'Total Points', fieldName: 'totalPoints' },
              // { title: 'Hours Spent', fieldName: 'hoursSpent' },
            ]}
            loading={leaderboardLoading}
            data={leaderboard}
            noRecordsText="Nobody in leaderboard"
            loadNextPage={() => fetchLeaderboard()}
            hasMore={!!nextLeaderboardToken}
            containerHeight="h-88"
            gridKey="leaderboard"
          />
        </div>
      </div>
    </Container>
  );
};

export default Leaderboard;
