import React, { createContext, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { getUser } from '../generated/graphql/queries';
import { updateUser } from '../generated/graphql/mutations';
import ReactGA from 'react-ga4';

const UserContext = createContext({
  user: null,
  setUser: () => null,
  userGroups: null,
});

const UserProvider = ({ children, user, setUser }) => {
  const [userGroups, setUserGroups] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isCertified = userGroups && userGroups.includes('Certified');
  const isAdmin = userGroups && userGroups.includes('Admin');
  const isEmployee = isAdmin || (userGroups && userGroups.includes('Employee'));

  // Set google analytics to track the user by id and add groups as custom dimensions
  const analyticsSetUserParams = userObj => {
    if (!userObj || !userObj.id) {
      return;
    }
    const params = {
      user_id: userObj.id,
      user_properties: {
        user_status: userObj.userStatus,
        heard_about_us: userObj.heardAboutUs,
      },
    };
    // console.log('Setting user params', params, { userObj });
    ReactGA.set(params);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const currentAuthUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

        if (currentAuthUser && currentAuthUser.attributes && currentAuthUser.attributes.sub) {
          const result = await API.graphql(graphqlOperation(getUser));

          const userToSet = { ...result.data.getUser, cognitoUser: currentAuthUser };

          setUser(userToSet);

          const groups = currentAuthUser.signInUserSession.accessToken.payload['cognito:groups'];

          setUserGroups(groups || []);

          const { id } = await Auth.currentUserInfo();

          analyticsSetUserParams(userToSet);

          await API.graphql(
            graphqlOperation(updateUser, { input: { lastSignInAt: new Date().toISOString(), identityId: id } })
          );
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error getting user info: ', error);

        if (error === 'The user is not authenticated') {
          try {
            await Auth.signOut();

            setUser(null);
          } catch (error) {
            console.error(`Error -- ${error}`);
          }
        }
        setIsLoading(false);
        setUser(null);
        setUserGroups(null);
      }
    };

    fetchData();
  }, []);

  const loadUser = async () => {
    try {
      setIsLoading(true);
      const currentAuthUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

      if (currentAuthUser && currentAuthUser.attributes && currentAuthUser.attributes.sub) {
        const result = await API.graphql(graphqlOperation(getUser));

        const userToSet = { ...result.data.getUser, cognitoUser: currentAuthUser };

        setUser(userToSet);

        const groups = currentAuthUser.signInUserSession.accessToken.payload['cognito:groups'];

        setUserGroups(groups || []);

        const { id } = await Auth.currentUserInfo();

        analyticsSetUserParams(userToSet);

        await API.graphql(
          graphqlOperation(updateUser, { input: { lastSignInAt: new Date().toISOString(), identityId: id } })
        );
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error getting user info: ', error);

      if (error === 'The user is not authenticated') {
        try {
          await Auth.signOut();

          setUser(null);
        } catch (error) {
          console.error(`Error -- ${error}`);
        }
      }
      setIsLoading(false);
      setUser(null);
      setUserGroups(null);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loadUser,
        userGroups,
        isCertified,
        isAdmin,
        isEmployee,
        isLoading,
        setIsLoading,
      }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);

export default UserContext;

export { UserProvider, useUser };
